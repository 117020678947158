import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';

type Mode = 'document' | 'table';

interface Props {
  mode: Mode
  height: string
  readonly: boolean
  initialValue?: string

  contextmenu?: string

  setup?(editor): void
}

export const TinyEditor = forwardRef((props: Props, ref) => {

  const editorRef = useRef(null);

  const [targetState, setTargetState] = useState<'' | 'found' | 'listened'>('');

  useImperativeHandle(ref, () => ({
    getContent() {
      return new Promise(resolve => {
        const event = new CustomEvent('getContent', {
          detail: {
            cb: content => {
              resolve(content);
            }
          }
        });
        editorRef.current.contentWindow.document.getElementById('iamTextarea')?.dispatchEvent(event);
      });
    }
  }));

  useEffect(() => {
    const findTargetRef = setInterval(() => {
      const iamTarget = editorRef?.current?.contentWindow?.document?.getElementById('iamTextarea');
      if (iamTarget) {
        setTargetState('found');
      }
      if (iamTarget?.getAttribute('iam-listened') === 'Y') {
        clearInterval(findTargetRef);
        setTargetState('listened');
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (targetState === 'found') {
      const event = new CustomEvent('initTinymce', {
        detail: {
          mode: props.mode,
          contextmenu: props.contextmenu,
          setup: props.setup
        }
      });
      editorRef.current.contentWindow.document.getElementById('iamTextarea')?.dispatchEvent(event);
    }
  }, [targetState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (targetState === 'listened' && typeof props.initialValue === 'string') {
      const event = new CustomEvent('setContent', {
        detail: {content: props.initialValue}
      });
      editorRef.current.contentWindow.document.getElementById('iamTextarea')?.dispatchEvent(event);
    }
  }, [targetState, props.initialValue]);

  useEffect(() => {
    if (targetState === 'listened' && typeof props.readonly === 'boolean') {
      const event = new CustomEvent('setReadonly', {
        detail: {readonly: props.readonly}
      });
      editorRef.current.contentWindow.document.getElementById('iamTextarea')?.dispatchEvent(event);
    }
  }, [targetState, props.readonly]);

  return <iframe ref={editorRef} src="/editor/mce-editor.html?v=1.2.0" title="iam-editor" style={{height: props.height, minHeight: '25rem'}}/>
});

export const sampleTable = `
    <table style="border-collapse: collapse; width: 100%;" border="1px">
      <thead>
        <tr>
          <th><br></th>
          <th><br></th>
          <th><br></th>
          <th><br></th>
          <th><br></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><br></td>
          <td><br></td>
          <td><br></td>
          <td><br></td>
          <td><br></td>
        </tr>
      </tbody>
    </table>
`;