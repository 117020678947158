import {Fragment, useEffect, useRef, useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {BehaviorSubject, Subject} from 'rxjs';

import {classNames} from 'primereact/utils';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';

import {baseWebSocketURL} from 'src/config';

import {IamPermission} from 'src/shared/utils/Permission';

import {AuthService} from 'src/service/AuthService';
import {OrganizationService} from './he-thong/to-chuc/OrganizationService';
import {AppStorage, UserStorage} from 'src/service/UserStorage';

import {AppTopbar} from './AppTopbar';
import {AppMenu} from './AppMenu';

import Task from './cong-viec';
import Category from './danh-muc';
import System from './he-thong';
import Result from './ket-qua';
import Report from './bao-cao';
import Reminder from './lich-lam-viec';
import {Profile} from './tai-khoan';

const iamElasticHasChange = new Subject<any>();
const taMaNotification = new BehaviorSubject<any>(null);
const taMaTeamActivity = new BehaviorSubject<any>(null);

export default function App() {

  const [me, setMe] = useState(null);
  const [isBirthday, setIsBirthday] = useState(false);
  const [org, setOrg] = useState(null);

  const [layoutMode] = useState('static');
  const [layoutColorMode] = useState('light');
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const [activeTopbarItem, setActiveTopbarItem] = useState(null);

  let menuClick = false;
  let mobileTopbarMenuClick = false;
  let topbarItemClick: boolean;

  useEffect(() => {
    AuthService.getPermissions().then(({listData}) => {
      IamPermission.init(listData);

      AuthService.getMe('N').then(data => {
        setMe(data);
        AppStorage.set('me', JSON.stringify(data));
        UserStorage.id = data.partyId;

        initWebSocket();

        if (UserStorage.get('showHappyBirthday') === 'Y' && data.birthDate) {
          const birthDate = new Date(data.birthDate);
          const birthMonth = birthDate.getMonth();
          const birthDay = birthDate.getDate();
          const today = new Date();
          if (today.getMonth() === birthMonth && today.getDate() === birthDay) {
            setIsBirthday(true);
          }
        }
      }).then(() => {
        OrganizationService.get().then(data => {
          setOrg(data);
        });
      });
    }).catch(() => {
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, 'body-overflow-hidden');
    } else {
      removeClass(document.body, 'body-overflow-hidden');
    }
  }, [mobileMenuActive]);

  const initWebSocket = () => {
    const origin = window.location.origin.replace('http', 'ws').replace('https', 'wss');
    const webSocket = new WebSocket(`${origin}/${baseWebSocketURL}`);

    webSocket.onopen = () => {
      webSocket.send('subscribe:TaMaElasticHasChange,TaMaNotification,TaMaTeamActivity');
    }

    webSocket.onclose = (e: any) => {
      if (e.reason !== 'unmount') {
        initWebSocket();
      }
    }
    webSocket.onmessage = (event: any) => {
      try {
        const data = JSON.parse(event.data);
        switch (data.topic) {
          case 'TaMaElasticHasChange':
            iamElasticHasChange.next(data);
            break;
          case 'TaMaNotification':
            taMaNotification.next(data);
            break;
          case 'TaMaTeamActivity':
            taMaTeamActivity.next(data);
            break;
        }
      } catch (e) {
      }
    }
  }

  const onWrapperClick = () => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    if (!topbarItemClick) {
      setActiveTopbarItem(null);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
    topbarItemClick = false;
  }

  const onToggleMenuClick = event => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === 'overlay') {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive(prevState => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === 'static') {
        setStaticMenuInactive(prevState => !prevState);
      }
    } else {
      setMobileMenuActive(prevState => !prevState);
    }

    event.preventDefault();
  }

  const onSidebarClick = () => {
    menuClick = true;
  }

  const onMenuItemClick = event => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  }

  const onMobileTopbarMenuClick = event => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive(prevState => !prevState);
    event.preventDefault();
  }

  const onMobileSubTopbarMenuClick = event => {
    mobileTopbarMenuClick = true;

    event && event.preventDefault();
  }

  const onTopbarItemClick = (event, item) => {
    topbarItemClick = true;

    if (activeTopbarItem === item) setActiveTopbarItem(null);
    else setActiveTopbarItem(item);

    event && event.preventDefault();
  }

  const isDesktop = () => {
    return window.innerWidth >= 992;
  }

  const addClass = (element, className) => {
    if (element.classList)
      element.classList.add(className);
    else
      element.className += ' ' + className;
  }

  const removeClass = (element, className) => {
    if (element.classList)
      element.classList.remove(className);
    else
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  }

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
    'layout-theme-light': layoutColorMode === 'light'
  });

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      {me &&
				<Fragment>
					<AppTopbar me={me} org={org} iamElasticHasChange={iamElasticHasChange} taMaNotification={taMaNotification}
										 mobileTopbarMenuActive={mobileTopbarMenuActive} onToggleMenuClick={onToggleMenuClick}
										 onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
										 activeTopbarItem={activeTopbarItem} onTopbarItemClick={onTopbarItemClick}/>

					<div className="layout-sidebar" onClick={onSidebarClick}>
						<AppMenu onMenuItemClick={onMenuItemClick}/>
					</div>

					<div className="layout-main-container">
						<div className="layout-main">
							<Routes>
								<Route path="cong-viec/*" element={<Task me={me} iamElasticHasChange={iamElasticHasChange} taMaTeamActivity={taMaTeamActivity}/>}/>
								<Route path="danh-muc/*" element={<Category/>}/>
								<Route path="he-thong/*" element={<System me={me} org={org}/>}/>
								<Route path="ket-qua/*" element={<Result iamElasticHasChange={iamElasticHasChange}/>}/>
								<Route path="bien-ban/*" element={<Result iamElasticHasChange={iamElasticHasChange}/>}/>
								<Route path="bao-cao/*" element={<Report me={me} iamElasticHasChange={iamElasticHasChange}/>}/>
								<Route path="lich-lam-viec/*" element={<Reminder me={me} iamElasticHasChange={iamElasticHasChange}/>}/>
								<Route path="tai-khoan/*" element={<Profile me={me}/>}/>
								<Route path="*" element={<Navigate to="/cong-viec/dashboard" replace/>}/>
							</Routes>
						</div>
					</div>

          {isBirthday && <HappyBirthday me={me}/>}
				</Fragment>
      }
    </div>
  );
}

const HappyBirthday = ({me}) => {

  const [display, setDisplay] = useState(true);

  const hide = () => {
    UserStorage.set('showHappyBirthday', 'N');
    setDisplay(false);
  }

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={hide}/>
    </div>
  );
  return (
    <Fragment>
      <Dialog showHeader={false} footer={footer} visible={display} style={{width: '70rem'}} onHide={hide}>
        <div className="grid mt-5">
          <div className="col-12 text-center">
            <h4 className="happy-birthday-text">Chúc mừng sinh nhật, {me.partyName}!</h4>
          </div>
        </div>
        <Confetti/>
      </Dialog>
    </Fragment>
  );
}
const Confetti = () => {

  const canvas = useRef(null);

  useEffect(() => {
    init();
    render();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const init = () => {
    canvas.current.ctx = canvas.current.getContext('2d');
    canvas.current.width = window.innerWidth;
    canvas.current.height = window.innerHeight;

    canvas.current.confetti = [];
    const confettiCount = 300;
    const colors = [
      {front: 'red', back: 'darkred'},
      {front: 'green', back: 'darkgreen'},
      {front: 'blue', back: 'darkblue'},
      {front: 'yellow', back: 'darkyellow'},
      {front: 'orange', back: 'darkorange'},
      {front: 'pink', back: 'darkpink'},
      {front: 'purple', back: 'darkpurple'},
      {front: 'turquoise', back: 'darkturquoise'}];

    for (let i = 0; i < confettiCount; i++) {
      canvas.current.confetti.push({
        color: colors[Math.floor(randomRange(0, colors.length))],
        dimensions: {
          x: randomRange(10, 20),
          y: randomRange(10, 30)
        },

        position: {
          x: randomRange(0, canvas.current.width),
          y: canvas.current.height - 1
        },

        rotation: randomRange(0, 2 * Math.PI),
        scale: {
          x: 1,
          y: 1
        },
        velocity: {
          x: randomRange(-25, 25),
          y: randomRange(0, -50)
        }
      });
    }
  }

  const render = () => {
    if (!canvas?.current?.ctx) {
      return;
    }
    const gravity = 0.5;
    const terminalVelocity = 5;
    const drag = 0.075;

    canvas.current.ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);

    canvas.current.confetti.forEach((confetto, index) => {
      let width = confetto.dimensions.x * confetto.scale.x;
      let height = confetto.dimensions.y * confetto.scale.y;

      // Move canvas to position and rotate
      canvas.current.ctx.translate(confetto.position.x, confetto.position.y);
      canvas.current.ctx.rotate(confetto.rotation);

      // Apply forces to velocity
      confetto.velocity.x -= confetto.velocity.x * drag;
      confetto.velocity.y = Math.min(confetto.velocity.y + gravity, terminalVelocity);
      confetto.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random();

      // Set position
      confetto.position.x += confetto.velocity.x;
      confetto.position.y += confetto.velocity.y;

      // Delete confetti when out of frame
      if (confetto.position.y >= canvas.current.height) canvas.current.confetti.splice(index, 1);

      // Loop confetto x position
      if (confetto.position.x > canvas.current.width) confetto.position.x = 0;
      if (confetto.position.x < 0) confetto.position.x = canvas.current.width;

      // Spin confetto by scaling y
      confetto.scale.y = Math.cos(confetto.position.y * 0.1);
      canvas.current.ctx.fillStyle = confetto.scale.y > 0 ? confetto.color.front : confetto.color.back;

      // Draw confetti
      canvas.current.ctx.fillRect(-width / 2, -height / 2, width, height);

      // Reset transform matrix
      canvas.current.ctx.setTransform(1, 0, 0, 1, 0, 0);
    });

    // Fire off another round of confetti
    if (canvas.current.confetti.length <= 10) init();

    window.requestAnimationFrame(render);
  };

  const randomRange = (min, max) => Math.random() * (max - min) + min;

  return (
    <Fragment>
      <canvas ref={canvas} className="confetti"></canvas>
    </Fragment>
  );
}