import {EntityService} from 'src/service/EntityService';

export class OrgDocumentService extends EntityService {

  static entity = 'iam-system/organization-documents';

  static update(partyContentId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${partyContentId}/update`, data).then(res => res.data);
  }
}
