import {EntityService} from 'src/service/EntityService';

export class TaskExpenseService extends EntityService {

  static entity = 'iam-invoice/task-expenses';

  static getPitId(): Promise<any> {
    return this.axios.get(`${this.entity}/pit`).then(res => res.data);
  }

  static deletePit(id: string): Promise<any> {
    return this.axios.delete(`${this.entity}/pit`, {params: {id}}).then(res => res.data);
  }

  static updateExpense(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/update`, data).then(res => res.data);
  }

  static updateExpenseStatus(id: string, statusId: string): Promise<any> {
    return this.axios.patch(`${this.entity}/${id}/update-status`, {statusId}).then(res => res.data);
  }

  static getExpensesByEmployee(fromDate: string, toDate: string): Promise<any> {
    return this.axios.get(`${this.entity}/by-employee`, {params: {fromDate, toDate}}).then(res => res.data);
  }

  static getExpensesByType(fromDate: string, toDate: string): Promise<any> {
    return this.axios.get(`${this.entity}/by-type`, {params: {fromDate, toDate}}).then(res => res.data);
  }

  static expenseStatuses = [
    {value: 'InvoiceSent', label: 'Mới tạo'},
    {value: 'InvoiceAcked', label: 'Đã duyệt'},
    {value: 'InvoiceCancelled', label: 'Không duyệt'}
  ]

}
