import {Fragment, useEffect, useRef, useState} from 'react';

import {Button} from 'primereact/button';
import {confirmPopup} from 'primereact/confirmpopup';
import {InputTextarea} from 'primereact/inputtextarea';
import {FormatDisplay} from '@iamsoftware/react-hooks';

import {TaskService as Service} from './TaskService';

export const Comments = ({me, workEffortId}) => {

  const commentsRef = useRef(null);

  const [comments, setComments] = useState([]);

  const [submitting, setSubmitting] = useState(false);
  const [communicationEventId, setCommunicationEventId] = useState(null);
  const [body, setBody] = useState('');

  useEffect(() => {
    loadComments();
  }, [workEffortId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    commentsRef.current?.scrollTo({
      top: commentsRef.current?.scrollHeight,
      behavior: 'smooth'
    });
  }, [comments]);

  const loadComments = () => {
    if (workEffortId) {
      Service.getComments(workEffortId, '').then(data => {
        setComments(data.listData);
      });
    }
  }

  const deleteComment = comment => event => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Bạn chắc chắn muốn xoá bình luận này?',
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Không',
      acceptLabel: 'Có',
      accept: () => {
        Service.deleteComment(workEffortId, comment.communicationEventId).then(() => {
          loadComments();
        });
      }
    });
  }

  const updateComment = comment => {
    setCommunicationEventId(comment.communicationEventId);
    setBody(comment.body);
  }

  const onSubmit = () => {
    if (!submitting) {
      setSubmitting(true);

      let promise;
      if (communicationEventId) {
        promise = Service.updateComment(workEffortId, communicationEventId, {body});
      } else {
        promise = Service.createComment(workEffortId, {body});
      }
      promise.then(() => {
        setCommunicationEventId(null);
        setBody('');
        loadComments();
      }).finally(() => {
        setSubmitting(false);
      });
    }
  }

  return (
    <div className="p-fluid formgrid grid">
      <div className="col-12 mb-2">
        <ul id="commentsRef" className="comments" ref={commentsRef}>
          {comments.map((comment, index) => <li key={index}>
            <div className="grid grid-nogutter">
              <div className="col-fixed col-align-top">
                <div className="icon-container"><i className="pi pi-user"></i></div>
              </div>
              <div className="col">
                <div className="event-owner">{comment.subject}</div>
                <div className="event-detail">{comment.body}</div>
                <span className="timeline-clock"><i className="pi pi-clock"></i><span>{FormatDisplay.dateTime(comment.entryDate)}</span></span>
                {me.partyId === comment.fromPartyId && <Fragment>
									<Button icon="pi pi-pencil" className="p-button-xs ml-2" rounded outlined severity="secondary" onClick={() => updateComment(comment)}/>
									<Button icon="pi pi-trash" className="p-button-xs ml-2" rounded outlined severity="danger" onClick={deleteComment(comment)}/>
                </Fragment>}
              </div>
            </div>
          </li>)}
        </ul>
      </div>
      <div className="col-11">
        <InputTextarea value={body} onChange={(e) => setBody(e.target.value)} autoResize/>
      </div>
      <div className="col-1 pl-0">
        <Button icon="pi pi-send" rounded onClick={onSubmit}/>
      </div>
    </div>
  );
}