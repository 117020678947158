import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Fieldset} from 'primereact/fieldset';
import {ToastService, useDataTableBasic} from '@iamsoftware/react-hooks';

import {OrganizationService} from './OrganizationService';

export const ZaloNotification = () => {

  const [submitting, setSubmitting] = useState(false);

  const [employeeDailyEvents, setEmployeeDailyEvents] = useState<any>({});
  const [creatorEvents, setCreatorEvents] = useState<any>({});
  const [assigneeEvents, setAssigneeEvents] = useState<any>({});

  const [customerEvents, setCustomerEvents] = useState<any>({});

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = () => {
    patchData('Zalo_EmployeeDailyEvents', setEmployeeDailyEvents, rawEmployeeDailyEvents);
    patchData('Zalo_CreatorEvents', setCreatorEvents, rawCreatorEvents);
    patchData('Zalo_AssigneeEvents', setAssigneeEvents, rawAssigneeEvents);
    patchData('Zalo_CustomerEvents', setCustomerEvents, rawCustomerEvents);
  }

  const patchData = (typeEnumId, set, raws) => {
    OrganizationService.getContentText(typeEnumId).then(({contentText}) => {
      let contentJson = [];
      try {
        contentJson = JSON.parse(contentText);
      } catch (e) {
      }
      set(raws.map(item => {
        for (const content of contentJson) {
          if (content.dataKey === item.dataKey) {

            if (content.recurHour) item.recurHour = content.recurHour;
            if (content.recurDays) item.recurDays = content.recurDays;
            if (content.allow) item.allow = content.allow;

            break;
          }
        }
        return item;
      }));
    });
  }

  const onEditorChange = setState => (rowData, column, newValue) => {
    switch (column.field) {
      case 'recurDays':
        newValue?.sort();
        break;
    }
    setState(prevItems => prevItems.map(prevItem => {
      if (prevItem['dataKey'] === rowData['dataKey']) {
        return {
          ...prevItem,
          [column.field]: newValue
        }
      }
      return prevItem;
    }));
  }

  const {render: renderEmployeeDailyEvents} = useDataTableBasic({
    tableHeader: 'Nhắc nhở hàng ngày',
    dataKey: 'dataKey',
    indexColumnWidth: 45,
    columns: [
      {field: 'event', header: 'Thống kê công việc', minWidth: 250},
      {
        field: 'recurHour', header: 'Giờ gửi', width: 150, dataType: 'editor', editorConfig: {
          onEditorChange: onEditorChange(setEmployeeDailyEvents), field: {type: 'InputMask', InputMaskProps: {mask: '99:99', placeholder: 'HH:mm'}}
        }
      },
      {
        field: 'recurDays', header: 'Các ngày', width: 230, dataType: 'editor', editorConfig: {
          onEditorChange: onEditorChange(setEmployeeDailyEvents), field: {type: 'MultiSelect', MultiSelectProps: {options: weekDays, className: 'w-full'}}
        }
      },
      {
        field: 'allow', header: 'Áp dụng', width: 120, dataType: 'editor', editorConfig: {
          onEditorChange: onEditorChange(setEmployeeDailyEvents), field: {type: 'Checkbox'}
        }, className: 'text-center'
      }
    ],
    items: employeeDailyEvents
  });

  const {render: renderCreatorEvents} = useDataTableBasic({
    tableHeader: 'Sự kiện theo trạng thái công việc cho Người tạo',
    dataKey: 'dataKey',
    indexColumnWidth: 45,
    columns: [
      {field: 'event', header: 'Sự kiện', minWidth: 250},
      {
        field: 'allow', header: 'Áp dụng', width: 120, dataType: 'editor', editorConfig: {
          onEditorChange: onEditorChange(setCreatorEvents), field: {type: 'Checkbox'}
        }, className: 'text-center'
      }
    ],
    items: creatorEvents
  });
  const {render: renderAssigneeEvents} = useDataTableBasic({
    tableHeader: 'Sự kiện theo trạng thái công việc cho Người được giao',
    dataKey: 'dataKey',
    indexColumnWidth: 45,
    columns: [
      {field: 'event', header: 'Sự kiện', minWidth: 250},
      {
        field: 'allow', header: 'Áp dụng', width: 120, dataType: 'editor', editorConfig: {
          onEditorChange: onEditorChange(setAssigneeEvents), field: {type: 'Checkbox'}
        }, className: 'text-center'
      }
    ],
    items: assigneeEvents
  });

  const {render: renderCustomerEvents} = useDataTableBasic({
    tableHeader: 'Sự kiện theo trạng thái công việc',
    dataKey: 'dataKey',
    indexColumnWidth: 45,
    columns: [
      {field: 'event', header: 'Sự kiện', minWidth: 250},
      {
        field: 'allow', header: 'Áp dụng', width: 120, dataType: 'editor', editorConfig: {
          onEditorChange: onEditorChange(setCustomerEvents), field: {type: 'Checkbox'}
        }, className: 'text-center'
      }
    ],
    items: customerEvents
  });

  const onSubmit = (typeEnumId, events) => {
    if (!submitting) {
      setSubmitting(true);

      OrganizationService.setContentText(typeEnumId, JSON.stringify(events)).then(() => {
        ToastService.success();
      }).catch(() => {
      }).finally(() => setSubmitting(false));
    }
  }

  return (
    <div className="grid">
      <div className="col-12">
        <Fieldset legend="Cấu hình thông báo Zalo nội bộ">
          <div className="grid">
            <div className="col-12">
              {renderEmployeeDailyEvents()}
            </div>
            <div className="col-12 text-center">
              <Button label="Lưu" icon="pi pi-check" size="small" onClick={() => onSubmit('Zalo_EmployeeDailyEvents', employeeDailyEvents)} loading={submitting}/>
            </div>
            <div className="col-12 mt-2">
              {renderCreatorEvents()}
            </div>
            <div className="col-12 text-center">
              <Button label="Lưu" icon="pi pi-check" size="small" onClick={() => onSubmit('Zalo_CreatorEvents', creatorEvents)} loading={submitting}/>
            </div>
            <div className="col-12 mt-2">
              {renderAssigneeEvents()}
            </div>
            <div className="col-12 text-center">
              <Button label="Lưu" icon="pi pi-check" size="small" onClick={() => onSubmit('Zalo_AssigneeEvents', assigneeEvents)} loading={submitting}/>
            </div>
          </div>
        </Fieldset>
      </div>
      <div className="col-12">
        <Fieldset legend="Cấu hình thông báo Zalo khách hàng">
          <div className="grid">
            <div className="col-12">
              {renderCustomerEvents()}
            </div>
            <div className="col-12 text-center">
              <Button label="Lưu" icon="pi pi-check" size="small" onClick={() => onSubmit('Zalo_CustomerEvents', customerEvents)} loading={submitting}/>
            </div>
          </div>
        </Fieldset>
      </div>
    </div>
  );
}

const weekDays = [
  {value: '1', label: 'CN'},
  {value: '2', label: 'T2'},
  {value: '3', label: 'T3'},
  {value: '4', label: 'T4'},
  {value: '5', label: 'T5'},
  {value: '6', label: 'T6'},
  {value: '7', label: 'T7'}
];

const rawEmployeeDailyEvents: Array<any> = [
  {dataKey: 'inPlanningTasks', event: 'Công việc Chưa xử lý', allow: false},
  {dataKey: 'inProgressTasks', event: 'Công việc Đang xử lý', allow: false},
  {dataKey: 'onHoldTasks', event: 'Công việc Đang chờ', allow: false},
  {dataKey: 'highPriorityTasks', event: 'Công việc Ưu tiên cao chưa hoàn thành', allow: false},
  {dataKey: 'inPlanningExpiredTasks', event: 'Công việc đã quá hạn Xử lý', allow: false},
  {dataKey: 'inProgressExpiredTasks', event: 'Công việc đã quá hạn Hoàn thành', allow: false}
];

const rawCreatorEvents: Array<any> = [
  {dataKey: 'WeInProgress', event: 'Công việc Đang thực hiện', allow: false},
  {dataKey: 'WeOnHold', event: 'Công việc Đang chờ', allow: false},
  {dataKey: 'WeComplete', event: 'Công việc Đã hoàn thành', allow: false},
  {dataKey: 'WeCancelled', event: 'Công việc Đã huỷ', allow: false}
];

const rawAssigneeEvents: Array<any> = [
  {dataKey: 'WeInPlanning', event: 'Công việc Mới được giao', allow: false},
  {dataKey: 'WeCancelled', event: 'Công việc Đã huỷ', allow: false}
];

const rawCustomerEvents: Array<any> = [
  {dataKey: 'WeInPlanning', event: 'Công việc Mới được tạo', allow: false},
  {dataKey: 'WeInProgress', event: 'Công việc Đang thực hiện', allow: false},
  {dataKey: 'WeComplete', event: 'Công việc Đã hoàn thành', allow: false},
  {dataKey: 'WeCancelled', event: 'Công việc Đã huỷ', allow: false}
];