type AppStorageKey = 'moquiSessionToken' | 'me' | 'ownerPartyTaxId';

const app = 'iam-hkd-react.v1.0.0';

export class AppStorage {

  static set(key: AppStorageKey, value: string): void {
    localStorage.setItem(`${app}_${key}`, value);
  }

  static get(key: AppStorageKey): string {
    return localStorage.getItem(`${app}_${key}`) || '';
  }

  static remove(key: AppStorageKey): void {
    localStorage.removeItem(`${app}_${key}`);
  }

}

type UserStorageKey = 'showHappyBirthday';

export class UserStorage {

  static id;

  static set(key: UserStorageKey, value: string): void {
    localStorage.setItem(`${app}_${this.id}_${key}`, value);
  }

  static get(key: UserStorageKey): string {
    return localStorage.getItem(`${app}_${this.id}_${key}`) || '';
  }

  static remove(key: UserStorageKey): void {
    localStorage.removeItem(`${app}_${this.id}_${key}`);
  }

}
