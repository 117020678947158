import {Fragment, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {SelectButton} from 'primereact/selectbutton';
import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useToolbar} from '@iamsoftware/react-hooks';

import {IamPermission} from 'src/shared/utils/Permission';

import {TaskService as Service} from './TaskService';

import {useCrupTask} from './CrupTask';
import {Resolve} from './Resolve';
import {Expenses} from './Expenses';

import {TaskProgression} from 'src/shared/components/TaskProgression';

const stages = [
  {value: 'Open', label: 'Chờ xử lý'},
  {value: 'Closed', label: 'Đã hoàn thành'},
  {value: 'Cancelled', label: 'Đã huỷ'}
];

export const Tasks = ({me, iamElasticHasChange}) => {

  const header = 'Công việc';
  const dataKey = 'workEffortId';

  const location = useLocation();

  const [stage, setStage] = useState(stages[0].value);

  const [requiredParams, setRequiredParams] = useState(null);

  const [assignerIsMe, setAssignerIsMe] = useState(false);

  const [statuses, setStatuses] = useState([]);

  const [displayResolve, setDisplayResolve] = useState(null);
  const [displayExpense, setDisplayExpense] = useState(null);

  useEffect(() => {
    Service.getStatuses().then(({listData}) => {
      setStatuses(listData.map(item => {
        return {value: item.statusId, label: item.description};
      }));
    });
    const subscription = iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('workEfforts') && data?.message?.workEffortTypeEnumIdSet?.includes('WetTask')) {
        refreshLazyData();
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location?.search) {
      const showResolve = new URLSearchParams(location?.search).get('showResolve');
      if (showResolve) {
        setDisplayResolve(showResolve);
      }
      const showDetail = new URLSearchParams(location?.search).get('showDetail');
      if (showDetail) {
        Service.get(showDetail).then(data => {
          doUpdateTask(showDetail, data, true);
        });
      }
    }
  }, [location?.search]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let _requiredParams;
    switch (stage) {
      case 'Open':
        _requiredParams = {statusId: {value: ['WeInPlanning', 'WeApproved', 'WeInProgress', 'WeOnHold'], matchMode: 'in'}};
        break;
      case 'Closed':
        _requiredParams = {statusId: {value: ['WeComplete', 'WeClosed'], matchMode: 'in'}};
        break;
      case 'Cancelled':
        _requiredParams = {statusId: {value: ['WeCancelled'], matchMode: 'in'}};
        break;
    }
    if (_requiredParams) {
      if (assignerIsMe) {
        _requiredParams['parties.partyId'] = {value: me.partyId, matchMode: 'equals'};
      }
      setRequiredParams(_requiredParams);
    }
  }, [stage, assignerIsMe]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, refreshLazyData} = useDataTable({
    stateKey: `ResolvedTasks_${stage}`,
    tableHeader: header,
    dataKey,
    columns: [
      {field: 'workEffortId', header: 'Mã CV', width: 100, matchMode: 'equals'},
      {
        field: 'workEffortName', header: 'Công việc', minWidth: 220, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <div title={`Công việc tạo bởi ${rowData.createdByPartyName}`}>
            {rowData.workEffortName}
            <TaskProgression progression={rowData.taskProgression} percentComplete={rowData.percentComplete} className="mt-1"/>
          </div>
        }
      },
      {field: 'priority', header: 'Ưu tiên', width: 90, matchMode: 'numeric', dataType: 'number'},
      {
        field: 'estimatedStartDate', header: 'Ngày bắt đầu', width: 170, dataType: 'custom', customCell(rowData: any): any {
          let _className = '';
          if (['WeInPlanning'].includes(rowData.statusId) && rowData.estimatedStartDate < Date.now()) {
            _className = 'alert-danger';
          }
          return <span className={_className}>{FormatDisplay.dateTime(rowData.estimatedStartDate)}</span>
        }
      },
      {
        field: 'estimatedCompletionDate', header: 'Ngày hoàn thành', width: 170, dataType: 'custom', customCell(rowData: any): any {
          let _className = '';
          if (['WeInPlanning', 'WeApproved', 'WeInProgress', 'WeOnHold'].includes(rowData.statusId) && rowData.estimatedCompletionDate < Date.now()) {
            _className = 'alert-danger';
          }
          return <span className={_className}>{FormatDisplay.dateTime(rowData.estimatedCompletionDate)}</span>
        }
      },
      {field: 'description', header: 'Ghi chú', minWidth: 150, sortable: false, matchMode: 'contains'},
      {field: 'status', filterField: 'statusId', header: 'Trạng thái', width: 150, matchMode: 'contains', filterType: 'dropdown', filterOptions: statuses, hidden: stage !== 'Open'},
      {
        field: 'employee', filterField: 'parties.partyPseudoId', header: 'Nhân viên', minWidth: 180, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'Assignee')
            ?.map((party, index) => <p key={index} className="mb-0" title={`Công việc tạo bởi ${rowData.createdByPartyName}`}>[{party.partyPseudoId}] {party.partyName}</p>);
        }
      },
      {
        field: 'team', filterField: 'parties.partyName@IAM@1', header: 'Nhóm', width: 150, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'OrgTeam')
            ?.map((party, index) => <p key={index} className="mb-0">{party.partyName}</p>);
        }
      },
      {
        field: 'customerName', filterField: 'parties.partyName@IAM@2', header: 'Khách hàng', minWidth: 180, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'Customer')
            ?.map((party, index) => <p key={index} className="mb-0">- {party.partyName}</p>);
        }, hidden: true
      },
      {
        field: 'customerPseudoId', filterField: 'parties.partyPseudoId@IAM@1', header: 'MST khách hàng', minWidth: 180, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'Customer')
            ?.map((party, index) => <p key={index} className="mb-0">- {party.partyPseudoId}</p>);
        }, hidden: true
      },
      {field: 'createdByPartyName', header: 'Người tạo', width: 150, matchMode: 'contains', hidden: true}
    ],
    indexColumnWidth: 45,
    elastic: {
      tie_breaker_id: dataKey,
      sort: stage === 'Open' ? [{priority: 'asc'}, {estimatedCompletionDate: 'asc'}] : [{lastUpdatedStamp: 'desc'}],
      getPitId(): Promise<any> {
        return Service.getPitId();
      },
      deletePit(id: string): Promise<any> {
        return Service.deletePit(id);
      }
    },
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  const {renderCrupTask, doCreateTask, doUpdateTask} = useCrupTask({
    me,
    setDisplayResolve
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doUpdate = (doView?: boolean) => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.get(selectedItem[dataKey]).then(data => {
        doUpdateTask(selectedItem[dataKey], data, doView);
      });
    }
  }

  const doCancel = () => {
    if (selectedItem && selectedItem[dataKey]) {
      ConfirmDialogService.confirm('Huỷ công việc', `Xác nhận huỷ công việc ([${selectedItem.workEffortId}] - ${selectedItem.workEffortName})?`, () => {
        Service.updateStatus(selectedItem[dataKey], 'WeCancelled').then(() => {
          ToastService.success();
        });
      });
    }
  }

  let canUpdate = false;
  if (['WeInPlanning', 'WeOnHold'].includes(selectedItem?.statusId)) {
    if (IamPermission.has('UPDATE', 'UpdateTask') || selectedItem?.createdByUserId === me.userId) {
      canUpdate = true;
    }
  }

  let canCancel = false;
  if (['WeInPlanning', 'WeInProgress', 'WeOnHold'].includes(selectedItem?.statusId)) {
    if (IamPermission.has('UPDATE', 'UpdateTask') && selectedItem?.createdByUserId === me.userId) {
      canCancel = true;
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate: IamPermission.has('CREATE') && doCreateTask,
    hasSelectedItem: selectedItem,
    doView: () => doUpdate(true),
    leftButtons: <Fragment>
      {IamPermission.has('UPDATE') && <Button label="Chỉnh sửa" icon="pi pi-pencil" severity="warning" onClick={() => doUpdate(selectedItem)} disabled={!canUpdate}/>}
      {IamPermission.has('UPDATE') && stage === 'Open' && <Button label="Huỷ công việc" icon="pi pi-times" severity="danger" onClick={doCancel} disabled={!canCancel}/>}
      <Button label="Xử lý" icon="pi pi-spin pi-bolt" severity="info" onClick={() => setDisplayResolve(selectedItem[dataKey])} disabled={!selectedItem}/>
      <Button label="Chi phí" icon="pi pi-dollar" severity="help" onClick={() => setDisplayExpense(selectedItem[dataKey])} disabled={!selectedItem}/>
    </Fragment>,
    rightButtons: <div className="field-checkbox mb-0">
      <Checkbox inputId="assignerIsMe" onChange={e => setAssignerIsMe(e.checked)} checked={assignerIsMe}></Checkbox>
      <label htmlFor="assignerIsMe">Công việc của tôi</label>
    </div>
  });

  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={stage} options={stages} onChange={e => setStage(e.value)}/>
      </div>
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 17.58rem)'}}>
        {renderDataTable()}
      </div>

      {renderCrupTask()}

      <Resolve me={me} display={displayResolve} setDisplay={setDisplayResolve}/>
      <Expenses me={me} iamElasticHasChange={iamElasticHasChange} display={displayExpense} setDisplay={setDisplayExpense}/>
    </div>
  );
}