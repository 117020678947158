import {EntityService} from 'src/service/EntityService';

export class TeamService extends EntityService {

  static entity = 'iam-party/teams';

  static roleTypes = [
    {value: 'Assignee', label: 'Nhân viên'},
    {value: 'Manager', label: 'Trưởng nhóm'}
  ];

  static getDepartments(teamId: string, lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/${teamId}/departments/list?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static createDepartment(teamId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${teamId}/departments/create`, data).then(res => res.data);
  }

  static updateDepartment(teamId: string, id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${teamId}/departments/${id}/update`, data).then(res => res.data);
  }

  static getEmployees(teamId: string, lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/${teamId}/employees/list?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static createEmployee(teamId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${teamId}/employees/create`, data).then(res => res.data);
  }

  static updateEmployee(teamId: string, id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${teamId}/employees/${id}/update`, data).then(res => res.data);
  }

  static getClients(teamId: string, lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/${teamId}/clients/list?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static createClient(teamId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${teamId}/clients/create`, data).then(res => res.data);
  }

  static updateClient(teamId: string, id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${teamId}/clients/${id}/update`, data).then(res => res.data);
  }

}
