import {useEffect, useRef, useState} from 'react';

import moment from 'moment';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Dropdown} from 'primereact/dropdown';
import {Fieldset} from 'primereact/fieldset';
import {ToastService, OfficeAppsViewer, useDialogCrup, useFileUploader, FormatDisplay} from '@iamsoftware/react-hooks';

import {TaskService as Service} from './TaskService';
import {ServiceService} from '../../danh-muc/dich-vu/ServiceService';
import {AuthService} from 'src/service/AuthService';

import {TinyEditor} from 'src/shared/components/Tiny-Editor';

import {StatusHistories} from './StatusHistories';
import {Comments} from './Comments';

export const Resolve = ({me, display, setDisplay}) => {

  const [workEffort, setWorkEffort] = useState(null);

  useEffect(() => {
    if (display) {
      Service.get(display).then(data => {
        setWorkEffort(data);
      });
    } else {
      setWorkEffort(null);
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  const {renderScreen, footer} = useScreen({workEffort, setDisplay});

  return (
    <Dialog header={`Xử lý công việc #${workEffort?.workEffortId} - ${workEffort?.workEffortName}`} footer={footer} visible={!!display} maximized onHide={() => setDisplay(false)}>
      <div className="grid">
        <div className="col">
          {renderScreen()}
        </div>
        <div className="col-fixed" style={{width: '30rem'}}>
          <Fieldset legend="Tiến độ" toggleable className="p-fieldset-sm">
            <StatusHistories me={me} workEffort={workEffort} setWorkEffort={setWorkEffort}/>
          </Fieldset>
          <br/>
          <Fieldset legend="Trao đổi" toggleable>
            <Comments me={me} workEffortId={workEffort?.workEffortId}/>
          </Fieldset>
        </div>
      </div>
    </Dialog>
  );
}

const useScreen = ({workEffort, setDisplay}) => {

  const editorRef = useRef(null);

  const [submitting, setSubmitting] = useState(false);

  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);

  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState(null);

  const [serviceTemplate, setServiceTemplate] = useState({});

  const [editorReadonly, setEditorReadonly] = useState(false);
  const [editorValue, setEditorValue] = useState(null);

  const [attachments, setAttachments] = useState(null);

  const [displayViewer, setDisplayViewer] = useState(null);

  const onFileSelect = async file => {
    if (file.key) {
      setDisplayViewer({type: file.contentType, name: file.fileName, src: file.viewSrc});
    } else {
      const src = window.URL.createObjectURL(new Blob([file], {type: file.type}));
      window.open(src);
    }
  }

  const {renderFileUploader: renderAttachments, setFileUploaderDisabled, getFiles: getAttachments, deletedExistingFiles: deletedAttachments} = useFileUploader({
    accept: 'image/*, application/pdf, .xlsx, .docx, .ppt',
    onSelect: onFileSelect,
    existingFiles: attachments
  });

  useEffect(() => {
    setEditorReadonly(workEffort?.statusId !== 'WeInProgress');
    setFileUploaderDisabled(workEffort?.statusId !== 'WeInProgress');
  }, [workEffort?.statusId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    setClients([]);
    setClient(null);
    setTemplates([]);
    setTemplate(null);
    setServiceTemplate({});

    if (workEffort?.workEffortId) {
      Service.getClients(workEffort.workEffortId).then(data => {
        setClients(data?.clients);
      });
    }
  }, [workEffort?.workEffortId]);

  useEffect(() => {
    if (workEffort?.serviceId) {
      ServiceService.getContents(workEffort?.serviceId, '').then(data => {
        setTemplates(data.listData);
      });
    }
  }, [workEffort?.serviceId]);

  useEffect(() => {
    if (template?.productId && template?.productContentId) {
      if (!serviceTemplate[template.productContentId]?.serviceTemplate) {
        ServiceService.getContent(template.productId, template.productContentId).then(({serviceTemplate}) => {
          setServiceTemplate(prevState => {
            if (!prevState[template.productContentId]) {
              prevState[template.productContentId] = {};
            }
            prevState[template.productContentId].serviceTemplate = serviceTemplate;
            return {...prevState};
          });
        });
      }
    }
  }, [template?.productId, template?.productContentId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getResolvedContent = _workEffortId => {
    Service.getResolvedContent(_workEffortId).then(data => {
      if (data?.contentTemplateId && data.resolvedContent) {
        templates.forEach(_template => {
          if (_template.productContentId === data.contentTemplateId) {
            setTemplate(_template);

            setServiceTemplate(prevState => {
              if (!prevState[_template.productContentId]) {
                prevState[_template.productContentId] = {};
              }
              prevState[_template.productContentId][_workEffortId] = data.resolvedContent;
              return {...prevState};
            });
          }
        });
      } else {
        setServiceTemplate(prevState => {
          return {...prevState};
        });
      }

      if (data?.attachments?.length) {
        AuthService.getApiKey().then(({token}) => {
          const _attachments = [];
          for (const attachment of data.attachments) {
            _attachments.push({
              key: attachment.workEffortContentId,
              contentType: attachment.contentType,
              fileName: attachment.fileName,
              src: `${Service.baseURL}/${Service.entity}/${_workEffortId}/contents/${attachment.workEffortContentId}/download`,
              viewSrc: `${window.location.origin}${Service.baseURL}/iam-content/${token}/work-effort/${attachment.workEffortContentId}/download`
            });
          }
          setAttachments(_attachments);
        });
      } else {
        setAttachments([]);
      }
    });
  }

  useEffect(() => {
    if (workEffort?.serviceClassEnumId === 'PclsServiceTaskSingleClient' && client?.workEffortId && templates?.length) {
      getResolvedContent(client.workEffortId);
    }
  }, [workEffort?.serviceClassEnumId, client?.workEffortId, templates]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (workEffort?.serviceClassEnumId === 'PclsServiceTaskCompoundClient' && workEffort?.workEffortId && templates?.length) {
      getResolvedContent(workEffort.workEffortId);
    }
  }, [workEffort?.serviceClassEnumId, workEffort?.workEffortId, templates]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setEditorValue('');
    if (workEffort?.serviceClassEnumId && serviceTemplate && template?.productContentId && serviceTemplate[template.productContentId]) {
      switch (workEffort?.serviceClassEnumId) {
        case 'PclsServiceTaskSingleClient':
          if (client?.workEffortId) {
            if (serviceTemplate[template.productContentId][client?.workEffortId]) {
              setEditorValue(serviceTemplate[template.productContentId][client?.workEffortId]);
            } else {
              makeSingleEditor(serviceTemplate[template.productContentId].serviceTemplate);
            }
          }
          break;
        case 'PclsServiceTaskCompoundClient':
          if (workEffort?.workEffortId) {
            if (serviceTemplate[template.productContentId][workEffort?.workEffortId]) {
              setEditorValue(serviceTemplate[template.productContentId][workEffort?.workEffortId]);
            } else {
              makeCompoundEditor(serviceTemplate[template.productContentId].serviceTemplate);
            }
          }
          break;
      }
    }
  }, [workEffort?.serviceClassEnumId, template?.productContentId, serviceTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

  const makeSingleEditor = html => {
    const div = document.createElement('div');
    div.innerHTML = html.trim();
    if (client) {
      for (const ref of div.querySelectorAll('[data-iam-ref-as]') as any) {
        fillElement(ref.getAttribute('data-iam-ref-as'), ref, client, 0);
      }
    }
    setEditorValue(div.innerHTML);
  }

  const makeCompoundEditor = (html: string, param_clients?: Array<any>) => {
    const div = document.createElement('div');
    div.innerHTML = html.trim();

    for (const tableElement of div.getElementsByTagName('table') as any) {
      try {
        if (tableElement.innerHTML.includes('data-iam-th-as')) {

          tableElement.querySelector('tbody').innerHTML = '';

          (param_clients || clients)?.forEach((client, clientIndex) => {
            const tr = document.createElement('tr');

            for (const thElement of tableElement.querySelector('thead > tr:first-child')?.getElementsByTagName('th') as any) {
              const td = document.createElement('td');

              if (thElement.getAttribute('data-iam-th-as')) {
                fillElement(thElement.getAttribute('data-iam-th-as'), td, client, clientIndex);
              }
              const colspan = thElement.getAttribute('colspan');
              if (colspan && parseInt(colspan) > 0) {
                if (thElement.getAttribute('data-iam-th-as')) {
                  td.setAttribute('colspan', colspan);
                } else {
                  for (let i = 1; i < colspan; i++) {
                    tr.append(document.createElement('td'));
                  }
                }
              }
              tr.append(td);
            }
            tableElement.querySelector('tbody').append(tr);
          });
        }
      } catch (e) {
        console.log(e);
      }
    }

    setEditorValue(div.innerHTML);
  }

  const fillElement = (attribute, element, _client, _clientIndex) => {
    switch (attribute) {
      case 'index':
        element.textContent = _clientIndex + 1;
        element.style.textAlign = 'center';
        break;
      case 'clientTaxId':
        element.textContent = _client.partyTaxId;
        break;
      case 'clientName':
        element.textContent = _client.partyName;
        break;
      case 'clientAddress':
        element.textContent = _client.address1;
        break;
      case 'clientPhoneNumber':
        element.textContent = _client.phoneNumber;
        break;
      case 'clientEmailAddress':
        element.textContent = _client.emailAddress;
        break;
      case 'serviceFeatureId@IAM@WeTaskResolvedDate':
        element.textContent = FormatDisplay.date(new Date());
        break;
      default:
        if (_client.additionalInfos?.length && attribute?.includes('clientAdditionalInfo@IAM@')) {
          for (const additionalInfo of _client.additionalInfos) {
            if (additionalInfo.partyContentTypeEnumId === attribute.replace('clientAdditionalInfo@IAM@', '')) {
              element.textContent = additionalInfo.description;
              break;
            }
          }
        }
    }
  }

  const {render: renderDialogSort, update: sort} = useDialogCrup({
    header: '!Sắp xếp danh sách',
    width: '40rem',
    fields: [
      {
        field: 'field', header: 'Trường', required: true, type: 'Dropdown', DropdownProps: {
          options: [
            {value: 'partyTaxId', label: 'Mã số thuế'},
            {value: 'partyName', label: 'Tên khách hàng'},
            {value: 'fromDate', label: 'Thời điểm thêm'}
          ]
        }, className: 'md:col-12'
      },
      {
        field: 'order', header: 'Thứ tự', required: true, type: 'Dropdown', DropdownProps: {
          options: [
            {value: 'ASC', label: 'Tăng dần'},
            {value: 'DESC', label: 'Giảm dần'}
          ]
        }, className: 'md:col-12'
      }
    ],
    updateItem(id: string, item: any): Promise<any> {
      return Promise.resolve(item);
    },
    reloadLazyData: item => {
      if (template.productContentId && serviceTemplate && serviceTemplate[template.productContentId]?.serviceTemplate && clients?.length) {
        const _clients = [...clients];
        _clients.sort((a, b) => {
          switch (item.order) {
            case 'ASC':
              if (typeof a[item.field] === 'number') {
                return a[item.field] - b[item.field];
              } else if (typeof a[item.field] === 'string') {
                return a[item.field]?.localeCompare(b[item.field])
              }
              break;
            case 'DESC':
              if (typeof b[item.field] === 'number') {
                return b[item.field] - a[item.field];
              } else if (typeof b[item.field] === 'string') {
                return b[item.field]?.localeCompare(a[item.field])
              }
              break;
          }
          return 0;
        });
        makeCompoundEditor(serviceTemplate[template.productContentId].serviceTemplate, _clients);
      }
    },
    submitLabel: 'Áp dụng',
    hideToastMessage: true
  });

  const renderScreen = () => {
    return (
      <div className="p-fluid formgrid grid mt-1">
        {workEffort?.serviceClassEnumId === 'PclsServiceTaskSingleClient' && <div className="col-6">
					<Dropdown value={client} onChange={e => setClient(e.value)} options={clients} dataKey="workEffortId" optionLabel="partyName" placeholder="Chọn khách hàng"/>
				</div>}
        <div className="col-6">
          <Dropdown value={template} onChange={e => setTemplate(e.value)} options={templates} dataKey="productContentId" optionLabel="description" placeholder="Chọn biểu mẫu"/>
        </div>
        {(workEffort?.serviceClassEnumId === 'PclsServiceTaskCompoundClient' && template?.productContentId) && <div className="col-6 un-fluid">
					<Button label="Sắp xếp danh sách" icon="pi pi-sort" outlined severity="success" onClick={sort}/>
          {renderDialogSort()}
				</div>}
        <div className="col-12 mt-3">
          <TinyEditor ref={editorRef} mode="document" height="calc(100vh - 30rem)" readonly={editorReadonly} initialValue={editorValue}/>
        </div>
        <div className="col-12 mt-2">
          {renderAttachments()}
        </div>
        <OfficeAppsViewer display={displayViewer} setDisplay={setDisplayViewer}/>
      </div>
    );
  }

  const onSubmit = async event => {
    if (!submitting) {
      setSubmitting(true);

      const data: any = {
        contentTemplateId: template?.productContentId,
        resolvedContent: await editorRef.current.getContent()
      };
      let taskResolvedDate;

      if (data.resolvedContent) {
        const div = document.createElement('div');
        div.innerHTML = data.resolvedContent.trim();
        for (const ref of div.querySelectorAll('[data-iam-ref-as]') as any) {
          if (ref.getAttribute('data-iam-ref-as')?.includes('serviceFeatureId@IAM@')) {
            switch (ref.getAttribute('data-iam-ref-as').replace('serviceFeatureId@IAM@', '')) {
              case 'WeTaskNo':
                data.taskNo = ref.textContent?.trim();
                break;
              case 'WeTaskResolvedDate':
                taskResolvedDate = ref.textContent?.trim();
                break;
            }
          }
        }
        const _taskProgression = {total: 0, resolved: 0};
        for (const tableElement of div.getElementsByTagName('table') as any) {
          try {
            if (tableElement.querySelector('thead > tr:first-child')) {
              for (const thElement of tableElement.querySelector('thead > tr:first-child')?.getElementsByTagName('th') as any) {
                if (thElement.getAttribute('data-iam-th-as')?.includes('serviceFeatureId@IAM@')) {
                  switch (thElement.getAttribute('data-iam-th-as').replace('serviceFeatureId@IAM@', '')) {
                    case 'WeTaskProgression':
                      for (const tdElement of tableElement.querySelectorAll(`tbody > tr > td:nth-child(${thElement.cellIndex + 1})`) as any) {
                        _taskProgression.total++;
                        if (tdElement.textContent?.trim()) {
                          _taskProgression.resolved++;
                        }
                      }
                      break;
                  }
                }
              }
            }
          } catch (e) {
            console.log(e);
          }
        }
        if (_taskProgression.total) {
          if (_taskProgression.resolved) {
            data.percentComplete = Math.round(_taskProgression.resolved / _taskProgression.total * 100);
          }
          data.taskProgression = JSON.stringify(_taskProgression);
        }

        if (taskResolvedDate) {
          if (taskResolvedDate.includes(' ')) {
            if (moment(taskResolvedDate, 'HH:mm:ss DD/MM/YYYY', true).isValid()) {
              data.taskResolvedDate = moment(taskResolvedDate, 'HH:mm:ss DD/MM/YYYY', true).valueOf();
            }
          } else {
            if (moment(taskResolvedDate, 'DD/MM/YYYY', true).isValid()) {
              data.taskResolvedDate = moment(taskResolvedDate, 'DD/MM/YYYY', true).valueOf();
            }
          }
          if (!data.taskResolvedDate) {
            return ToastService.error(`Ngày không hợp lệ: ${taskResolvedDate}`);
          }
        }

        data.attachments = await getAttachments(false);
        if (data.attachments.length === 1) {
          data.attachment = data.attachments[0];
          delete data.attachments;
        }
        if (deletedAttachments?.length) {
          if (deletedAttachments.length === 1) {
            data.deletedAttachment = deletedAttachments[0];
          } else {
            data.deletedAttachments = deletedAttachments;
          }
        }

        let _workEffortId;
        switch (workEffort.serviceClassEnumId) {
          case 'PclsServiceTaskSingleClient':
            _workEffortId = client.workEffortId;
            break;
          case 'PclsServiceTaskCompoundClient':
            _workEffortId = workEffort.workEffortId;
            break;
        }

        Service.resolve(_workEffortId, Service.toFormData(data)).then(() => {
          ToastService.success();
        }).finally(() => setSubmitting(false));
      } else {
        ToastService.error('Chưa có nội dung!');
        setSubmitting(false);
      }
    }
    event.preventDefault();
  }

  const footer = (
    <div className="flex justify-content-center">
      {workEffort?.statusId === 'WeInProgress' && <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit} loading={submitting}/>}
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );

  return {renderScreen, footer};
}