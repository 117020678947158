import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {FormatDisplay} from '@iamsoftware/react-hooks';

import {TaskService as Service} from './TaskService';

export const StatusHistories = ({me, workEffort, setWorkEffort}) => {

  const [submitting, setSubmitting] = useState(false);

  const [histories, setHistories] = useState([]);

  useEffect(() => {
    if (workEffort?.workEffortId) {
      loadStatusHistories();
    }
  }, [workEffort?.workEffortId]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadStatusHistories = () => {
    Service.getStatusHistories(workEffort.workEffortId).then(data => {
      setHistories(data.listData);
    });
  }

  const updateTaskStatus = value => {
    if (!submitting) {
      setSubmitting(true);

      Service.updateStatus(workEffort.workEffortId, value).then(() => {
        loadStatusHistories();
        setWorkEffort(prevState => {
          prevState.statusId = value;
          return prevState;
        });
      }).finally(() => setSubmitting(false));
    }
  }

  return (
    <Fragment>
      <div className="grid">
        {(me.roleTypeId === 'Administrator' || workEffort?.employees?.includes(me.partyId)) && <div className="col-12 flex gap-2">
          {['WeInPlanning', 'WeOnHold'].includes(workEffort?.statusId) && <Button label="Đang thực hiện" icon="pi pi-bolt" severity="success" outlined size="small"
																																									onClick={() => updateTaskStatus('WeInProgress')} loading={submitting}/>}
          {['WeInPlanning', 'WeInProgress'].includes(workEffort?.statusId) && <Button label="Đang chờ" icon="pi pi-bolt" severity="warning" outlined size="small"
																																											onClick={() => updateTaskStatus('WeOnHold')} loading={submitting}/>}
          {['WeInProgress', 'WeOnHold'].includes(workEffort?.statusId) && <Button label="Hoàn thành" icon="pi pi-bolt" outlined size="small"
																																									onClick={() => updateTaskStatus('WeComplete')} loading={submitting}/>}
          {['WeInPlanning', 'WeInProgress', 'WeOnHold'].includes(workEffort?.statusId) && <Button label="Huỷ" icon="pi pi-bolt" outlined severity="danger" size="small"
																																																	onClick={() => updateTaskStatus('WeCancelled')} loading={submitting}/>}
				</div>}
      </div>
      <div className="p-datatable p-component p-datatable-gridlines p-datatable-sm">
        <div className="p-datatable-wrapper">
          <table className="p-datatable-table" style={{borderTop: '1px solid #dee2e6'}}>
            <tbody className="p-datatable-tbody">
            {histories.map((history, index) => <tr key={index}>
              <td>{history.newStatus}</td>
              <td>{FormatDisplay.dateTime(history.changedDate)}</td>
              <td>{history.changedByUserName}</td>
            </tr>)}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
}