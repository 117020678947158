import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {IamPermission} from 'src/shared/utils/Permission';

import {TeamService as Service} from './TeamService';

import {Departments} from './Departments';
import {Employees} from './Employees';
import {Clients} from './Clients';

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Ngừng hoạt động'}
];

export const Teams = ({me}) => {

  const header = 'Nhóm nhân viên';
  const dataKey = 'partyId';

  const [statusId, setStatusId] = useState(statuses[0].value);

  const [requiredParams, setRequiredParams] = useState(null);

  const [displayDepartments, setDisplayDepartments] = useState(null);
  const [displayEmployees, setDisplayEmployees] = useState(null);
  const [displayClients, setDisplayClients] = useState(null);

  useEffect(() => {
    if (statusId) {
      setRequiredParams({disabled: {value: statusId, matchMode: 'equals'}});
    } else {
      setRequiredParams({});
    }
  }, [statusId]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: header,
    dataKey,
    columns: [
      {field: 'pseudoId', header: 'Mã nhóm', width: 250, matchMode: 'contains'},
      {field: 'partyName', header: 'Tên nhóm', minWidth: 250, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'pseudoId', header: 'Mã nhóm', required: true, className: 'md:col-12'},
      {field: 'partyName', header: 'Tên nhóm', required: true, className: 'md:col-12'}
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doUpdate = () => {
    if (selectedItem && selectedItem[dataKey]) {
      update(selectedItem);
    }
  }

  const doDisable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.disable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }
  const doEnable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.enable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate: IamPermission.has('CREATE') && create,
    hasSelectedItem: selectedItem,
    doUpdate: IamPermission.has('UPDATE') && doUpdate,
    leftButtons: <Fragment>
      {IamPermission.has('UPDATE') && <Fragment>
        {statusId === statuses[0].value && <Button label="Khoá" icon="pi pi-lock" severity="danger" size="small" onClick={doDisable} disabled={!selectedItem}/>}
        {statusId === statuses[1].value && <Button label="Mở khoá" icon="pi pi-lock" severity="success" size="small" onClick={doEnable} disabled={!selectedItem}/>}
      </Fragment>}
      <Button label="Phòng ban" icon="pi pi-sitemap" severity="help" size="small" onClick={() => setDisplayDepartments(selectedItem[dataKey])} disabled={!selectedItem}/>
      <Button label="Nhân viên" icon="pi pi-users" severity="help" size="small" onClick={() => setDisplayEmployees(selectedItem[dataKey])} disabled={!selectedItem}/>
      <Button label="Khách hàng" icon="pi pi-users" severity="help" size="small" onClick={() => setDisplayClients(selectedItem[dataKey])} disabled={!selectedItem}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 17.48rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrup()}

      <Departments display={displayDepartments} setDisplay={setDisplayDepartments}/>
      <Employees me={me} display={displayEmployees} setDisplay={setDisplayEmployees}/>
      <Clients display={displayClients} setDisplay={setDisplayClients}/>
    </div>
  );
}
