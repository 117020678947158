import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {Tooltip} from 'primereact/tooltip';

import {FormatDisplay, ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {TaskHeadingService as Service} from './TaskHeadingService';
import {TaskService} from '../danh-sach/TaskService';
import {ServiceService} from '../../danh-muc/dich-vu/ServiceService';
import {TeamService} from '../../he-thong/team-nhom/TeamService';
import {ClientService} from '../../danh-muc/khach-hang/ClientService';

import {convertToCron, cronOptions} from 'src/shared/utils/Cron';

const statuses = [
  {value: 'WeInPlanning', label: 'Đang hoạt động'},
  {value: 'WeClosed', label: 'Ngừng hoạt động'}
];

export const TaskHeadings = ({iamElasticHasChange}) => {

  const header = 'Đầu mục công việc';
  const dataKey = 'workEffortId';

  const [statusId, setStatusId] = useState(statuses[0].value);

  const [requiredParams, setRequiredParams] = useState(null);

  const [services, setServices] = useState([]);
  const [teams, setTeams] = useState([]);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const _event: any = {
      filters: {disabled: {value: 'N', matchMode: 'equals'}}
    };
    TeamService.getList(JSON.stringify(_event)).then(({listData}) => {
      setTeams(listData.map(item => {
        return {value: item.partyId, label: item.partyName};
      }));
    });
    const subscription = iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('workEfforts') && data?.message?.workEffortTypeEnumIdSet?.includes('WetTask')) {
        refreshLazyData();
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (statusId) {
      setRequiredParams({statusId: {value: statusId, matchMode: 'equals'}});
    } else {
      setRequiredParams({});
    }
  }, [statusId]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, refreshLazyData, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: header,
    dataKey,
    columns: [
      {field: 'workEffortId', header: 'Mã CV', width: 100, matchMode: 'equals'},
      {field: 'workEffortName', header: 'Công việc', minWidth: 250, matchMode: 'contains'},
      {field: 'priority', header: 'Ưu tiên', width: 90, matchMode: 'numeric', dataType: 'number'},
      {field: 'estimatedStartDate', header: 'Ngày bắt đầu', width: 170, dataType: 'date-time'},
      {field: 'recurCronOption', header: 'Chu kỳ', width: 120, matchMode: 'equals', filterType: 'dropdown', filterOptions: cronOptions.filter(it => it.value), dataType: 'fromFilterOptions'},
      {
        field: 'team', filterField: 'parties.partyName@IAM@1', header: 'Nhóm', width: 150, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'OrgTeam')
            ?.map((party, index) => <p key={index} className="mb-0" title={`Công việc tạo bởi ${rowData.createdByPartyName}`}>{party.partyName}</p>);
        }
      },
      {
        field: 'customerName', filterField: 'parties.partyName@IAM@2', header: 'Khách hàng', minWidth: 180, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'Customer')
            ?.map((party, index) => <p key={index} className="mb-0">- {party.partyName}</p>);
        }
      },
      {
        field: 'customerPseudoId', filterField: 'parties.partyPseudoId', header: 'MST khách hàng', minWidth: 180, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'Customer')
            ?.map((party, index) => <p key={index} className="mb-0">- {party.partyPseudoId}</p>);
        }
      },
      {field: 'createdByPartyName', header: 'Người tạo', width: 150, matchMode: 'contains', hidden: true}
    ],
    indexColumnWidth: 45,
    elastic: {
      tie_breaker_id: dataKey,
      sort: [
        {lastUpdatedStamp: 'desc'}
      ],
      getPitId(): Promise<any> {
        return TaskService.getPitId();
      },
      deletePit(id: string): Promise<any> {
        return TaskService.deletePit(id);
      }
    },
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  const searchServices = event => {
    ServiceService.find(event.query).then(data => {
      setServices(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.productId, label: `[${item.pseudoId}] - ${item.productName}`};
      }));
    });
  }

  const searchClients = event => {
    const {teamId} = form.getValue();
    if (teamId) {
      ClientService.find(event.query, teamId).then(data => {
        setClients(data.listData.map(item => {
          delete item.disabled;
          return {...item, value: item.partyId, label: `[${item.partyTaxId}] - ${item.partyName}`};
        }));
      });
    }
  }

  const {render: renderDialogCrup, create, view, update, form} = useDialogCrup({
    header,
    dataKey,
    width: '60rem',
    fields: [
      {
        field: 'service', header: 'Dịch vụ', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: services, completeMethod: searchServices, field: 'label', dropdown: true, forceSelection: true},
        className: 'md:col-12'
      },
      {field: 'workEffortName', header: 'Tên công việc', required: true, className: 'md:col-12'},
      {field: 'estimatedStartDate', header: 'Ngày bắt đầu', required: true, InputTextProps: {type: 'datetime-local'}, className: 'md:col-6'},
      {field: 'recurStartCron', type: 'hidden'},
      {field: 'recurCronOption', header: 'Chu kỳ', type: 'Dropdown', DropdownProps: {options: cronOptions, className: 'cron-expression'}, className: 'md:col-6'},
      {
        type: 'template', template(item: any): any {
          if (item.recurStartCron) {
            return <Tooltip target=".cron-expression" event="hover" position="bottom">
              {item.recurStartCron}
            </Tooltip>
          }
          return '';
        }, className: 'md:col-12 m-0'
      },
      // {field: 'recurStartCron', header: 'Chu kỳ', InputTextProps: {className: 'cron-expression'} as any, className: 'md:col-12'},
      // {
      //   type: 'custom', body: <Tooltip target=".cron-expression" event="focus" position="bottom">
      //     <QuartzCronTooltipContent/>
      //   </Tooltip>, className: 'md:col-12 m-0'
      // },
      {
        field: 'teamId', header: 'Nhóm nhân viên', required: true, type: 'Dropdown', DropdownProps: {options: teams}, className: 'md:col-8'
      },
      {field: 'priority', header: 'Ưu tiên', type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {field: 'description', header: 'Mô tả', className: 'md:col-12'},
      {
        field: 'clients', header: 'Khách hàng', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: clients, completeMethod: searchClients, field: 'label', dropdown: true, multiple: true},
        className: 'md:col-12', disabledDependency(item: any): boolean {
          return !item?.teamId;
        }
      }
    ],
    createItem: item => {
      return TaskService.checkRecurStartCron(item).catch(err => {
        ToastService.error(err);
      }).then(item => Service.create(inject(item)));
    },
    updateItem: (id, item) => {
      return TaskService.checkRecurStartCron(item).catch(err => {
        ToastService.error(err);
      }).then(item => Service.update(id, inject(item)));
    }
  });

  const inject = item => {
    item.serviceId = item.service.value;
    if (item.clients?.length) {
      item.clients = item.clients.map(client => client.value);
    }
    return item;
  }

  const {service, estimatedStartDate, recurCronOption} = form.getValue();
  useEffect(() => {
    if (service?.productName) {
      const _value = form.getRawValue();
      _value.workEffortName = service.productName;
      form.setValue(_value);
    }
  }, [service]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    let _recurStartCron = '';
    if (estimatedStartDate && recurCronOption) {
      _recurStartCron = convertToCron(recurCronOption, new Date(1 * estimatedStartDate));
    }
    const _value = form.getRawValue();
    _value.recurStartCron = _recurStartCron;
    form.setValue(_value);
  }, [estimatedStartDate, recurCronOption]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doCreate = () => {
    create({estimatedStartDate: FormatDisplay.date(new Date(Date.now()), 'YYYY-MM-DD HH:mm')});
  }

  const doUpdate = (doView?: boolean) => {
    if (selectedItem && selectedItem[dataKey]) {

      Service.get(selectedItem[dataKey]).then(data => {
        if (data.estimatedStartDate) {
          data.estimatedStartDate = FormatDisplay.date(data.estimatedStartDate, 'YYYY-MM-DD HH:mm');
        }
        data.service = {value: data.serviceId, label: data.serviceName};

        if (data.clientIds) {
          const clientIds = data.clientIds?.split(',');
          if (clientIds.length) {
            const clientNames = data.clientNames.split('@IAM@');
            const clients = [];
            clientIds.forEach((clientId, index) => {
              clients.push({value: clientId, label: clientNames[index]});
            });
            data.clients = clients;
          }
        }

        if (doView === true) {
          view(data);
        } else {
          update(data);
        }
      });
    }
  }

  const doDisable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.disable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }
  const doEnable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.enable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate,
    hasSelectedItem: selectedItem,
    doView: () => doUpdate(true),
    doUpdate,
    leftButtons: <Fragment>
      {statusId === statuses[0].value && <Button label="Khoá" icon="pi pi-lock" severity="danger" size="small" onClick={doDisable} disabled={!selectedItem}/>}
      {statusId === statuses[1].value && <Button label="Mở khoá" icon="pi pi-lock" severity="success" size="small" onClick={doEnable} disabled={!selectedItem}/>}
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 17.48rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>
  );
}