import {TabView, TabPanel} from 'primereact/tabview';

import {Departments} from './phong-ban';
import {OrgInfo} from './OrgInfo';
import {OrgLogo} from './OrgLogo';
import {ZaloNotification} from './ZaloNotification';
import {ConfigAppMenu} from './ConfigAppMenu';

export const Organization = ({me, org}) => {

  return (
    <div className="grid">
      <div className="col-12">
        <TabView>
          <TabPanel header="Cơ cấu tổ chức">
            <Departments me={me}/>
          </TabPanel>
          <TabPanel header="Công ty">
            <OrgInfo/>
          </TabPanel>
          <TabPanel header="Logo công ty">
            <OrgLogo org={org}/>
          </TabPanel>
          <TabPanel header="Thông báo Zalo">
            <ZaloNotification/>
          </TabPanel>
          <TabPanel header="Cấu hình Menu">
            <ConfigAppMenu/>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
}