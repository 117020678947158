import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {FormatDisplay, ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {IamPermission} from 'src/shared/utils/Permission';

import {TaskService as Service} from './TaskService';
import {TaskExpenseService} from '../../bao-cao/chi-phi/TaskExpenseService';
import {TaskExpenseTypeService} from '../../danh-muc/loai-chi-phi/TaskExpenseTypeService';

export const Expenses = ({me, iamElasticHasChange, display, setDisplay}) => {

  const header = 'Chi phí';

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );
  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '80rem'}} position="top" onHide={() => setDisplay(false)}>
      <Screen me={me} iamElasticHasChange={iamElasticHasChange} workEffortId={display}/>
    </Dialog>
  );
}

const Screen = ({me, iamElasticHasChange, workEffortId}) => {

  const header = 'Chi phí';
  const dataKey = 'invoiceId';

  const [expenseTypes, setExpenseTypes] = useState([]);

  useEffect(() => {
    const _event: any = {
      filters: {disabled: {value: 'N', matchMode: 'equals'}}
    };
    TaskExpenseTypeService.getList(JSON.stringify(_event)).then(({listData}) => {
      setExpenseTypes(listData.map(item => {
        return {value: item.enumId, label: item.description};
      }));
    });

    const subscription = iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('mantle.account.invoice.InvoiceItem')) {
        refreshLazyData();
        setSelectedItems(null);
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, setSelectedItems, refreshLazyData} = useDataTable({
    stateKey: 'Expenses-Table',
    dataKey,
    columns: [
      {field: 'itemTypeDescription', header: 'Loại chi phí', minWidth: 150, matchMode: 'contains'},
      {field: 'amount', header: 'Chi phí', width: 120, dataType: 'number', matchMode: 'numeric'},
      {field: 'invoiceDate', header: 'Ngày', width: 120, dataType: 'date'},
      {field: 'description', header: 'Ghi chú', minWidth: 150, matchMode: 'contains'},
      {field: 'toPartyName', header: 'Nhân viên tạo', minWidth: 150, matchMode: 'contains'},
      {field: 'statusId', header: 'Trạng thái', minWidth: 150, matchMode: 'equals', filterType: 'dropdown', filterOptions: TaskExpenseService.expenseStatuses, dataType: 'fromFilterOptions'}
    ],
    indexColumnWidth: 45,
    elastic: {
      tie_breaker_id: dataKey,
      sort: [{invoiceDate: 'desc'}],
      getPitId(): Promise<any> {
        return TaskExpenseService.getPitId();
      },
      deletePit(id: string): Promise<any> {
        return TaskExpenseService.deletePit(id);
      }
    },
    getList: lazyLoadEvent => {
      if (workEffortId) {
        return Service.getExpenses(workEffortId, lazyLoadEvent);
      } else {
        return Promise.resolve({});
      }
    }
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'itemTypeEnumId', header: 'Loại chi phí', required: true, type: 'Dropdown', DropdownProps: {options: expenseTypes, filter: true}, className: 'md:col-12'},
      {field: 'amount', header: 'Chi phí', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-12'},
      {field: 'invoiceDate', header: 'Ngày', required: true, InputTextProps: {type: 'date'}, className: 'md:col-12'},
      {field: 'description', header: 'Ghi chú', type: 'InputTextarea', className: 'md:col-12'}
    ],
    createItem: item => {
      return Service.createExpense(workEffortId, item);
    },
    updateItem: (id, item) => {
      return TaskExpenseService.updateExpense(id, item);
    }
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doCreate = () => {
    create({invoiceDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD')});
  }

  const doUpdate = () => {
    if (selectedItem && selectedItem[dataKey]) {
      const data = Object.assign({}, selectedItem);
      if (data.invoiceDate) {
        data.invoiceDate = FormatDisplay.date(data.invoiceDate, 'YYYY-MM-DD');
      }
      update(data);
    }
  }

  const updateStatus = statusId => {
    if (selectedItem && selectedItem[dataKey]) {
      TaskExpenseService.updateExpenseStatus(selectedItem[dataKey], statusId).then(() => {
        ToastService.success();
      });
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate,
    hasSelectedItem: selectedItem,
    leftButtons: <Fragment>
      <Button label="Chỉnh sửa" icon="pi pi-check" severity="warning" size="small" onClick={() => doUpdate()} disabled={['InvoiceAcked'].includes(selectedItem?.statusId) || me.partyId !== selectedItem?.toPartyId}/>
      {IamPermission.has('ALL', 'TaskExpenseReport') && <Fragment>
				<Button label="Duyệt" icon="pi pi-check" severity="success" size="small" onClick={() => updateStatus('InvoiceAcked')} disabled={!['InvoiceSent', 'InvoiceCancelled'].includes(selectedItem?.statusId)}/>
				<Button label="Không duyệt" icon="pi pi-times" severity="danger" size="small" onClick={() => updateStatus('InvoiceCancelled')} disabled={!['InvoiceSent', 'InvoiceAcked'].includes(selectedItem?.statusId)}/>
			</Fragment>}
    </Fragment>
  });

  return (
    <div className="grid mt-2">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0">
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>
  );
}