import {useEffect, useState} from 'react';

import moment from 'moment';

import {Button} from 'primereact/button';
import {FormatDisplay, ToastService, useDataTableBasic, useForm} from '@iamsoftware/react-hooks';

import {AggregatedTaskService as Service} from './AggregatedTaskService';
import {ServiceService} from '../../danh-muc/dich-vu/ServiceService';
import {ClientService} from '../../danh-muc/khach-hang/ClientService';
import {TeamService} from '../../he-thong/team-nhom/TeamService';

import {useCrupTask} from '../danh-sach/CrupTask';

export const AggregatedTasks = ({me}) => {

  const [submitting, setSubmitting] = useState(false);

  const [services, setServices] = useState([]);
  const [teams, setTeams] = useState([]);
  const [clients, setClients] = useState([]);

  const [items, setItems] = useState([]);

  useEffect(() => {
    const _event: any = {
      filters: {disabled: {value: 'N', matchMode: 'equals'}}
    };
    TeamService.getList(JSON.stringify(_event)).then(({listData}) => {
      setTeams(listData.map(item => {
        return {value: item.partyId, label: item.partyName};
      }));
      form.setValue({
        start: moment().format('YYYY-MM-DD'),
        end: moment().add(1, 'week').format('YYYY-MM-DD'),
        maxIterate: 1,
        teamId: listData[0].partyId
      });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const searchServices = event => {
    ServiceService.find(event.query).then(data => {
      setServices(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.productId, label: `[${item.pseudoId}] - ${item.productName}`};
      }));
    });
  }

  const searchClients = event => {
    ClientService.find(event.query).then(data => {
      setClients(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.partyId, label: `[${item.partyTaxId}] - ${item.partyName}`};
      }));
    });
  }

  const searchTasks = () => {
    if (!submitting && form.valid()) {
      setSubmitting(true);
      setItems([]);
      setSelectedItems(null);

      const params = form.getValue();

      params.serviceId = params.service.value;
      if (params.clients?.length) {
        params.clients = params.clients.map(client => client.value).join(',');
      }

      Service.getAggregatedTasks(params).then(({listData}) => {
        setItems(listData);
      }).finally(() => setSubmitting(false));
    }
  }

  const form = useForm({
    formGroups: [
      {className: 'col-5', start: 0, end: 4},
      {className: 'col-3', start: 4, end: 5},
      {className: 'col-4', start: 5, end: 6}
    ],
    fields: [
      {field: 'start', header: 'Bắt đầu từ ngày', required: true, InputTextProps: {type: 'date'}, className: 'md:col-4'},
      {field: 'end', header: 'Đến ngày', required: true, InputTextProps: {type: 'date'}, className: 'md:col-4'},
      {field: 'maxIterate', header: 'Giới hạn trùng lặp', required: true, type: 'InputNumber', InputNumberProps: {min: 1, max: 10}, className: 'md:col-4'},
      {
        field: 'teamId', header: 'Nhóm nhân viên', required: true, type: 'Dropdown', DropdownProps: {options: teams}, className: 'md:col-12'
      },
      {
        field: 'service', header: 'Dịch vụ', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: services, completeMethod: searchServices, field: 'label', dropdown: true, forceSelection: true},
        className: 'md:col-12'
      },
      {
        field: 'clients', header: 'Khách hàng', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: clients, completeMethod: searchClients, field: 'label', dropdown: true, multiple: true},
        className: 'md:col-12'
      }
    ]
  });

  const {render, selectedItems, setSelectedItems} = useDataTableBasic({
    dataKey: 'dataKey',
    tableHeader: 'Công việc',
    indexColumnWidth: 45,
    columns: [
      {field: 'workEffortId', header: 'Mã CV', width: 120, matchMode: 'contains'},
      {field: 'workEffortName', header: 'Công việc', minWidth: 250, matchMode: 'contains'},
      {field: 'priority', header: 'Ưu tiên', width: 100, dataType: 'number', matchMode: 'equals'},
      {field: 'recurStartCron', header: 'Chu kỳ', minWidth: 120, matchMode: 'contains'},
      {field: 'start', header: 'Ngày bắt đầu', minWidth: 140, dataType: 'date-time', matchMode: 'contains'},
      {
        field: 'clientNames', header: 'Khách hàng', minWidth: 180, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.clientNames?.split('@IAM@').map((clientName, index) => <p key={index} className="mb-0">- {clientName}</p>);
        }
      },
      {
        field: 'clientPseudoIds', header: 'MST khách hàng', minWidth: 180, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.clientPseudoIds?.split('@IAM@').map((clientPseudoId, index) => <p key={index} className="mb-0">- {clientPseudoId}</p>);
        }
      }
    ],
    items,
    selectionMode: 'checkbox'
  });

  const reloadLazyData = () => {
    setItems([]);
    setSelectedItems(null);
  }

  const {renderCrupTask, doCreateTask} = useCrupTask({me, reloadLazyData, disabledField: {service: true}});

  const doAssign = () => {
    if (selectedItems?.length) {

      const serviceName = new Set(selectedItems.map(item => item.serviceName));
      const {service, teamId} = form.getValue();

      const clients = [];
      selectedItems.forEach(selectedItem => {
        const clientIds = selectedItem.clientIds?.split(',');
        if (clientIds.length) {
          const clientPseudoIds = selectedItem.clientPseudoIds.split('@IAM@');
          const clientNames = selectedItem.clientNames.split('@IAM@');
          clientIds.forEach((clientId, index) => {
            if (clients.filter(client => client.clientId === clientId).length === 0) {
              clients.push({
                rootWorkEffortId: selectedItem.workEffortId,
                rootEstimatedStartDate: selectedItem.start,
                clientId: clientId,
                clientPseudoId: clientPseudoIds[index],
                clientName: clientNames[index]
              });
            }
          });
        }
      });

      doCreateTask({
        formValue: {
          service,
          teamId,
          workEffortName: Array.from(serviceName).join(', '),
          estimatedStartDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD HH:mm')
        },
        clients
      });
    } else {
      ToastService.error('Chưa chọn công việc nào');
    }
  }

  return (
    <div className="grid">
      <div className="col-12">
        {form.render()}
      </div>
      <div className="col-12 pt-0 flex justify-content-between">
        <Button icon="pi pi-search" label="Tổng hợp dữ liệu" severity="success" size="small" onClick={searchTasks} loading={submitting}/>
        <Button icon="pi pi-user-edit" label="Phân công" severity="info" onClick={doAssign} loading={submitting}/>
      </div>
      <div className="col-12">
        {render()}
      </div>

      {renderCrupTask()}
    </div>
  );
}