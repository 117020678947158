import {useState} from 'react';

import {ProfileService} from '../../app/tai-khoan/ProfileService';

interface Props {
  partyId: string
  last?: string
}

export const Avatar = (props: Props) => {

  const [replace, setReplace] = useState(0);

  const onError = event => {
    if (replace < 5) {
      setReplace(prevState => prevState + 1);
      event.target.src = '/images/blank-avatar-profile.png';
    }
    return true;
  }
  return <img src={`${ProfileService.baseURL}/${ProfileService.entity}/avatar/${props.partyId}?last=${props.last}`} onError={onError} alt="Avatar"/>
}