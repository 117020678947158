import {EntityService} from 'src/service/EntityService';

export class EventService extends EntityService {

  static entity = 'iam-work-effort/reminders';

  static getFullCalendarData(start: string, end: string): Promise<any> {
    return this.axios.get(`${this.entity}/full-calendar-data`, {params: {start, end}}).then(res => res.data);
  }

  static updateStatus(workEffortId: string, statusId: string): Promise<any> {
    return this.axios.patch(`${this.entity}/${workEffortId}/update-status`, {statusId}).then(res => res.data);
  }

}
