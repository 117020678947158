import {Fragment, useEffect, useState} from 'react';

import {Tooltip} from 'primereact/tooltip';

import {FormatDisplay, Period, useDataTableBasic} from '@iamsoftware/react-hooks';

import {UserService} from '../../he-thong/nhan-vien/UserService';

export const EmploymentLeaveAggregation = ({iamElasticHasChange}) => {

  const header = 'Quản lý nghỉ phép';

  const [period, setPeriod] = useState(null);

  const [items, setItems] = useState([]);
  const [daysSet, setDaysSet] = useState([]);

  useEffect(() => {
    const subscription = iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('workEfforts') && data?.message?.workEffortTypeEnumIdSet?.includes('WetTask')) {
        load();
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (period) {
      load();
    }
  }, [period]); // eslint-disable-line react-hooks/exhaustive-deps

  const load = () => {
    if (period?.length === 2) {
      UserService.getEmploymentLeaveAggregation(period[0], period[1]).then(data => {
        setItems(data.listData);
      });
    }
  }

  const onMouseEnter = rowData => {
    setDaysSet(rowData.daysSet);
  }

  const {render} = useDataTableBasic({
    tableHeader: header,
    indexColumnWidth: 45,
    columns: [
      {field: 'pseudoId', header: 'Mã nhân viên', width: 250, matchMode: 'contains'},
      {field: 'partyName', header: 'Tên nhân viên', minWidth: 150, matchMode: 'contains'},
      {
        field: 'leaveCount', header: 'Tổng số ngày nghỉ', width: 170, matchMode: 'equals', dataType: 'custom', customCell(rowData: any): any {
          return <span className="daysSet-tooltip" onMouseEnter={() => onMouseEnter(rowData)}>{FormatDisplay.number(rowData.leaveCount)}</span>
        }, className: 'text-right'
      }
    ],
    items
  });

  return (
   <Fragment>
     <div className="p-fluid formgrid grid mb-2">
       <div className="col-2">
         <Period defaultPeriod="THISFISCALYEAR" value={period} onChange={({value}) => setPeriod(value)}/>
       </div>
     </div>
     <div className="grid">
       <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12rem)'}}>
         {render()}
       </div>
       <Tooltip target=".daysSet-tooltip" position="left">
         {daysSet?.map((day, index) => <span className="mr-2" key={index}>{day}</span>)}
       </Tooltip>
     </div>
   </Fragment>
  );
}