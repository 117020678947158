import {sampleTable} from 'src/shared/components/Tiny-Editor';

type ConfigType = 'col' | 'value';

export const setupEditor = (editor, additionalInfos, serviceFeatures) => {

  editor.ui.registry.addMenuItem('iamMenuItem_add_SampleTable', {
    icon: 'checklist',
    text: 'Thêm bảng mẫu',
    onAction: () => {
      const div = document.createElement('div');
      div.innerHTML = sampleTable.trim();
      editor.selection.getNode().closest('body').append(div.firstChild);
    }
  });

  const makeDialogConfig: any = (configType: ConfigType) => {

    const items = [];
    if (configType === 'col') {
      items.push({field: 'index', text: 'Số thứ tự'});
    }
    items.push(...[
      {field: 'clientTaxId', text: 'Khách hàng - Mã số thuế'},
      {field: 'clientName', text: 'Khách hàng - Tên khách hàng'},
      {field: 'clientAddress', text: 'Khách hàng - Địa chỉ'},
      {field: 'clientPhoneNumber', text: 'Khách hàng - Số điện thoại'},
      {field: 'clientEmailAddress', text: 'Khách hàng - Email'}
    ]);
    additionalInfos.forEach(additionalInfo => {
      items.push({field: `clientAdditionalInfo@IAM@${additionalInfo.enumId}`, text: `Khách hàng - ${additionalInfo.description}`});
    });
    serviceFeatures
      ?.filter(serviceFeature => {
        const _includes = ['WeTaskProgression'].includes(serviceFeature.productFeatureId);
        return configType === 'value' ? !_includes : _includes;
      })
      ?.forEach(serviceFeature => {
        items.push({field: `serviceFeatureId@IAM@${serviceFeature.productFeatureId}`, text: `Trường yêu cầu - ${serviceFeature.applAsValue}`});
      });

    items.forEach(item => {
      item.value = JSON.stringify(item);
    });

    return {
      title: configType === 'col' ? 'Gán loại cột' : 'Gán giá trị',
      body: {
        type: 'panel',
        items: [
          {
            type: 'listbox',
            name: 'field',
            label: 'Gán từ thông tin',
            items
          }
        ]
      },
      buttons: [
        {
          type: 'submit',
          name: 'submitButton',
          text: 'Chọn',
          buttonType: 'primary'
        },
        {
          type: 'cancel',
          name: 'closeButton',
          text: 'Đóng'
        }
      ],
      onSubmit: api => {
        const data = api.getData();

        if (data.field) {
          const field = JSON.parse(data.field);

          switch (configType) {
            case 'col':
              const th = editor.selection.getNode();
              th.setAttribute('title', field.text);
              th.setAttribute('data-iam-th-as', field.field);
              if (field.field === 'index') {
                th.setAttribute('width', '50');
                th.textContent = 'STT';
              } else {
                th.textContent = field.text;
              }
              break;
            case 'value':
              const ref = document.createElement('mark');
              ref.setAttribute('title', field.text);
              ref.textContent = 'VALUE';
              ref.setAttribute('data-iam-ref-as', field.field);
              editor.selection.getNode().append(ref);
              break;
          }
        }

        api.close();
      }
    }
  }

  editor.ui.registry.addMenuItem('iamMenuItem_set_col', {
    icon: 'checklist',
    text: 'Gán loại cột',
    onAction: () => {
      editor.windowManager.open(makeDialogConfig('col'));
    }
  });

  editor.ui.registry.addMenuItem('iamMenuItem_set_value', {
    icon: 'paste-text',
    text: 'Gán giá trị',
    onAction: () => {
      editor.windowManager.open(makeDialogConfig('value'));
    }
  });

  editor.ui.registry.addContextMenu('iamContextMenu', {
    update: element => {
      let menu = 'iamMenuItem_set_value';
      switch (element.tagName) {
        case 'TH':
          menu += ' iamMenuItem_set_col';
          break;
        case 'HTML':
        case 'BODY':
          menu += ' iamMenuItem_add_SampleTable';
          break;
        default:
      }
      return menu;
    }
  });

}