import {useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {FormatDisplay, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {IamPermission} from 'src/shared/utils/Permission';

import {TeamService as Service} from './TeamService';
import {DepartmentService} from '../to-chuc/phong-ban/DepartmentService';

export const Departments = ({display, setDisplay}) => {

  const header = 'Phòng ban';

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );
  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '70rem'}} position="top" onHide={() => setDisplay(false)}>
      <Screen teamId={display}/>
    </Dialog>
  );
}

const Screen = ({teamId}) => {

  const header = 'Phòng ban';
  const dataKey = 'partyRelationshipId';

  const [departments, setDepartments] = useState([]);

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    stateKey: 'Departments-Table',
    dataKey,
    columns: [
      {field: 'partyName', header: 'Tên phòng ban', minWidth: 250, matchMode: 'contains'},
      {field: 'fromDate', header: 'Từ ngày', width: 170, dataType: 'date-time'},
      {field: 'thruDate', header: 'Đến ngày', width: 170, dataType: 'date-time'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      if (teamId) {
        return Service.getDepartments(teamId, lazyLoadEvent);
      } else {
        return Promise.resolve({});
      }
    }
  });

  const searchDepartments = event => {
    DepartmentService.find(event.query).then(data => {
      setDepartments(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.partyId, label: `[${item.pseudoId}] - ${item.partyName}`};
      }));
    });
  }

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {
        field: 'toParty', header: 'Tên phòng ban', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: departments, completeMethod: searchDepartments, field: 'label', dropdown: true, forceSelection: true},
        disabled: 'Update', className: 'md:col-12'
      },
      {field: 'fromDate', header: 'Từ ngày', required: true, InputTextProps: {type: 'date'}, className: 'md:col-12'},
      {field: 'thruDate', header: 'Đến ngày', InputTextProps: {type: 'date'}, className: 'md:col-12'}
    ],
    createItem: item => {
      item.toPartyId = item.toParty.value;
      return Service.createDepartment(teamId, item);
    },
    updateItem: (id, item) => {
      item.toPartyId = item.toParty.value;
      return Service.updateDepartment(teamId, id, item);
    },
    reloadLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doCreate = () => {
    create({fromDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD')});
  }

  const doUpdate = () => {
    if (selectedItem && selectedItem[dataKey]) {
      const data = Object.assign({}, selectedItem);
      data.toParty = {
        value: data.toPartyId,
        label: data.partyName
      };
      if (data.fromDate) {
        data.fromDate = FormatDisplay.date(data.fromDate, 'YYYY-MM-DD');
      }
      if (data.thruDate) {
        data.thruDate = FormatDisplay.date(data.thruDate, 'YYYY-MM-DD');
      }
      update(data);
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate,
    hasSelectedItem: selectedItem,
    doUpdate
  });

  return (
    <div className="grid mt-2">
      {IamPermission.has('UPDATE') && <div className="col-12">
        {renderToolbar()}
			</div>}
      <div className="col-12 py-0">
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>
  );
}
