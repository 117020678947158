import {EntityService} from 'src/service/EntityService';

export class UserService extends EntityService {

  static entity = 'iam-system/users';

  static find(term: string, teamId?: string): Promise<any> {
    return this.axios.get(`${this.entity}/find`, {params: {term, teamId}}).then(res => res.data);
  }

  static resetPassword(userId: string, resetPassword: string): Promise<any> {
    return this.axios.put(`${this.entity}/${userId}/reset-password`, {resetPassword}).then(res => res.data);
  }

  static getPermissions(userId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${userId}/permissions`).then(res => res.data);
  }

  static setPermissions(userId: string, permissions: Array<any>): Promise<any> {
    return this.axios.put(`${this.entity}/${userId}/permissions`, {permissions}).then(res => res.data);
  }

  static getIdentityDocuments(userId: string, lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/${userId}/identity-documents/list?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static getIdentityDocument(userId: string, partyContentId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${userId}/identity-documents/${partyContentId}/detail`).then(res => res.data);
  }

  static createIdentityDocument(userId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${userId}/identity-documents/create`, data).then(res => res.data);
  }

  static updateIdentityDocument(userId: string, partyContentId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${userId}/identity-documents/${partyContentId}/update`, data).then(res => res.data);
  }

  static disableIdentityDocument(userId: string, partyContentId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${userId}/identity-documents/${partyContentId}/disable`).then(res => res.data);
  }

  static enableIdentityDocument(userId: string, partyContentId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${userId}/identity-documents/${partyContentId}/enable`).then(res => res.data);
  }

  static getEmploymentLeaveAggregation(start: string, end: string): Promise<any> {
    return this.axios.get(`${this.entity}/employment-leave-aggregation`, {params: {start, end}}).then(res => res.data);
  }

}
