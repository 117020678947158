import {Fragment, useEffect, useState} from 'react';

import moment from 'moment';

import {Button} from 'primereact/button';

import {FormatDisplay, ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {IamPermission} from 'src/shared/utils/Permission';

import {EventService as Service} from '../EventService';
import {TaskService} from '../../cong-viec/danh-sach/TaskService';

import {XlsxService} from 'src/service/XlsxService';
import {useCrupEvent} from '../CrupEvent';

const purposeEnumId = 'WepOvertime';

export const Overtime = ({me, iamElasticHasChange}) => {

  const header = 'Làm thêm giờ';
  const dataKey = 'workEffortId';

  const [requiredParams] = useState({purposeEnumId: {value: purposeEnumId, matchMode: 'equals'}});

  const [currentLoadEvent, setCurrentLoadEvent] = useState(null);

  useEffect(() => {
    const subscription = iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('workEfforts') && data?.message?.workEffortTypeEnumIdSet?.includes('WetEvent')) {
        refreshLazyData();
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns: Array<any> = [
    {
      field: 'employee', filterField: 'parties.partyPseudoId', header: 'Nhân viên', minWidth: 180, sortable: false, matchMode: 'contains', dataType: 'custom',
      customCell(rowData: any, skipFormat: boolean): any {
        if (skipFormat === true) {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'Assignee')
            ?.map(party => `[${party.partyPseudoId}] ${party.partyName}`)?.join(', ');
        }
        return rowData.parties
          ?.filter(party => party.partyRoleTypeId === 'Assignee')
          ?.map((party, index) => <p key={index} className="mb-0">[{party.partyPseudoId}] {party.partyName}</p>);
      }
    },
    {field: 'estimatedStartDate', header: 'Từ giờ', width: 150, dataType: 'date-time'},
    {field: 'estimatedCompletionDate', header: 'Đến giờ', width: 150, dataType: 'date-time'},
    {field: 'estimatedWorkDuration', header: 'Số giờ', width: 100, dataType: 'number'},
    {
      field: 'assocToName', filterField: 'assocs.assocToName', header: 'Công việc', minWidth: 180, sortable: false, matchMode: 'contains', dataType: 'custom',
      customCell(rowData: any, skipFormat: boolean): any {
        if (skipFormat === true) {
          return rowData.assocs
            ?.filter(assoc => assoc.assocTypeEnumId === 'WeatRelatesTo')
            ?.map(assoc => assoc.assocToName)?.join(', ');
        }
        return rowData.assocs
          ?.filter(assoc => assoc.assocTypeEnumId === 'WeatRelatesTo')
          ?.map((assoc, index) => <p key={index} className="mb-0">- {assoc.assocToName}</p>);
      }
    },
    {
      field: 'customerName', filterField: 'parties.partyName', header: 'Khách hàng', minWidth: 180, sortable: false, matchMode: 'contains', dataType: 'custom',
      customCell(rowData: any, skipFormat: boolean): any {
        if (skipFormat === true) {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'Customer')
            ?.map(party => party.partyName)?.join(', ');
        }
        return rowData.parties
          ?.filter(party => party.partyRoleTypeId === 'Customer')
          ?.map((party, index) => <p key={index} className="mb-0">- {party.partyName}</p>);
      }
    },
    {field: 'description', header: 'Ghi chú', minWidth: 150, sortable: false, matchMode: 'contains'},
    {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'contains'}
  ];

  const {render: renderDataTable, selectedItems, refreshLazyData, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: header,
    dataKey,
    columns,
    indexColumnWidth: 45,
    elastic: {
      tie_breaker_id: dataKey,
      sort: [
        {lastUpdatedStamp: 'desc'}
      ],
      getPitId(): Promise<any> {
        return TaskService.getPitId();
      },
      deletePit(id: string): Promise<any> {
        return TaskService.deletePit(id);
      }
    },
    getList: lazyLoadEvent => {
      setCurrentLoadEvent(lazyLoadEvent);
      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  const {renderDialogCrupEvent, doCreateEvent, doUpdateEvent, formEvent} = useCrupEvent({me, header});

  const {estimatedStartDate, estimatedCompletionDate} = formEvent.getRawValue();
  useEffect(() => {
    if (estimatedStartDate && estimatedCompletionDate && (formEvent.dirty['estimatedStartDate'] || formEvent.dirty['estimatedCompletionDate'])) {
      const duration = moment.duration(moment(estimatedCompletionDate).diff(moment(estimatedStartDate))).asHours();
      if (duration > 0) {
        const _value = formEvent.getRawValue();
        _value.estimatedWorkDuration = duration;
        formEvent.setValue(_value);
      }
    }
  }, [estimatedStartDate, estimatedCompletionDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doCreate = () => {
    doCreateEvent({
      estimatedCompletionDate: FormatDisplay.date(moment().add(2, 'hours').toDate(), 'YYYY-MM-DD HH:mm'),
      estimatedWorkDuration: 2,
      purposeEnumId, statusId: 'WeOnHold', employees: {value: me.partyId, label: me.partyName}
    });
  }

  const doUpdate = (doView?: boolean) => {
    if (selectedItem && selectedItem[dataKey]) {
      doUpdateEvent(selectedItem, doView);
    }
  }

  const updateStatus = statusId => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.updateStatus(selectedItem[dataKey], statusId).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const showDownloadExcel = () => {
    downloadExcel({
      fromDate: FormatDisplay.date(moment().startOf('month').toDate(), 'YYYY-MM-DD'),
      toDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD')
    });
  }

  const {render: renderDialogDownloadExcel, create: downloadExcel} = useDialogCrup({
    header: '!Xuất Excel',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'fromDate', header: 'Từ ngày', required: true, InputTextProps: {type: 'date'}, className: 'md:col-12'},
      {field: 'toDate', header: 'Đến ngày', required: true, InputTextProps: {type: 'date'}, className: 'md:col-12'}
    ],
    createItem: item => {
      const _event = JSON.parse(currentLoadEvent);
      _event.page = 0;
      _event.size = 10000;
      _event.query.bool.filter.bool.must.push(
        {
          range: {
            estimatedStartDate: {gte: moment(1 * item.fromDate).startOf('day').valueOf()}
          }
        },
        {
          range: {
            estimatedCompletionDate: {lte: moment(1 * item.toDate).endOf('day').valueOf()}
          }
        }
      );
      Service.getList(JSON.stringify(_event)).then(({listData}) => {
        if (listData?.length) {
          const aoa = [[]];
          columns.forEach(column => {
            aoa[0].push(column.header);
            listData.map(item => item._source).forEach((item, index) => {
              if (!aoa[index + 1]) {
                aoa[index + 1] = [];
              }
              aoa[index + 1].push(XlsxService.getCellValue(column, item));
            });
          });

          XlsxService.generateFile(aoa, 'lam-them-gio');
        } else {
          ToastService.error('Không tìm thấy dữ liệu');
        }
      });
      return Promise.resolve();
    },
    useSaveSplitButton: false,
    submitLabel: 'Tải Excel',
    hideToastMessage: true
  });

  const {renderToolbar} = useToolbar({
    doCreate,
    hasSelectedItem: selectedItem,
    doView: () => doUpdate(true),
    leftButtons: <Fragment>
      <Button label="Chỉnh sửa" icon="pi pi-check" severity="warning" size="small" onClick={() => doUpdate(selectedItem)} disabled={!['WeOnHold'].includes(selectedItem?.statusId) || me.partyId !== selectedItem?.createdByPartyId}/>
      {IamPermission.has('ALL', 'ReminderManagement') && <Fragment>
				<Button label="Duyệt" icon="pi pi-check" severity="success" size="small" onClick={() => updateStatus('WeApproved')} disabled={!['WeOnHold', 'WeCancelled'].includes(selectedItem?.statusId)}/>
				<Button label="Không duyệt" icon="pi pi-times" severity="danger" size="small" onClick={() => updateStatus('WeCancelled')} disabled={!['WeOnHold', 'WeApproved'].includes(selectedItem?.statusId)}/>
			</Fragment>}
    </Fragment>,
    rightButtons: <Fragment>
      <Button label="Xuất Excel" icon="pi pi-file-excel" severity="info" size="small" onClick={showDownloadExcel}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 13.7rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrupEvent()}
      {renderDialogDownloadExcel()}
    </div>
  );
}