import {TabView, TabPanel} from 'primereact/tabview';

import {ByEmployee} from './ByEmployee';
import {ByTeam} from './ByTeam';

export const ProgressReport = ({me, iamElasticHasChange}) => {

  return (
    <TabView className="p-tabview-transparent">
      <TabPanel header="Theo nhân viên">
        <ByEmployee me={me} iamElasticHasChange={iamElasticHasChange}/>
      </TabPanel>
      <TabPanel header="Theo nhóm">
        <ByTeam iamElasticHasChange={iamElasticHasChange}/>
      </TabPanel>
    </TabView>

  );
}