import {Route, Routes} from 'react-router-dom';

import {Tasks} from './danh-sach';
import {Dashboard} from './dashboard';
import {TaskHeadings} from './dau-muc';
import {AggregatedTasks} from './phan-cong';
import {Progress} from './theo-doi-tien-do';

export default function Task({me, iamElasticHasChange, taMaTeamActivity}) {

  return (
    <Routes>
      <Route path="danh-sach" element={<Tasks me={me} iamElasticHasChange={iamElasticHasChange}/>}/>
      <Route path="dashboard" element={<Dashboard iamElasticHasChange={iamElasticHasChange} taMaTeamActivity={taMaTeamActivity}/>}/>
      <Route path="dau-muc" element={<TaskHeadings iamElasticHasChange={iamElasticHasChange}/>}/>
      <Route path="phan-cong" element={<AggregatedTasks me={me}/>}/>
      <Route path="theo-doi-tien-do" element={<Progress me={me} iamElasticHasChange={iamElasticHasChange}/>}/>
    </Routes>
  );
}
