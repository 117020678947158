import {useEffect, useState} from 'react';
import {NavLink, Route, Routes, useLocation} from 'react-router-dom';

import {TabMenu} from 'primereact/tabmenu';

import {MyAccount} from './MyAccount';
import {ConfigMenus} from './ConfigMenu';
import {IdentityDocuments} from './IdentityDocuments';
import {ZaloNotification} from './zalo-notification';

export const Profile = ({me}) => {

  const location = useLocation();

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (location?.pathname) {
      items.forEach((item, index) => {
        if (location?.pathname === item.target) {
          setActiveIndex(index);
        }
      });
    }
  }, [location?.pathname]);

  return (
    <div className="card p-0">
      <TabMenu model={items} activeIndex={activeIndex} onTabChange={e => setActiveIndex(e.index)}/>
      <div className="grid">
        <div className="col-12 p-5">
          <Routes>
            <Route path="" element={<MyAccount me={me}/>}/>
            <Route path="cau-hinh-menu" element={<ConfigMenus/>}/>
            <Route path="ho-so" element={<IdentityDocuments/>}/>
            <Route path="thong-bao-zalo" element={<ZaloNotification me={me}/>}/>
          </Routes>
        </div>
      </div>
    </div>
  );
}

const template = (item: any, options: any) => {
  return (
    <NavLink to={item.target} className={options.className}>
      <span className={options.iconClassName}/>
      <span className={options.labelClassName}>{item.label}</span>
    </NavLink>
  );
};

const items = [
  {label: 'Tài khoản của tôi', icon: 'pi pi-fw pi-user', target: '/tai-khoan', template},
  {label: 'Cấu hình Menu', icon: 'pi pi-fw pi-palette', target: '/tai-khoan/cau-hinh-menu', template},
  {label: 'Hồ sơ nhân sự', icon: 'pi pi-fw pi-book', target: '/tai-khoan/ho-so', template},
  {label: 'Thông báo Zalo', icon: 'pi pi-fw pi-bell', target: '/tai-khoan/thong-bao-zalo', template}
];