import {Service} from 'src/service/Service';

export class ProfileService extends Service {

  static entity = 'iam-profile';

  static get(): Promise<any> {
    return this.axios.get(`${this.entity}`).then(res => res.data);
  }

  static set(data: any): Promise<any> {
    return this.axios.post(`${this.entity}`, data).then(res => res.data);
  }

  static getIdentityDocuments(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/identity-documents/list?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static getIdentityDocument(partyContentId: string): Promise<any> {
    return this.axios.get(`${this.entity}/identity-documents/${partyContentId}/detail`).then(res => res.data);
  }

  static createIdentityDocument(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/identity-documents/create`, data).then(res => res.data);
  }

  static updateIdentityDocument(partyContentId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/identity-documents/${partyContentId}/update`, data).then(res => res.data);
  }

  static disableIdentityDocument(partyContentId: string): Promise<any> {
    return this.axios.put(`${this.entity}/identity-documents/${partyContentId}/disable`).then(res => res.data);
  }

  static enableIdentityDocument(partyContentId: string): Promise<any> {
    return this.axios.put(`${this.entity}/identity-documents/${partyContentId}/enable`).then(res => res.data);
  }

}
