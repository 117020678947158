import {useRef, useState} from 'react';

import {Button} from 'primereact/button';

import {OrganizationService as Service} from './OrganizationService';

import {Logo} from 'src/shared/components/Logo';
import {ToastService} from '@iamsoftware/react-hooks';

export const OrgLogo = ({org}) => {

  const inputImg = useRef(null);

  const [submitting, setSubmitting] = useState(false);

  const [avatar, setAvatar] = useState(null);
  const [avatarSrc, setAvatarSrc] = useState(null);

  const onUploadImage = e => {
    if (e.target.files && e.target.files.length === 1) {
      const file = e.target.files[0];
      setAvatar(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarSrc(reader.result);
      }
      reader.readAsDataURL(file);
    }
  }

  const doSubmit = () => {
    if (!submitting && avatar) {
      setSubmitting(true);

      Service.setLogo(avatar).then(() => {
        window.location.reload();
      }).finally(() => setSubmitting(false));
    } else {
      ToastService.error('Chưa chọn ảnh.');
    }
  }

  return (
    <div className="grid">
      <div className="col-6">
        <div className="card">

          <div className="flex gap-5 flex-column-reverse md:flex-row">
            <div className="flex flex-column align-items-center flex-or">
              <div className="h-5rem w-14rem" style={{border: '1px solid #ccc'}}>
                {!avatarSrc && <Logo defaultSrc="https://placehold.co/190x35" last={org?.lastUpdatedStamp}/>}
                {avatarSrc && <img src={avatarSrc} alt="Avatar" className="iam-logo"/>}
              </div>
              <input ref={inputImg} type="file" accept="image/*" hidden onChange={onUploadImage}/>
              <button className="p-button p-component p-button-rounded -mt-4 p-button-icon-only" onClick={() => inputImg.current.click()}>
                <span className="p-button-icon p-c pi pi-upload"></span>
                <span className="p-button-label p-c">&nbsp;</span><span role="presentation" className="p-ink"></span>
              </button>
            </div>
          </div>

          <div className="grid">
            <div className="col-12 flex gap-2 justify-content-center">
              <Button label="Cập nhật" icon="pi pi-check" size="small" onClick={doSubmit} loading={submitting}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}