import {Fragment, useEffect, useRef, useState} from 'react';

import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {ToastService, useDataTable, useDialogCrup, useToolbar, validateTaxCode} from '@iamsoftware/react-hooks';

import {IamPermission} from 'src/shared/utils/Permission';

import {ClientService as Service} from './ClientService';
import {TeamService} from '../../he-thong/team-nhom/TeamService';
import {ClientAdditionalInfoService} from '../thong-tin-khach-hang/ClientAdditionalInfoService';
import {PartyService} from 'src/service/PartyService';
import {CommonService} from 'src/service/CommonService';

import {ImportExcel} from 'src/shared/components/ImportExcel';

import {TinyEditor} from 'src/shared/components/Tiny-Editor';
import {ClientZaloFollowStatus} from './ClientZaloFollowStatus';

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Ngừng hoạt động'}
];

export const Clients = () => {

  const header = 'Khách hàng';
  const dataKey = 'partyId';

  const editorRef = useRef(null);

  const [statusId, setStatusId] = useState(statuses[0].value);

  const [requiredParams, setRequiredParams] = useState(null);

  const [provinces, setProvinces] = useState([]);
  const [counties, setCounties] = useState([]);
  const [teams, setTeams] = useState([]);

  const [additionalInfos, setAdditionalInfos] = useState([]);

  const [editorInitialValue, setEditorInitialValue] = useState('');
  const [editorReadonly, setEditorReadonly] = useState(false);
  const [editorValue, setEditorValue] = useState(null);

  const [displayZaloFollowStatus, setDisplayZaloFollowStatus] = useState(null);

  useEffect(() => {
    CommonService.getProvinces().then(data => {
      setProvinces(data.listData.map(item => {
        return {value: item.toGeoId, label: item.geoName}
      }));
    });

    const _event: any = {
      filters: {disabled: {value: 'N', matchMode: 'equals'}}
    };
    TeamService.getList(JSON.stringify(_event)).then(({listData}) => {
      setTeams(listData.map(item => {
        return {value: item.partyId, label: item.partyName};
      }));
    });

    ClientAdditionalInfoService.getList(JSON.stringify(_event)).then(({listData}) => {
      setAdditionalInfos(listData);
      let rows = '';
      listData.forEach(info => {
        rows += makeAdditionalInfoRow(info);
      });
      setEditorInitialValue(`
          <table style="border-collapse: collapse; width: 100%;" border="1px" data-iam="main-table">
            <thead>
              <tr>
                <th contenteditable="false">Thông tin</th>
                <th contenteditable="false">Nội dung</th>
              </tr>
            </thead>
            <tbody>
              ${rows}
            </tbody>
          </table>
      `);
    });
  }, []);

  useEffect(() => {
    if (statusId) {
      setRequiredParams({disabled: {value: statusId, matchMode: 'equals'}});
    } else {
      setRequiredParams({});
    }
  }, [statusId]); // eslint-disable-line react-hooks/exhaustive-deps

  const makeAdditionalInfoRow = info => {
    return `
        <tr>
          <td contenteditable="false">${info.description}</td>
          <td data-iam-query="Y" data-iam-enumId="${info.enumId}" contenteditable="true" style="width: 50%"></td>
        </tr>
    `;
  }

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: header,
    dataKey,
    columns: [
      {field: 'partyTaxId', header: 'Mã số thuế', width: 140, matchMode: 'contains'},
      {field: 'partyName', header: 'Tên khách hàng', minWidth: 250, matchMode: 'contains'},
      {field: 'emailAddress', header: 'Email', width: 170, matchMode: 'contains'},
      {field: 'phoneNumber', header: 'Số điện thoại', width: 140, matchMode: 'contains'},
      {field: 'address1', header: 'Địa chỉ', minWidth: 170, matchMode: 'contains'},
      {field: 'teamNames', header: 'Nhóm', width: 170, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  const getByTaxCode = () => {
    const _value = form.getValue();
    if (validateTaxCode(_value.partyTaxId)) {
      PartyService.getByTaxCode(_value.partyTaxId).then(({data}) => {
        if (data?.partyName) {
          _value.partyId = '';
          _value.partyName = data.partyName;
          _value.address1 = data.address1;
          _value.stateProvinceGeoId = data.stateProvinceGeoId;
          _value.countyGeoId = data.countyGeoId;
          form.setValue(_value);
        }
      });
    } else {
      ToastService.error('Mã số thuế không hợp lệ');
    }
  }

  const {render: renderDialogCrup, create, view, update, form} = useDialogCrup({
    header,
    dataKey,
    width: '60rem',
    fields: [
      {field: 'partyId', type: 'hidden'},
      {
        field: 'partyTaxId', header: 'Mã số thuế', required: true, className: 'md:col-4'
      },
      {
        field: 'getByTaxCode', type: 'custom', body: <Button icon="pi pi-search" rounded severity="success" onClick={getByTaxCode}/>, displayDependency(item: any): boolean {
          return !item[dataKey];
        }, className: 'md:col-1 pl-0 pt-4'
      },
      {field: 'partyName', header: 'Tên khách hàng', required: true, className: 'md:col-7'},
      {field: 'address1', header: 'Địa chỉ', required: true, InputTextProps: {maxLength: 500}, className: 'md:col-12'},
      {field: 'stateProvinceGeoId', header: 'Tỉnh/Thành phố', required: true, type: 'Dropdown', DropdownProps: {options: provinces, filter: true}, className: 'md:col-6'},
      {field: 'countyGeoId', header: 'Quận/Huyện', required: true, type: 'Dropdown', DropdownProps: {options: counties, filter: true}, className: 'md:col-6'},
      {field: 'phoneNumber', header: 'Số điện thoại', InputTextProps: {maxLength: 17, keyfilter: 'int'}, className: 'md:col-6'},
      {field: 'emailAddress', header: 'Email', InputTextProps: {maxLength: 50}, className: 'md:col-6'},
      {
        field: 'teams', header: 'Nhóm nhân viên', required: true, type: 'MultiSelect', MultiSelectProps: {options: teams, display: 'chip'}, className: 'md:col-12'
      },
      {type: 'custom', className: 'md:col-12 mt-2', body: <b>Thông tin khác</b>},
      {type: 'custom', className: 'md:col-12', body: <TinyEditor ref={editorRef} mode="table" height="400px" readonly={editorReadonly} initialValue={editorValue}/>}
    ],
    createItem: async item => {
      return Service.create(await inject(item));
    },
    updateItem: async (id, item) => {
      return Service.update(id, await inject(item));
    },
    reloadLazyData
  });

  const inject = async item => {
    item.additionalInfos = [];
    item.additionalInfoRaw = await editorRef.current.getContent();
    if (item.additionalInfoRaw) {
      const div = document.createElement('div');
      div.innerHTML = item.additionalInfoRaw.trim();
      for (const td of div.querySelectorAll('td[data-iam-query="Y"]') as any) {
        if (td.textContent && td.textContent.trim() && td.getAttribute('data-iam-enumId')) {
          item.additionalInfos.push({
            partyContentTypeEnumId: td.getAttribute('data-iam-enumId'),
            description: td.textContent.trim()
          });
        }
      }
    }
    return item;
  }

  const {stateProvinceGeoId} = form.getValue();
  useEffect(() => {
    if (stateProvinceGeoId) {
      CommonService.getCounties(stateProvinceGeoId).then(data => {
        setCounties(data.listData.map(item => {
          return {value: item.toGeoId, label: item.geoName}
        }));
      });
    }
  }, [stateProvinceGeoId]);

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doCreate = () => {
    setEditorValue(editorInitialValue);
    setEditorReadonly(false);
    create();
  }

  const doUpdate = (doView?: boolean) => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.get(selectedItem[dataKey]).then(data => {

        if (data.teamIds) {
          data.teams = data.teamIds?.split(',');
        }

        if (data.additionalInfoRaw) {
          const unavailableInfos = [];
          additionalInfos.forEach(info => {
            if (!data.additionalInfoRaw.includes(`data-iam-enumid="${info.enumId}"`)) {
              unavailableInfos.push(info);
            }
          });
          if (unavailableInfos.length) {
            const div = document.createElement('div');
            div.innerHTML = data.additionalInfoRaw.trim();
            unavailableInfos.forEach(info => {
              const table = document.createElement('table');
              table.innerHTML = makeAdditionalInfoRow(info);
              if (div.querySelector('table[data-iam="main-table"] tbody')) {
                div.querySelector('table[data-iam="main-table"] tbody').append(table.querySelector('tbody').firstChild);
              } else {
                div.querySelector('table[data-iam="main-table"]')?.append(table.querySelector('tbody'));
              }
            });
            data.additionalInfoRaw = div.innerHTML;
          }
        }
        setEditorValue(data.additionalInfoRaw || editorInitialValue);

        if (doView === true) {
          setEditorReadonly(true);
          view(data);
        } else {
          setEditorReadonly(false);
          update(data);
        }
      });
    }
  }

  const doDisable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.disable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }
  const doEnable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.enable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const formatItem = async (row, fileHeaders) => {
    let stateProvinceGeoId;
    if (row[3]) {
      const stateProvinceGeo = await CommonService.getGeoByName({toGeoName: row[3], toGeoTypeEnumId: 'GEOT_PROVINCE'});
      if (stateProvinceGeo?.geoId) {
        stateProvinceGeoId = stateProvinceGeo.geoId;
      }
    }

    let countyGeoId;
    if (row[4]) {
      const countyGeo = await CommonService.getGeoByName({toGeoName: row[4], toGeoTypeEnumId: 'GEOT_COUNTY', geoId: stateProvinceGeoId || ''});
      if (countyGeo?.geoId) {
        countyGeoId = countyGeo.geoId;
      }
    }

    const _teams = [];
    if (row[7]) {
      row[7].split(',').forEach(_team => {
        teams?.forEach(team => {
          if (_team?.trim() === team.label) {
            _teams.push(team.value);
          }
        });
      });
    }

    const _additionalInfos = [];

    const _additionalInfoRaw = document.createElement('div');
    _additionalInfoRaw.innerHTML = editorInitialValue?.trim();

    for (let i = 8; i < row.length; i++) {
      if (fileHeaders[i] && row[i]) {
        for (const additionalInfo of additionalInfos) {
          if (additionalInfo.description === fileHeaders[i]) {
            _additionalInfos.push({
              partyContentTypeEnumId: additionalInfo.enumId,
              description: row[i]
            });

            _additionalInfoRaw.querySelector(`[data-iam-enumid="${additionalInfo.enumId}"]`).textContent = row[i];
          }
        }
      }
    }

    return {
      partyTaxId: row[0] || '',
      partyName: row[1] || '',
      address1: row[2] || '',
      stateProvinceGeoId,
      countyGeoId,
      phoneNumber: row[5] || '',
      emailAddress: row[6] || '',
      teams: _teams,
      additionalInfos: _additionalInfos,
      additionalInfoRaw: _additionalInfoRaw.innerHTML
    };
  }

  const {renderToolbar} = useToolbar({
    doCreate: IamPermission.has('CREATE') && doCreate,
    hasSelectedItem: selectedItem,
    doView: () => doUpdate(true),
    doUpdate: IamPermission.has('UPDATE') && doUpdate,
    leftButtons: IamPermission.has('UPDATE') && <Fragment>
      {statusId === statuses[0].value && <Button label="Khoá" icon="pi pi-lock" severity="danger" size="small" onClick={doDisable} disabled={!selectedItem}/>}
      {statusId === statuses[1].value && <Button label="Mở khoá" icon="pi pi-lock" severity="success" size="small" onClick={doEnable} disabled={!selectedItem}/>}
			<Button label="Thông báo Zalo" icon="pi pi-bell" severity="help" size="small" onClick={() => setDisplayZaloFollowStatus(selectedItem)} disabled={!selectedItem}/>
		</Fragment>,
    rightButtons: <Fragment>
      <ImportExcel headers={['Mã số thuế', 'Tên khách hàng', 'Địa chỉ', 'Tỉnh/Thành phố', 'Quận/Huyện', 'Số điện thoại', 'Email', 'Nhóm nhân viên']}
                   column={{field: 'partyTaxId', header: 'Mã số thuế'}}
                   formatItem={formatItem} createItem={item => Service.create(item)}
                   downloadTemplateUrl={`/import-template/import-khach-hang.xlsx`} reloadLazyData={reloadLazyData}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 17.48rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrup()}

      <ClientZaloFollowStatus display={displayZaloFollowStatus} setDisplay={setDisplayZaloFollowStatus}/>
    </div>
  );
}
