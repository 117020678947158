import {Fragment, useEffect, useState} from 'react';

import {FormatDisplay} from '@iamsoftware/react-hooks';

import {TaskService} from './cong-viec/danh-sach/TaskService';

import {TaskInfo} from './cong-viec/theo-doi-tien-do';

export const HighPriorityTasks = ({iamElasticHasChange, onTopbarItemClick}) => {

  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    loadData();

    const subscription = iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('workEfforts') && data?.message?.workEffortTypeEnumIdSet?.includes('WetTask')) {
        loadData();
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = () => {
    const params: any = {
      size: 200,
      sort: [{priority: 'asc'}, {estimatedCompletionDate: 'asc'}],
      query: {
        bool: {
          filter: {
            bool: {
              must: [
                {terms: {statusId: ['WeInPlanning', 'WeApproved', 'WeInProgress', 'WeOnHold']}},
                {term: {priority: 1}}
              ]
            }
          }
        }
      }
    };
    TaskService.getElasticTasks(JSON.stringify(params)).then(({listData}) => {
      setTasks(listData.map(item => {
        delete item._source.priority;
        return item._source;
      }));
    });
  }

  const doView = workEffortId => {
    if (workEffortId) {
      window.location.hash = `/cong-viec/danh-sach?showDetail=${workEffortId}`;
    }
  }

  return (
    <Fragment>
      <button className="p-link layout-topbar-button" title="Thông báo" onClick={e => onTopbarItemClick(e, 'HighPriorityTasks')}>
        <i className="pi pi-check-square p-overlay-badge">
          <span className="p-badge p-component p-badge-no-gutter p-badge-info">{FormatDisplay.number(tasks?.length || 0)}</span>
        </i>
        <span>Công việc Ưu tiên cao</span>
      </button>
      <div className="top-menu-item fadeInDown" style={{width: '27rem'}}>
        <div className="surface-card shadow-2 border-round p-4" style={{maxHeight: '35rem', overflowY: 'auto'}}>
          <div className="flex align-items-center justify-content-between mb-2">
            <div className="text-900 font-medium text-xl">Công việc Ưu tiên cao</div>
          </div>
          <div className="grid mt-2">
            {tasks.map((task, index) => <div key={index} className="col-12">
              <TaskInfo task={task} doView={doView}/>
            </div>)}
          </div>
        </div>
      </div>
    </Fragment>
  );
}