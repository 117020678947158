import {Service} from 'src/service/Service';

export class StatisticsTaskService extends Service {

  static entity = 'iam-report/statistics';

  static getStatsByObject(byObject: string, objectId: string): Promise<any> {
    return this.axios.get(`${this.entity}/stats`, {params: {byObject, objectId}}).then(res => res.data);
  }

  static getTasksByClient(lazyLoadEvent: string, clientId: string): Promise<any> {
    return this.axios.get(`${this.entity}/by-client`, {params: {lazyLoadEvent, clientId}}).then(res => res.data);
  }

  static getTasksByService(lazyLoadEvent: string, serviceId: string): Promise<any> {
    return this.axios.get(`${this.entity}/by-service`, {params: {lazyLoadEvent, serviceId}}).then(res => res.data);
  }

  static getTasksByEmployee(lazyLoadEvent: string, employeeId: string): Promise<any> {
    return this.axios.get(`${this.entity}/by-employee`, {params: {lazyLoadEvent, employeeId}}).then(res => res.data);
  }

  static getTasksByTeam(lazyLoadEvent: string, teamId: string): Promise<any> {
    return this.axios.get(`${this.entity}/by-team`, {params: {lazyLoadEvent, teamId}}).then(res => res.data);
  }

}
