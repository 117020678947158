import {useEffect, useRef, useState} from 'react';
import {Link, NavLink} from 'react-router-dom';

import {Menu} from 'primereact/menu';
import {classNames} from 'primereact/utils';
import {FormatDisplay, useDialogCrup} from '@iamsoftware/react-hooks';

import {AuthService} from 'src/service/AuthService';
import {NotificationsService} from 'src/service/NotificationsService';

import {AppStorage} from 'src/service/UserStorage';

import {Logo} from 'src/shared/components/Logo';
import {Notifications} from './Notifications';
import {HighPriorityTasks} from './HighPriorityTasks';

export const AppTopbar = props => {

  const {me, org, mobileTopbarMenuActive, onToggleMenuClick, onMobileTopbarMenuClick} = props;

  const menu = useRef(null);

  const [noticeCount, setNoticeCount] = useState(0);

  useEffect(() => {
    const subscription = props.taMaNotification.subscribe(() => {
      NotificationsService.count().then(data => {
        setNoticeCount(data.noticeCount);
      });
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const logout = () => {
    AuthService.logout().then(() => {
      window.location.hash = '/login';
    });
  }

  const {render: renderDialogChangePassword, create: changePassword} = useDialogCrup({
    header: '!Đổi mật khẩu',
    width: '40rem',
    fields: [
      {field: 'oldPassword', header: 'Mật khẩu cũ', required: true, type: 'Password', className: 'md:col-12'},
      {field: 'newPassword', header: 'Mật khẩu mới', required: true, type: 'Password', className: 'md:col-12'},
      {field: 'newPasswordVerify', header: 'Nhập lại mật khẩu mới', required: true, type: 'Password', className: 'md:col-12'}
    ],
    createItem: item => {
      return AuthService.changePassword(item);
    },
    checkResponseError: data => {
      let error = '';
      if (data?.isSuccess === 'N') {
        error = data.messages;
      }
      return error;
    },
    useSaveSplitButton: false
  });

  const template = (item: any, options: any) => {
    const onClick = () => {
      options.onClick({
        preventDefault: () => {
        }
      });
    }
    return (
      <NavLink to={item.target} className={options.className} onClick={onClick}>
        <span className={options.iconClassName}/>
        <span className={options.labelClassName}>{item.label}</span>
      </NavLink>
    );
  };

  const accountMenus = [
    {label: me.username, icon: 'pi pi-fw pi-user', target: '/tai-khoan', template},
    {label: 'Đổi mật khẩu', icon: 'pi pi-fw pi-key', command: changePassword},
    {separator: true},
    {label: 'Đăng xuất', icon: 'pi pi-fw pi-sign-out', command: logout}
  ];

  return (
    <div className="layout-topbar">
      <Link to="/" className="layout-topbar-logo">
        {org && <Logo last={org.lastUpdatedStamp}/>}
      </Link>

      <button type="button" className="p-link layout-menu-button layout-topbar-button" onClick={onToggleMenuClick}>
        <i className="pi pi-bars"/>
      </button>

      <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={onMobileTopbarMenuClick}>
        <i className="pi pi-ellipsis-v"/>
      </button>

      <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': mobileTopbarMenuActive})}>
        <li>
          <div className="house-holds">
            <p className="mb-0"><b>{me.partyName}</b></p>
            <p className="text-right">{me.username.replace(AppStorage.get('ownerPartyTaxId') + '_', '')}</p>
          </div>
        </li>
        <li className={classNames({'active-top-menu-item fadeInDown': props.activeTopbarItem === 'Notifications'})}>
          <button className="p-link layout-topbar-button" title="Thông báo" onClick={e => props.onTopbarItemClick(e, 'Notifications')}>
            <i className="pi pi-bell p-overlay-badge">
              <span className="p-badge p-component p-badge-no-gutter p-badge-success">{FormatDisplay.number(noticeCount)}</span>
            </i>
            <span>Thông báo</span>
          </button>
          <div className="top-menu-item fadeInDown" style={{width: '27rem'}}>
            <Notifications activeTopbarItem={props.activeTopbarItem} taMaNotification={props.taMaNotification}/>
          </div>
        </li>
        <li className={classNames({'active-top-menu-item fadeInDown': props.activeTopbarItem === 'HighPriorityTasks'})}>
          <HighPriorityTasks iamElasticHasChange={props.iamElasticHasChange} onTopbarItemClick={props.onTopbarItemClick}/>
        </li>
        <li>
          <Link to="/tai-khoan/cau-hinh-menu" className="p-link layout-topbar-button" title="Cấu hình Menu">
            <i className="pi pi-cog"/>
            <span>Cấu hình Menu</span>
          </Link>
        </li>
        <li>
          <button className="p-link layout-topbar-button" onClick={event => menu.current.toggle(event)}>
            <i className="pi pi-user"/>
            <span>Profile</span>
          </button>
          <Menu model={accountMenus} popup ref={menu}/>
        </li>
      </ul>

      {renderDialogChangePassword()}
    </div>
  );
}