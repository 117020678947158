import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {FormatDisplay, ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {IamPermission} from 'src/shared/utils/Permission';

import {UserService as Service} from './UserService';
import {TeamService} from '../team-nhom/TeamService';
import {PartyService} from 'src/service/PartyService';

import {ImportExcel} from 'src/shared/components/ImportExcel';
import {Avatar} from 'src/shared/components/Avatar';

import {Permissions} from './Permissions';
import {IdentityDocuments} from './IdentityDocuments';

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Ngừng hoạt động'}
];

export const Users = ({me}) => {

  const header = 'Nhân viên';
  const dataKey = 'userId';

  const [statusId, setStatusId] = useState(statuses[0].value);

  const [requiredParams, setRequiredParams] = useState(null);

  const [teams, setTeams] = useState([]);

  const [displayPermissions, setDisplayPermissions] = useState(null);
  const [displayIdentityDocuments, setDisplayIdentityDocuments] = useState(null);

  useEffect(() => {
    const _event: any = {
      filters: {disabled: {value: 'N', matchMode: 'equals'}}
    };
    TeamService.getList(JSON.stringify(_event)).then(({listData}) => {
      setTeams(listData.map(item => {
        return {value: item.partyId, label: item.partyName};
      }));
    });
  }, []);

  useEffect(() => {
    if (statusId) {
      setRequiredParams({disabled: {value: statusId, matchMode: 'equals'}});
    } else {
      setRequiredParams({});
    }
  }, [statusId]);

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: header,
    dataKey,
    columns: [
      {
        field: 'username', header: 'Tài khoản', width: 200, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <div>
            <div className="mr-3 w-2rem h-2rem iam-avatar" style={{float: 'left'}}>
              <Avatar partyId={rowData.partyId}/>
            </div>
            <span style={{lineHeight: '2rem'}}>{rowData.username.replace(`${me.ownerTaxId}_`, '')}</span>
          </div>;
        }
      },
      {field: 'partyName', header: 'Tên nhân viên', minWidth: 250, matchMode: 'contains'},
      {field: 'gender', header: 'Giới tính', width: 130, matchMode: 'equals', filterType: 'dropdown', filterOptions: PartyService.genders, dataType: 'fromFilterOptions'},
      {field: 'birthDate', header: 'Ngày sinh', width: 120, dataType: 'date'},
      {field: 'emailAddress', header: 'Email', minWidth: 170, matchMode: 'contains'},
      {field: 'bankAccount', header: 'Tài khoản ngân hàng', width: 180, matchMode: 'contains'},
      {field: 'comments', header: 'Ghi chú', width: 170, matchMode: 'contains'},
      {field: 'teamNames', header: 'Nhóm', width: 170, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '60rem',
    fields: [
      {field: 'teamIds', type: 'hidden'},
      {field: 'partyId', type: 'hidden'},
      {field: 'partyName', header: 'Tên nhân viên', required: true, className: 'md:col-6'},
      {field: 'gender', header: 'Giới tính', type: 'Dropdown', DropdownProps: {options: PartyService.genders}, className: 'md:col-3'},
      {field: 'birthDate', header: 'Ngày sinh', InputTextProps: {type: 'date'}, className: 'md:col-3'},
      {field: 'username', header: 'Tài khoản', required: true, InputTextProps: {maxLength: 50, keyfilter: /\S/}, className: 'md:col-6'},
      {field: 'emailAddress', header: 'Email', required: true, InputTextProps: {maxLength: 50}, className: 'md:col-6'},
      {field: 'bankAccount', header: 'Tài khoản ngân hàng', className: 'md:col-12'},
      {
        field: 'teamId', header: 'Nhóm nhân viên', type: 'Dropdown', DropdownProps: {options: teams, showClear: true}, displayDependency(item: any): boolean {
          return !item.teamIds;
        }, className: 'md:col-6'
      },
      {
        field: 'teamRoleTypeId', header: 'Vai trò', type: 'Dropdown', DropdownProps: {options: TeamService.roleTypes, showClear: true}, displayDependency(item: any): boolean {
          return !item.teamIds;
        }, className: 'md:col-6'
      },
      {field: 'comments', header: 'Ghi chú', className: 'md:col-12'}
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const {render: renderDialogResetPassword, update: resetPassword} = useDialogCrup({
    header: '!Đặt lại mật khẩu',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'resetPassword', header: 'Mật khẩu mới', required: true, type: 'Password', className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return Service.resetPassword(id, item.resetPassword);
    }
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doUpdate = () => {
    if (selectedItem && selectedItem[dataKey]) {
      const data = Object.assign({}, selectedItem);
      if (data.birthDate) {
        data.birthDate = FormatDisplay.date(data.birthDate, 'YYYY-MM-DD');
      }
      data.username = data.username.replace(`${me.ownerTaxId}_`, '');
      update(data);
    }
  }

  const doDisable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.disable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }
  const doEnable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.enable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const formatItem = row => {
    let birthDate;
    if (row[4] > 0) {
      try {
        birthDate = '' + new Date(Date.UTC(0, 0, row[4] - 1)).getTime();
      } catch (e) {
      }
    }
    let gender;
    PartyService.genders.forEach(g => {
      if (g.label === row[3]) {
        gender = g.value;
      }
    });
    let teamId;
    teams.forEach(team => {
      if (team.label === row[5]) {
        teamId = team.value;
      }
    });
    let teamRoleTypeId;
    TeamService.roleTypes.forEach(roleType => {
      if (roleType.label === row[6]) {
        teamRoleTypeId = roleType.value;
      }
    });
    return {
      username: row[0] || '',
      emailAddress: row[1] || '',
      partyName: row[2] || '',
      gender,
      birthDate,
      teamId,
      teamRoleTypeId,
      bankAccount: row[7] || '',
      comments: row[8] || ''
    };
  }

  const {renderToolbar} = useToolbar({
    doCreate: IamPermission.has('CREATE') && create,
    hasSelectedItem: selectedItem,
    doUpdate: IamPermission.has('UPDATE') && doUpdate,
    leftButtons: <Fragment>
      {IamPermission.has('UPDATE') && <Fragment>
        {statusId === statuses[0].value && <Button label="Khoá" icon="pi pi-lock" severity="danger" size="small" onClick={doDisable} disabled={!selectedItem}/>}
        {statusId === statuses[1].value && <Button label="Mở khoá" icon="pi pi-lock" severity="success" size="small" onClick={doEnable} disabled={!selectedItem}/>}
			</Fragment>}
      {IamPermission.has('ALL') && <Button label="Đặt lại mật khẩu" icon="pi pi-history" severity="warning" size="small" onClick={() => resetPassword(selectedItem)} disabled={!selectedItem}/>}
      {IamPermission.has('ALL') && <Button label="Phân quyền" icon="pi pi-key" severity="help" size="small" onClick={() => setDisplayPermissions(selectedItem)} disabled={!selectedItem}/>}
      {IamPermission.has('ALL') && <Button label="Hồ sơ nhân sự" icon="pi pi-book" severity="help" size="small" onClick={() => setDisplayIdentityDocuments(selectedItem)} disabled={!selectedItem}/>}
    </Fragment>,
    rightButtons: <Fragment>
      <ImportExcel headers={['Tài khoản', 'Email', 'Tên nhân viên', 'Giới tính', 'Ngày sinh', 'Nhóm', 'Vai trò', 'Ghi chú']}
                   column={{field: 'username', header: 'Tài khoản'}}
                   formatItem={formatItem} createItem={item => Service.create(item)}
                   downloadTemplateUrl={`/import-template/import-nguoi-dung.xlsx`} reloadLazyData={reloadLazyData}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      {IamPermission.has(['CREATE', 'UPDATE', 'ALL']) && <div className="col-12">
        {renderToolbar()}
			</div>}
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 17.48rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
      {renderDialogResetPassword()}

      <Permissions display={displayPermissions} setDisplay={setDisplayPermissions}/>
      <IdentityDocuments display={displayIdentityDocuments} setDisplay={setDisplayIdentityDocuments}/>
    </div>
  );
}
