import {Service} from 'src/service/Service';

export class DashboardService extends Service {

  static entity = 'iam-report';

  static getStatsByStatus(statusId: string): Promise<any> {
    return this.axios.get(`${this.entity}/my-tasks/stats/by-status`, {params: {statusId}}).then(res => res.data);
  }

  static getStatsExpiredTask(): Promise<any> {
    return this.axios.get(`${this.entity}/my-tasks/stats/expired-task`).then(res => res.data);
  }

  static getTasksOverview(fromDate: string, toDate: string): Promise<any> {
    return this.axios.get(`${this.entity}/my-tasks/tasks-overview`, {params: {fromDate, toDate}}).then(res => res.data);
  }

}
