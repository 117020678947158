import {Fragment, useRef, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ToastService, useDataTableBasic} from '@iamsoftware/react-hooks';

import {XlsxService} from 'src/service/XlsxService';

export const ImportExcel = ({headers, column, formatItem, createItem, downloadTemplateUrl, reloadLazyData}) => {

  const inputExcel = useRef(null);

  const [displayImport, setDisplayImport] = useState(false);
  const [displayResults, setDisplayResults] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [contentFile, setContentFile] = useState(null);

  const [results, setResults] = useState([]);

  const {render: renderResults} = useDataTableBasic({
    indexColumnWidth: 50,
    columns: [
      {...column, minWidth: 140, matchMode: 'contains'},
      {field: 'import_status', header: 'Trạng thái', minWidth: 140, matchMode: 'contains'}
    ],
    items: results
  });

  const onUploadExcel = e => {
    if (e.target.files && e.target.files.length === 1) {
      setContentFile(e.target.files[0]);
    }
  }

  const doImport = () => {
    if (!submitting && contentFile) {
      setSubmitting(true);

      XlsxService.readExcelFile(contentFile).then(async rows => {
        try {
          headers.forEach((header, index) => {
            if (header !== rows[0][index]) {
              throw new Error('Dữ liệu không hợp lệ.');
            }
          });

          window['_hideToast'] = true;

          const complete = [];
          for (const row of rows.slice(1)) {
            const item = await formatItem(row, rows[0]);
            complete.push(createItem(item).then(() => {
              item.import_status = 'Thành công';
              return item;
            }).catch(err => {
              item.import_status = err?.response?.data?.errors || 'Có lỗi xảy ra';
              return item;
            }));
          }

          Promise.all(complete).then(_results => {
            setResults(_results);
            setDisplayResults(true);

            reloadLazyData && reloadLazyData();
          }).finally(() => {
            inputExcel.current.value = '';
            setDisplayImport(false);

            setSubmitting(false);

            window['_hideToast'] = false;
          });
        } catch (e) {
          ToastService.error(e.toString());
          setSubmitting(false);

          window['_hideToast'] = false;
        }
      });
    } else {
      ToastService.error('Chưa chọn File upload.');
    }
  }

  const downloadTemplate = () => {
    window.location.href = downloadTemplateUrl;
  }

  const footerImport = (
    <div>
      <Button label="Tải File mẫu" icon="pi pi-download" severity="success" size="small" onClick={downloadTemplate}/>
      <Button label="Import" icon="pi pi-file-import" size="small" onClick={doImport} loading={submitting}/>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplayImport(false)}/>
    </div>
  );

  const footerResult = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplayResults(false)}/>
    </div>
  );

  return (
    <Fragment>
      <Button label="Import Excel" icon="pi pi-file-import" severity="success" size="small" onClick={() => setDisplayImport(true)}/>

      <Dialog header="Import Excel" footer={footerImport} visible={displayImport} style={{width: '40rem'}} position="top" onHide={() => setDisplayImport(false)}>
        <div className="p-fluid formgrid grid">
          <div className="field col-12 md:col-12">
            <input ref={inputExcel} type="file" accept=".xlsx, .xls" onChange={onUploadExcel}/>
          </div>
        </div>
      </Dialog>

      <Dialog header="Kết quả" footer={footerResult} visible={displayResults} style={{width: '90rem'}} position="top" onHide={() => setDisplayResults(false)}>
        {renderResults()}
      </Dialog>
    </Fragment>
  );
}