import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {OfficeAppsViewer, ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {OrgDocumentService as Service} from './OrgDocumentService';
import {AuthService} from 'src/service/AuthService';

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Ngừng hoạt động'}
];

export const OrgDocuments = () => {

  const header = 'Tài liệu - biểu mẫu';
  const dataKey = 'partyContentId';

  const [statusId, setStatusId] = useState(statuses[0].value);

  const [requiredParams, setRequiredParams] = useState(null);

  const [displayViewer, setDisplayViewer] = useState(null);

  useEffect(() => {
    if (statusId) {
      setRequiredParams({disabled: {value: statusId, matchMode: 'equals'}});
    } else {
      setRequiredParams({});
    }
  }, [statusId]);

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'description', header: 'Tên biểu mẫu', minWidth: 250, matchMode: 'contains'},
      {field: 'createdByUserFullName', header: 'Người tạo', width: 200, matchMode: 'contains'},
      {field: 'contentDate', header: 'Ngày tạo', width: 160, dataType: 'date-time'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {

      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '50rem',
    fields: [
      {field: 'partyId', type: 'hidden'},
      {field: 'description', header: 'Tên biểu mẫu', required: true, className: 'md:col-12'},
      {field: 'contentFile', header: 'File', required: 'Create', type: 'file', className: 'md:col-12'}
    ],
    createItem: async item => {
      if (item.contentFile) {
        item.contentFile = item.contentFile[0];
      }
      return Service.create(Service.toFormData(item));
    },
    updateItem: async (id, item) => {
      if (item.contentFile) {
        item.contentFile = item.contentFile[0];
      }
      return Service.update(id, Service.toFormData(item));
    },
    reloadLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doCreate = () => {
    create();
  }

  const doView = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.get(selectedItem[dataKey]).then(data => {
        AuthService.getApiKey().then(({token}) => {
          setDisplayViewer({
            type: data.contentType, name: data.fileName,
            src: `${window.location.origin}${Service.baseURL}/iam-content/${token}/party/${selectedItem[dataKey]}/download`
          });
        });
      });
    }
  }

  const doDownload = () => {
    if (selectedItem && selectedItem[dataKey]) {
      window.open(`${Service.baseURL}/${Service.entity}/${selectedItem[dataKey]}/download`)
    }
  }

  const doUpdate = () => {
    if (selectedItem && selectedItem[dataKey]) {
      update(selectedItem);
    }
  }

  const doDisable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.disable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }
  const doEnable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.enable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate: doCreate,
    hasSelectedItem: selectedItem,
    doView: doView,
    doUpdate: doUpdate,
    leftButtons: <Fragment>
      {statusId === statuses[0].value && <Button label="Khoá" icon="pi pi-lock" severity="danger" size="small" onClick={doDisable} disabled={!selectedItem}/>}
      {statusId === statuses[1].value && <Button label="Mở khoá" icon="pi pi-lock" severity="success" size="small" onClick={doEnable} disabled={!selectedItem}/>}
      <Button label="Tải xuống" icon="pi pi-download" severity="help" size="small" onClick={doDownload} disabled={!selectedItem}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 17.48rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrup()}

      <OfficeAppsViewer display={displayViewer} setDisplay={setDisplayViewer}/>
    </div>
  );
}