import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {FormatDisplay} from '@iamsoftware/react-hooks';

import {NotificationsService} from 'src/service/NotificationsService';

export const Notifications = ({activeTopbarItem, taMaNotification}) => {

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (activeTopbarItem === 'Notifications') {
      NotificationsService.getNotifications().then(data => {
        setNotifications(data.listData.map(item => {
          item.messageJson = JSON.parse(item.messageJson);
          switch (item.typeString) {
            case 'success':
              item.color = 'green';
              break;
            case 'info':
              item.color = 'blue';
              break;
            case 'warn':
              item.color = 'orange';
              break;
            case 'error':
              item.color = 'pink';
              break;
          }
          return item;
        }));
        NotificationsService.markTouched().then(() => taMaNotification.next());
      });
    }
  }, [activeTopbarItem]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="surface-card shadow-2 border-round p-4" style={{maxHeight: '35rem', overflowY: 'auto'}}>
      <div className="flex align-items-center justify-content-between mb-2">
        <div className="text-900 font-medium text-xl">Thông báo</div>
      </div>
      <ul className="p-0 mx-0 mt-0 list-none">
        {notifications.map((notification, index) => <li key={index} className="flex align-items-center py-2 border-bottom-1 surface-border">
          <div className={`w-3rem h-3rem flex align-items-center justify-content-center bg-${notification.color}-100 border-circle mr-3 flex-shrink-0`}>
            <i className={`pi ${notification.messageJson?.icon} text-xl text-${notification.color}-500`}></i>
          </div>
          <div className="text-900 line-height-3 font-medium">
            <div dangerouslySetInnerHTML={{__html: notification.messageJson?.titleMessage}}></div>
            <div className="flex justify-content-between">
              <div>
                {notification.messageJson?.linkHref && <Link to={notification.messageJson?.linkHref}>
									<i className="pi pi-reply text-sm mr-2"></i><span>{notification.messageJson?.linkText}</span>
                </Link>}
              </div>
              <span className="text-700">{FormatDisplay.timeAgo(notification.sentDate)}</span>
            </div>
          </div>
        </li>)}
      </ul>
    </div>
  );
}