import {Fragment, useEffect, useState} from 'react';

import {AutoComplete} from 'primereact/autocomplete';
import {useDataTable} from '@iamsoftware/react-hooks';

import {StatisticsTaskService} from './StatisticsTaskService';
import {TaskService} from '../../cong-viec/danh-sach/TaskService';
import {ServiceService} from '../../danh-muc/dich-vu/ServiceService';

import {Stats} from './Stats';

export const ByService = ({iamElasticHasChange, statuses}) => {

  const header = 'Công việc';
  const dataKey = 'workEffortId';

  const [services, setServices] = useState([]);
  const [service, setService] = useState(null);

  const [stats, setStats] = useState({} as any);

  useEffect(() => {
    const subscription = iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('workEfforts') && data?.message?.workEffortTypeEnumIdSet?.includes('WetTask')) {
        loadData();
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadData();
  }, [service]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = () => {
    if (service?.productId && service?.productClassEnumId) {
      StatisticsTaskService.getStatsByObject('Service', service.productId).then(data => {
        setStats(data);
      });
      reloadLazyData();
    } else {
      setStats({});
    }
  }

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    stateKey: `StatisticsTask_ByService_${service?.productId}`,
    tableHeader: header + ` ${service?.productName || ''}`,
    dataKey,
    columns: [
      {field: 'workEffortId', header: 'Mã CV', width: 100, matchMode: 'equals'},
      {field: 'workEffortName', header: 'Công việc', minWidth: 200, sortable: false, matchMode: 'contains'},
      {field: 'priority', header: 'Ưu tiên', width: 90, matchMode: 'numeric', dataType: 'number'},
      {field: 'estimatedStartDate', header: 'Ngày bắt đầu', width: 170, dataType: 'date-time'},
      {field: 'estimatedCompletionDate', header: 'Ngày hoàn thành', width: 170, dataType: 'date-time'},
      {field: 'description', header: 'Ghi chú', width: 150, matchMode: 'contains'},
      {field: 'status', filterField: 'statusId', header: 'Trạng thái', width: 150, matchMode: 'equals', filterType: 'dropdown', filterOptions: statuses},
      {
        field: 'employee', filterField: 'parties.partyPseudoId', header: 'Nhân viên', minWidth: 180, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'Assignee')
            ?.map((party, index) => <p key={index} className="mb-0" title={`Công việc tạo bởi ${rowData.createdByPartyName}`}>[{party.partyPseudoId}] {party.partyName}</p>);
        }
      },
      {
        field: 'customerName', filterField: 'parties.partyName', header: 'Khách hàng', minWidth: 180, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'Customer')
            ?.map((party, index) => <p key={index} className="mb-0">- {party.partyName}</p>);
        }
      },
      {
        field: 'customerPseudoId', filterField: 'parties.partyPseudoId@IAM@1', header: 'MST khách hàng', minWidth: 180, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'Customer')
            ?.map((party, index) => <p key={index} className="mb-0">- {party.partyPseudoId}</p>);
        }
      }
    ],
    indexColumnWidth: 45,
    elastic: {
      tie_breaker_id: dataKey,
      sort: [
        {lastUpdatedStamp: 'desc'}
      ],
      getPitId(): Promise<any> {
        return TaskService.getPitId();
      },
      deletePit(id: string): Promise<any> {
        return TaskService.deletePit(id);
      }
    },
    getList: lazyLoadEvent => {
      if (service?.productId) {
        return StatisticsTaskService.getTasksByService(lazyLoadEvent, service.productId);
      } else {
        return Promise.resolve({});
      }
    }
  });

  const searchServices = event => {
    ServiceService.find(event.query).then(data => {
      setServices(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.productId, label: `[${item.pseudoId}] - ${item.productName}`};
      }));
    });
  }

  return (
    <Fragment>
      <div className="p-fluid formgrid grid mb-2">
        <div className="col-6">
          <AutoComplete value={service} suggestions={services} completeMethod={searchServices} onChange={e => setService(e.value)}
                        field="label" dropdown placeholder="Chọn dịch vụ"/>
        </div>
      </div>

      <Stats stats={stats}/>

      <div className="grid">
        <div className="col-12 pb-0">
          {renderDataTable()}
        </div>
      </div>
    </Fragment>
  );
}