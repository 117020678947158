import {Route, Routes} from 'react-router-dom';

import {Report} from './Report';
import {ResolvedTasks} from './ResolvedTasks';

export default function Result({iamElasticHasChange}) {

  return (
    <Routes>
      <Route path="" element={<Report iamElasticHasChange={iamElasticHasChange}/>}/>
      <Route path=":serviceId" element={<ResolvedTasks iamElasticHasChange={iamElasticHasChange}/>}/>
    </Routes>
  );
}
