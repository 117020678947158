import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {FormatDisplay, OfficeAppsViewer, ToastService, useFileUploader, useForm} from '@iamsoftware/react-hooks';

import {IamPermission} from 'src/shared/utils/Permission';

import {TaskService as Service} from './TaskService';
import {ServiceService} from '../../danh-muc/dich-vu/ServiceService';
import {TeamService} from '../../he-thong/team-nhom/TeamService';
import {UserService} from '../../he-thong/nhan-vien/UserService';
import {AuthService} from 'src/service/AuthService';

import {useCrupTaskClients} from './CrupTaskClients';

interface Props {
  me: any

  reloadLazyData?(): void

  disabledField?: any

  setDisplayResolve?(workEffortId: string): void
}

export const useCrupTask = (props: Props) => {

  const dataKey = 'workEffortId';

  const [workEffortId, setWorkEffortId] = useState(null);
  const [statusId, setStatusId] = useState(null);
  const [display, setDisplay] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [services, setServices] = useState([]);
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const [attachments, setAttachments] = useState(null);

  const [displayViewer, setDisplayViewer] = useState(null);

  useEffect(() => {
    const _event: any = {
      filters: {disabled: {value: 'N', matchMode: 'equals'}}
    };
    TeamService.getList(JSON.stringify(_event)).then(({listData}) => {
      setTeams(listData.map(item => {
        return {value: item.partyId, label: item.partyName};
      }));
    });
  }, []);

  const onFileSelect = async file => {
    if (file.key) {
      setDisplayViewer({type: file.contentType, name: file.fileName, src: file.viewSrc});
    } else {
      const src = window.URL.createObjectURL(new Blob([file], {type: file.type}));
      window.open(src);
    }
  }

  const {renderFileUploader: renderAttachments, getFiles: getAttachments, deletedExistingFiles: deletedAttachments} = useFileUploader({
    accept: 'image/*, application/pdf, .xlsx, .docx, .ppt',
    onSelect: onFileSelect,
    existingFiles: attachments,
    disabled
  });

  const searchServices = event => {
    ServiceService.find(event.query).then(data => {
      setServices(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.productId, label: `[${item.pseudoId}] - ${item.productName}`};
      }));
    });
  }

  const form = useForm({
    fields: [
      {
        field: 'service', header: 'Dịch vụ', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: services, completeMethod: searchServices, field: 'label', dropdown: true, forceSelection: true},
        className: 'md:col-12', disabled: props.disabledField?.service === true
      },
      {field: 'workEffortName', header: 'Tên công việc', required: true, className: 'md:col-12', disabled: props.disabledField?.workEffortName === true},
      {field: 'teamId', header: 'Nhóm', required: true, type: 'Dropdown', DropdownProps: {options: teams}, className: 'md:col-4'},
      {
        field: 'employees', header: 'Nhân viên', required: true, type: 'MultiSelect',
        MultiSelectProps: {options: users},
        className: 'md:col-8', disabledDependency(item: any): boolean {
          if (props.disabledField?.employees === true) {
            return true;
          }
          return !item?.teamId;
        }
      },
      {field: 'estimatedStartDate', header: 'Ngày bắt đầu dự tính', required: true, InputTextProps: {type: 'datetime-local'}, className: 'md:col-5'},
      {field: 'estimatedCompletionDate', header: 'Ngày hoàn thành dự tính', required: true, InputTextProps: {type: 'datetime-local'}, className: 'md:col-5'},
      {field: 'priority', header: 'Ưu tiên', type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-2'},
      {field: 'description', header: 'Ghi chú', type: 'InputTextarea', className: 'md:col-12'},
      {
        field: 'attachments', header: 'File đính kèm', type: 'FileUploader', FileUploaderProps: {render: renderAttachments}, className: 'md:col-12'
      }
    ],
    disabled
  });

  const {service, teamId} = form.getValue();
  useEffect(() => {
    if (service?.productName) {
      const _value = form.getRawValue();
      _value.workEffortName = service.productName;
      form.setValue(_value);
    }
  }, [service]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (form.dirty['teamId']) {
      const _value = form.getRawValue();
      _value.employees = null;
      form.setValue(_value);
    }
    if (teamId) {
      UserService.find('', teamId).then(data => {
        setUsers(data.listData.map(item => {
          return {value: item.partyId, label: `[${item.username.replace(`${props.me.ownerTaxId}_`, '')}] - ${item.partyName}`};
        }));
      });
    }
  }, [teamId]); // eslint-disable-line react-hooks/exhaustive-deps

  const {renderClients, clients, setClients} = useCrupTaskClients({display, teamId, disabled});

  const doCreate = (defaultValue?: any) => {

    setAttachments(defaultValue?.attachments || []);
    setDisabled(false);

    form.setValue(defaultValue?.formValue || {estimatedStartDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD HH:mm')});

    setClients(defaultValue?.clients || []);

    setDisplay(Date.now());
  }

  const doUpdate = (workEffortId: string, data: any, doView: boolean) => {
    setWorkEffortId(workEffortId);
    setStatusId(data.statusId);

    if (data.serviceId) {
      data.service = {value: data.serviceId, label: `[${data.servicePseudoId}] - ${data.serviceName}`};
    }

    if (data.estimatedStartDate) {
      data.estimatedStartDate = FormatDisplay.date(data.estimatedStartDate, 'YYYY-MM-DD HH:mm');
    }
    if (data.estimatedCompletionDate) {
      data.estimatedCompletionDate = FormatDisplay.date(data.estimatedCompletionDate, 'YYYY-MM-DD HH:mm');
    }

    if (data.attachments?.length) {
      AuthService.getApiKey().then(({token}) => {
        const _attachments = [];
        for (const attachment of data.attachments) {
          _attachments.push({
            key: attachment.workEffortContentId,
            contentType: attachment.contentType,
            fileName: attachment.fileName,
            src: `${Service.baseURL}/${Service.entity}/${data[dataKey]}/contents/${attachment.workEffortContentId}/download`,
            viewSrc: `${window.location.origin}${Service.baseURL}/iam-content/${token}/work-effort/${attachment.workEffortContentId}/download`
          });
        }
        setAttachments(_attachments);
      });
    }

    form.setValue(data);

    setClients(data.clients);

    if (doView === true) {
      setDisabled(true);

      setDisplay(Date.now());
    } else {
      setDisabled(false);

      setDisplay(Date.now());
    }
  }

  const inject = async () => {
    const item = form.getValue();
    item.serviceId = item.service.value;
    delete item.service;
    item.employees = JSON.stringify(item.employees);

    item.attachments = await getAttachments(false);
    if (item.attachments.length === 1) {
      item.attachment = item.attachments[0];
      delete item.attachments;
    }
    if (deletedAttachments?.length) {
      if (deletedAttachments.length === 1) {
        item.deletedAttachment = deletedAttachments[0];
      } else {
        item.deletedAttachments = deletedAttachments;
      }
    }

    if (clients?.length) {
      item.clients = JSON.stringify(clients.map(client => {
        const _client: any = {
          clientId: client.clientId, comments: client.comments, workEffortId: client.workEffortId
        };
        if (client.rootWorkEffortId) {
          _client.rootWorkEffortId = client.rootWorkEffortId;
        }
        if (client.rootEstimatedStartDate) {
          _client.rootEstimatedStartDate = client.rootEstimatedStartDate;
          if (typeof _client.rootEstimatedStartDate === 'number') {
            _client.rootEstimatedStartDate = _client.rootEstimatedStartDate.toString();
          }
        }
        if (client.fromDate) {
          _client.fromDate = client.fromDate;
          if (typeof _client.fromDate === 'number') {
            _client.fromDate = _client.fromDate.toString();
          }
        }
        return _client;
      }));
    }

    return Service.toFormData(item);
  }

  const onSubmit = async event => {
    if (!submitting && form.valid()) {
      setSubmitting(true);

      let promise;
      if (workEffortId) {
        promise = Service.update(workEffortId, await inject());
      } else {
        promise = Service.create(await inject());
      }
      promise.then(() => {
        setDisplay(null);
        ToastService.success();
        props.reloadLazyData && props.reloadLazyData();
      }).finally(() => {
        setSubmitting(false);
      });
    }
    event.preventDefault();
  }

  const showResolve = () => {
    props.setDisplayResolve(workEffortId);
    setDisplay(null);
  }

  const footer = (
    <div className="flex justify-content-center">
      {!disabled && <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit} loading={submitting}/>}
      {disabled && <Fragment>
        {IamPermission.has('UPDATE') && ['WeInPlanning', 'WeOnHold'].includes(statusId) &&
					<Button label="Chỉnh sửa" icon="pi pi-pencil" severity="warning" onClick={() => setDisabled(false)}/>
        }
        {props.setDisplayResolve && <Button label="Xử lý" icon="pi pi-spin pi-bolt" severity="info" size="small" onClick={showResolve}/>}
			</Fragment>}
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );

  const render = () => {
    return (
      <Fragment>
        <Dialog header="Công việc" footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
          <div className="grid pt-2">
            <div className="col-4">
              {form.render()}
            </div>
            <div className="col-8">
              {renderClients()}
            </div>
          </div>
        </Dialog>

        <OfficeAppsViewer display={displayViewer} setDisplay={setDisplayViewer}/>
      </Fragment>
    );
  }

  return {renderCrupTask: render, doCreateTask: doCreate, doUpdateTask: doUpdate};
}