import {useEffect, useRef, useState} from 'react';

import {Button} from 'primereact/button';
import {FormatDisplay, ToastService, useForm} from '@iamsoftware/react-hooks';

import {ProfileService} from './ProfileService';
import {PartyService} from 'src/service/PartyService';

import {Avatar} from 'src/shared/components/Avatar';

export const MyAccount = ({me}) => {

  const inputImg = useRef(null);

  const [submitting, setSubmitting] = useState(false);

  const [avatar, setAvatar] = useState(null);
  const [avatarSrc, setAvatarSrc] = useState(null);

  useEffect(() => {
    ProfileService.get().then(data => {
      if (data.birthDate) {
        data.birthDate = FormatDisplay.date(data.birthDate, 'YYYY-MM-DD');
      }
      form.setValue(data);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const form = useForm({
    fields: [
      {field: 'partyName', header: 'Tên nhân viên', required: true, className: 'md:col-6'},
      {field: 'gender', header: 'Giới tính', type: 'Dropdown', DropdownProps: {options: PartyService.genders}, className: 'md:col-3'},
      {field: 'birthDate', header: 'Ngày sinh', InputTextProps: {type: 'date'}, className: 'md:col-3'},
      {field: 'emailAddress', header: 'Email', required: true, InputTextProps: {maxLength: 50}, className: 'md:col-6'},
      {field: 'phoneNumber', header: 'Số điện thoại', InputTextProps: {maxLength: 17, keyfilter: 'int'}, className: 'md:col-6'},
      {field: 'comments', header: 'Ghi chú', type: 'InputTextarea', className: 'md:col-12'}
    ]
  });

  const onUploadImage = e => {
    if (e.target.files && e.target.files.length === 1) {
      const file = e.target.files[0];
      setAvatar(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarSrc(reader.result);
      }
      reader.readAsDataURL(file);
    }
  }

  const onSubmit = event => {
    if (!submitting && form.valid()) {
      setSubmitting(true);

      const data = form.getValue();
      if (avatar) {
        data.avatar = avatar;
      }
      ProfileService.set(ProfileService.toFormData(data)).then(() => {
        ToastService.success();
      }).finally(() => setSubmitting(false));
    }
    event.preventDefault();
  }

  return (
    <div className="flex gap-5 flex-column-reverse md:flex-row">
      <div className="flex flex-column align-items-center flex-or">
        <div className="h-10rem w-10rem iam-avatar">
          {!avatarSrc && <Avatar partyId={me.partyId} last={me.lastUpdatedStamp}/>}
          {avatarSrc && <img src={avatarSrc} alt="Avatar"/>}
        </div>
        <input ref={inputImg} type="file" accept="image/*" hidden onChange={onUploadImage}/>
        <button className="p-button p-component p-button-rounded -mt-4 p-button-icon-only" onClick={() => inputImg.current.click()}>
          <span className="p-button-icon p-c pi pi-pencil"></span>
          <span className="p-button-label p-c">&nbsp;</span><span role="presentation" className="p-ink"></span>
        </button>
      </div>
      <div className="flex-auto">
        {form.render()}
        <div>
          <Button label="Cập nhật thông tin" icon="pi pi-check" size="small" onClick={onSubmit} loading={submitting}/>
        </div>
      </div>

    </div>
  );
}