import {EntityService} from 'src/service/EntityService';

export class ClientService extends EntityService {

  static entity = 'iam-party/clients';

  static find(term: string, teamId?: string): Promise<any> {
    return this.axios.get(`${this.entity}/find`, {params: {term, teamId}}).then(res => res.data);
  }

  static getZaloFollowStatus(zoaId: string, partyId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${partyId}/zalo-follow-status`, {params: {zoaId}}).then(res => res.data);
  }

}
