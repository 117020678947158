import {Fragment, useEffect, useRef, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {SelectButton} from 'primereact/selectbutton';
import {ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {IamPermission} from 'src/shared/utils/Permission';

import {ServiceService as Service} from './ServiceService';
import {ClientAdditionalInfoService} from '../thong-tin-khach-hang/ClientAdditionalInfoService';

import {TinyEditor} from 'src/shared/components/Tiny-Editor';

import {setupEditor} from './setupEditor';

export const ServiceTemplates = ({display, setDisplay}) => {

  const header = 'Biểu mẫu';

  const [additionalInfos, setAdditionalInfos] = useState(null);
  const [service, setService] = useState(null);

  useEffect(() => {
    const _event: any = {
      filters: {disabled: {value: 'N', matchMode: 'equals'}}
    };
    ClientAdditionalInfoService.getList(JSON.stringify(_event)).then(({listData}) => {
      setAdditionalInfos(listData);
    });
  }, []);

  useEffect(() => {
    if (display) {
      setService(null);
      Service.get(display).then(data => {
        setService(data);
      });
    }
  }, [display]);

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );
  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '70rem'}} position="top" onHide={() => setDisplay(false)}>
      {(additionalInfos && !!service) && <Screen additionalInfos={additionalInfos} productId={display} service={service}/>}
    </Dialog>
  );
}

const statuses = [
  {value: 'Active', label: 'Đang hoạt động'},
  {value: 'Inactive', label: 'Ngừng hoạt động'}
];

const Screen = ({additionalInfos, productId, service}) => {

  const header = 'Biểu mẫu';
  const dataKey = 'productContentId';

  const editorRef = useRef(null);

  const [statusId, setStatusId] = useState(null);

  const [editorReadonly, setEditorReadonly] = useState(false);
  const [editorValue, setEditorValue] = useState(null);

  useEffect(() => {
    if (statusId) {
      reloadLazyData();
    } else {
      setStatusId(statuses[0].value);
    }
  }, [statusId]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    stateKey: 'ServiceTemplates-Table',
    dataKey,
    columns: [
      {field: 'sequenceNum', header: 'Thứ tự', width: 150, dataType: 'number', matchMode: 'numeric'},
      {field: 'description', header: 'Tên biểu mẫu', minWidth: 250, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      if (productId && statusId) {
        return Service.getContents(productId, lazyLoadEvent, {contentStatus: statusId});
      } else {
        return Promise.resolve({});
      }
    }
  });

  const setup = editor => {
    setupEditor(editor, additionalInfos, service.serviceFeatures);
  }

  const {render: renderDialogCrup, create, view, update} = useDialogCrup({
    header,
    dataKey,
    width: '',
    fields: [
      {field: 'description', header: 'Tên biểu mẫu', required: true, className: 'md:col-9'},
      {field: 'sequenceNum', header: 'Thứ tự', type: 'InputNumber', className: 'md:col-3'},
      {type: 'custom', className: 'md:col-12 mt-2', body: <b>{header}</b>},
      {type: 'custom', className: 'md:col-12', body: <TinyEditor ref={editorRef} mode="document" height="calc(100vh - 20rem)" readonly={editorReadonly} initialValue={editorValue} contextmenu="iamContextMenu" setup={setup}/>}
    ],
    createItem: async item => {
      item.serviceTemplate = await editorRef.current.getContent();
      return Service.createContent(productId, item);
    },
    updateItem: async (id, item) => {
      item.serviceTemplate = await editorRef.current.getContent();
      return Service.updateContent(productId, id, item);
    },
    reloadLazyData,
    originalDialogProps: {maximized: true} as any
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doCreate = () => {
    setEditorValue('');
    setEditorReadonly(false);
    create();
  }

  const doUpdate = (doView?: boolean) => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.getContent(productId, selectedItem[dataKey]).then(data => {
        setEditorValue(data.serviceTemplate);

        if (doView === true) {
          setEditorReadonly(true);
          view(data);
        } else {
          setEditorReadonly(false);
          update(data);
        }
      });
    }
  }

  const doDisable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.disableContent(productId, selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }
  const doEnable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.enableContent(productId, selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate: IamPermission.has('CREATE') && doCreate,
    hasSelectedItem: selectedItem,
    doView: () => doUpdate(true),
    doUpdate: IamPermission.has('UPDATE') && doUpdate,
    leftButtons: IamPermission.has('UPDATE') && <Fragment>
      {statusId === statuses[0].value && <Button label="Khoá" icon="pi pi-lock" severity="danger" size="small" onClick={doDisable} disabled={!selectedItem}/>}
      {statusId === statuses[1].value && <Button label="Mở khoá" icon="pi pi-lock" severity="success" size="small" onClick={doEnable} disabled={!selectedItem}/>}
    </Fragment>
  });

  return (
    <div className="grid mt-2">
      <div className="col-12">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0">
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>
  );
}