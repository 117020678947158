import {Fragment, useEffect, useState} from 'react';

import {AutoComplete} from 'primereact/autocomplete';
import {Badge} from 'primereact/badge';
import {Checkbox} from 'primereact/checkbox';
import {FormatDisplay} from '@iamsoftware/react-hooks';

import {TeamService} from '../../he-thong/team-nhom/TeamService';
import {TaskService} from '../danh-sach/TaskService';

import {useCrupTask} from '../danh-sach/CrupTask';

import {Avatar} from 'src/shared/components/Avatar';
import {TaskProgression} from 'src/shared/components/TaskProgression';

export const Progress = ({me, iamElasticHasChange}) => {

  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState(null);
  const [highPriority, setHighPriority] = useState(false);

  const [inPlanningTasks, setInPlanningTasks] = useState([]);
  const [inProgressTasks, setInProgressTasks] = useState([]);
  const [onHoldTasks, setOnHoldTasks] = useState([]);

  useEffect(() => {
    const subscription = iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('workEfforts') && data?.message?.workEffortTypeEnumIdSet?.includes('WetTask')) {
        load();
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    load();
  }, [team, highPriority]); // eslint-disable-line react-hooks/exhaustive-deps

  const load = () => {
    const params: any = {
      size: 200,
      sort: [{priority: 'asc'}, {estimatedCompletionDate: 'asc'}],
      query: {
        bool: {
          filter: {
            bool: {
              must: [
                {term: {statusId: 'WeInPlanning'}}
              ]
            }
          }
        }
      }
    };

    if (team?.value) {
      params.query.bool.filter.bool.must.push({
        nested: {
          path: 'parties',
          query: {
            bool: {
              must: [
                {term: {'parties.partyId': team?.value}},
                {term: {'parties.partyRoleTypeId': 'OrgTeam'}}
              ]
            }
          }
        }
      });
    }
    if (highPriority) {
      params.query.bool.filter.bool.must.push(
        {term: {priority: 1}}
      );
    }

    TaskService.getElasticTasks(JSON.stringify(params)).then(({listData}) => {
      setInPlanningTasks(listData.map(item => {
        return item._source;
      }));
    });

    params.query.bool.filter.bool.must[0].term.statusId = 'WeInProgress';
    TaskService.getElasticTasks(JSON.stringify(params)).then(({listData}) => {
      setInProgressTasks(listData.map(item => {
        return item._source;
      }));
    });

    params.query.bool.filter.bool.must[0].term.statusId = 'WeOnHold';
    TaskService.getElasticTasks(JSON.stringify(params)).then(({listData}) => {
      setOnHoldTasks(listData.map(item => {
        return item._source;
      }));
    });
  }

  const searchTeams = event => {
    const _event: any = {
      filters: {partyName: {value: event.query, matchMode: 'contains'}}
    };
    TeamService.getList(JSON.stringify(_event)).then(({listData}) => {
      setTeams(listData.map(item => {
        return {value: item.partyId, label: item.partyName};
      }));
    });
  }

  const {renderCrupTask, doUpdateTask} = useCrupTask({me});

  const doView = workEffortId => {
    if (workEffortId) {
      TaskService.get(workEffortId).then(data => {
        doUpdateTask(workEffortId, data, true);
      });
    }
  }

  return (
    <Fragment>
      <div className="p-fluid formgrid grid mb-2">
        <div className="col-6">
          <AutoComplete value={team} suggestions={teams} completeMethod={searchTeams} onChange={e => setTeam(e.value)}
                        field="label" dropdown placeholder="Chọn nhóm"/>
        </div>
        <div className="col-6 pt-3">
          <div className="field-checkbox mb-0">
            <Checkbox inputId="highPriority" onChange={e => setHighPriority(e.checked)} checked={highPriority}></Checkbox>
            <label htmlFor="highPriority">Công việc Ưu tiên cao</label>
          </div>
        </div>
      </div>
      <div className="grid mt-3">
        <div className="col-4">
          <div className="flex align-items-center">
            <span className="bg-orange-500 text-0 flex align-items-center justify-content-center border-round-sm p-2" style={{width: '15rem', height: '2.5rem'}}>
              <i className="pi pi-replay text-xl mr-2"></i> Chưa xử lý
            </span>
            <div className="bg-orange-500" style={{height: '2px', width: 'calc(100% - 2rem)'}}></div>
          </div>
          <div className="grid mt-2" style={{maxHeight: 'calc(100vh - 17rem)', overflowY: 'auto'}}>
            {inPlanningTasks.map((task, index) => <div key={index} className="col-12">
              <TaskInfo task={task} doView={doView}/>
            </div>)}
          </div>
        </div>
        <div className="col-4">
          <div className="flex align-items-center">
            <span className="bg-blue-500 text-0 flex align-items-center justify-content-center border-round-sm p-2" style={{width: '15rem', height: '2.5rem'}}>
              <i className="pi pi-bolt text-xl mr-2"></i> Đang xử lý
            </span>
            <div className="bg-blue-500" style={{height: '2px', width: 'calc(100% - 2rem)'}}></div>
          </div>
          <div className="grid mt-2" style={{maxHeight: 'calc(100vh - 17rem)', overflowY: 'auto'}}>
            {inProgressTasks.map((task, index) => <div key={index} className="col-12">
              <TaskInfo task={task} doView={doView}/>
            </div>)}
          </div>
        </div>
        <div className="col-4">
          <div className="flex align-items-center">
            <span className="bg-purple-500 text-0 flex align-items-center justify-content-center border-round-sm p-2" style={{width: '15rem', height: '2.5rem'}}>
              <i className="pi pi-ban text-xl mr-2"></i> Đang chờ
            </span>
            <div className="bg-purple-500" style={{height: '2px', width: 'calc(100% - 2rem)'}}></div>
          </div>
          <div className="grid mt-2" style={{maxHeight: 'calc(100vh - 17rem)', overflowY: 'auto'}}>
            {onHoldTasks.map((task, index) => <div key={index} className="col-12">
              <TaskInfo task={task} doView={doView}/>
            </div>)}
          </div>
        </div>
      </div>

      {renderCrupTask()}
    </Fragment>
  );
}

export const TaskInfo = ({task, doView}) => {
  return (
    <div className="surface-card shadow-2 border-round p-3 flex-auto">
      <div className="mb-3">
        <span className="text-900 font-medium text-primary inline-block mr-3 pointer" title="Mã CV" onClick={() => doView(task.workEffortId)}>#{task.workEffortId}</span>
        {task.estimatedCompletionDate > Date.now() && <span className="text-sm alert-success" title="Ngày hoàn thành">
          <i className="pi pi-clock mr-2"></i>{FormatDisplay.dateTime(task.estimatedCompletionDate)}
        </span>}
        {task.estimatedCompletionDate <= Date.now() && <span className="text-sm alert-danger" title="Ngày hoàn thành">
          <i className="pi pi-history mr-2"></i>{FormatDisplay.dateTime(task.estimatedCompletionDate)}
        </span>}
        {task.priority && <Badge value={task.priority} severity="info" style={{float: 'right'}} title="Ưu tiên"></Badge>}
      </div>
      <div className="line-height-3 text-700 mb-2" title={`Công việc tạo bởi ${task.createdByPartyName}`}>{task.workEffortName}</div>
      <TaskProgression progression={task.taskProgression} percentComplete={task.percentComplete} className="mb-2"/>
      <div className="line-height-3 text-700 mb-3" dangerouslySetInnerHTML={{__html: task.description}}></div>
      <div className="text-500 flex align-items-center gap-4">
        {task.parties
          ?.filter(party => party.partyRoleTypeId === 'Assignee')
          ?.map((party, index) => <div key={index} className="flex align-items-center gap-1" title="Nhân viên">
            <div className="mr-3 w-2rem h-2rem iam-avatar">
              {party.partyId && <Avatar partyId={task.partyId}/>}
            </div>
            <span>{party.partyName}</span>
          </div>)}

        {task.parties
          ?.filter(party => party.partyRoleTypeId === 'OrgTeam')
          ?.map((party, index) => <div key={index} className="flex align-items-center gap-1" title="Nhóm">
            <i className="pi pi-users"></i><span>{party.partyName}</span>
          </div>)}
      </div>
    </div>
  );
}