import {Fragment, useEffect, useState} from 'react';

import {ProgressBar} from 'primereact/progressbar';

export const TaskProgression = ({progression, percentComplete, className}) => {

  const [progress, setProgress] = useState(null);

  useEffect(() => {
    if (progression) {
      try {
        const _progression = JSON.parse(progression);
        setProgress(_progression);
      } catch (e) {

      }
    }
  }, [progression]);

  const valueTemplate = () => {
    return (
      <Fragment>
        {progress?.resolved}/<b>{progress?.total}</b>
      </Fragment>
    );
  };

  return <Fragment>
    {progress?.total && <ProgressBar value={percentComplete} displayValueTemplate={valueTemplate} className={className}></ProgressBar>}
  </Fragment>
}