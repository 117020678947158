import {Service} from 'src/service/Service';

export class AggregatedTaskService extends Service {

  static entity = 'iam-work-effort';

  static getAggregatedTasks(params: string): Promise<any> {
    return this.axios.get(`${this.entity}/aggregated-tasks`, {params}).then(res => res.data);
  }

  static assign(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/aggregated-tasks/assign`, data).then(res => res.data);
  }

}
