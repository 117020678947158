import {useEffect, useState} from 'react';

import {Chart} from 'primereact/chart';
import {Period} from '@iamsoftware/react-hooks';

import {DashboardService as Service} from './DashboardService';
import {TeamService} from '../../he-thong/team-nhom/TeamService';

export const TasksOverview = ({iamElasticHasChange}) => {

  const [period, setPeriod] = useState(null);

  const [teams, setTeams] = useState([]);

  const [data, setData] = useState({});

  useEffect(() => {
    const subscription = iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('workEfforts') && data?.message?.workEffortTypeEnumIdSet?.includes('WetTask')) {
        load();
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const _event: any = {
      filters: {disabled: {value: 'N', matchMode: 'equals'}}
    };
    TeamService.getList(JSON.stringify(_event)).then(({listData}) => {
      setTeams(listData);
    });
  }, []);

  useEffect(() => {
    if (period) {
      load();
    }
  }, [period]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (teams?.length) {
      load();
    }
  }, [teams]); // eslint-disable-line react-hooks/exhaustive-deps

  const load = () => {
    if (period?.length === 2 && teams.length) {
      Service.getTasksOverview(period[0], period[1]).then(({aggregations}) => {
        const _data = {
          labels: [],
          datasets: []
        }
        teams.forEach(team => {
          _data.datasets.push({
            type: 'bar',
            key: team.partyId,
            label: team.partyName,
            data: []
          });
        });
        if (aggregations?.tasks_over_time?.buckets) {
          aggregations?.tasks_over_time?.buckets.forEach(bucket => {
            _data.labels.push(bucket.key_as_string);

            _data.datasets.forEach(dataset => {
              let statValue = 0;
              if (bucket.terms_by_parties?.buckets?.length) {
                bucket.terms_by_parties?.buckets.forEach(party => {
                  if (party.key === dataset.key) {
                    statValue = party.doc_count;
                  }
                });
              }
              dataset.data.push(statValue);
            });
          });
        }
        setData(_data);
      });
    }
  }

  return (
    <div className="card">
      <h5>Tổng quan công việc theo nhóm</h5>
      <div className="p-fluid formgrid grid mb-2">
        <div className="col-4">
          <Period defaultPeriod="THISFISCALYEAR" value={period} onChange={({value}) => setPeriod(value)}/>
        </div>
      </div>
      <Chart type="bar" data={data} options={options}/>
    </div>
  );
}
const documentStyle = getComputedStyle(document.documentElement);
const textColor = documentStyle.getPropertyValue('--text-color');
const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
const options = {
  maintainAspectRatio: false,
  aspectRatio: 0.8,
  plugins: {
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      labels: {
        color: textColor
      }
    }
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        color: textColorSecondary
      },
      grid: {
        color: surfaceBorder
      }
    },
    y: {
      stacked: true,
      ticks: {
        color: textColorSecondary
      },
      grid: {
        color: surfaceBorder
      }
    }
  }
};