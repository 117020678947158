import {useEffect, useState} from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import {EventService} from '../EventService';

import {useCrupEvent} from '../CrupEvent';
import {FormatDisplay} from '@iamsoftware/react-hooks';

export const EventCalendar = ({me, iamElasticHasChange}) => {

  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const [events, setEvents] = useState(null);

  useEffect(() => {
    const subscription = iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('workEfforts') && data?.message?.workEffortTypeEnumIdSet?.includes('WetEvent')) {
        load();
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    load();
  }, [start, end]); // eslint-disable-line react-hooks/exhaustive-deps

  const load = () => {
    if (start && end) {
      EventService.getFullCalendarData(start, end).then(data => {
        setEvents(data.listData.map(item => {
          switch (item.purposeEnumId) {
            case 'WepBirthDate':
              item.color = 'green';
              break;
            case 'WepEmploymentLeave':
              item.color = 'rgb(255, 159, 64)';
              break;
          }
          return item;
        }));
      });
    }
  }

  const datesSet = arg => {
    setStart(arg.start?.getTime());
    setEnd(arg.end?.getTime());
  }

  const {renderDialogCrupEvent, doCreateEvent, doUpdateEvent} = useCrupEvent({me, header: 'Sự kiện'});

  const eventClick = e => {
    doUpdateEvent({workEffortId: e.event.id.split('@')[0]}, true);
  }

  const dateClick = e => {
    doCreateEvent({purposeEnumId: 'WepTimesheet', statusId: 'WeApproved', estimatedStartDate: e.date});
  }

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card calendar-demo">
          <FullCalendar
            locale="vi"
            datesSet={datesSet}
            events={events}
            eventClick={eventClick}
            dateClick={dateClick}
            initialDate={FormatDisplay.date(new Date(), 'YYYY-MM-DD')}
            initialView="dayGridMonth"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{left: 'prev,next today', center: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay'}}
            selectable
            selectMirror
            dayMaxEvents/>
        </div>
      </div>

      {renderDialogCrupEvent()}
    </div>
  );
}