import {Service} from './Service';

export class CommonService extends Service {

  static entity: string = 'iam-common';

  static getProvinces(): Promise<any> {
    return this.axios.get(`${this.entity}/geos/provinces`).then(res => res.data);
  }

  static getCounties(stateProvinceGeoId: string): Promise<any> {
    return this.axios.get(`${this.entity}/geos/${stateProvinceGeoId}/assocs?toGeoTypeEnumId=GEOT_COUNTY`).then(res => res.data);
  }

  static getGeoByName(params: any): Promise<any> {
    return this.axios.get(`${this.entity}/geos/geo-by-name`, {params}).then(res => res.data);
  }

}
