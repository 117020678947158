import {Fragment, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';

import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Messages} from 'primereact/messages';

import {AuthService} from 'src/service/AuthService';

import {AppStorage, UserStorage} from 'src/service/UserStorage';

export default function Login() {

  const [formType, setFormType] = useState<string>('Login');

  const [hasOrgByOriginLocation, setHasOrgByOriginLocation] = useState(false);
  const [ownerPartyTaxId, setOwnerPartyTaxId] = useState('');

  useEffect(() => {
    getToken();
    AuthService.getOrgByOriginLocation(window.location.origin).then(data => {
      if (data.ownerPartyTaxId) {
        setHasOrgByOriginLocation(true);
        setOwnerPartyTaxId(data.ownerPartyTaxId);
      } else if (AppStorage.get('ownerPartyTaxId')) {
        setOwnerPartyTaxId(AppStorage.get('ownerPartyTaxId'));
      }
    });
  }, []);

  const getToken = () => {
    AuthService.getToken().then(({moquiSessionToken}) => {
      AppStorage.set('moquiSessionToken', moquiSessionToken);
    });
  }

  return (
    <div className="layout-wrapper layout-overlay layout-theme-light">

      <div className="layout-topbar">
        <Link to="/" className="layout-topbar-logo">
          <img src="/iam/IAM-TASK.png" alt="IAM-TASK"/>
        </Link>
      </div>

      <div className="layout-main-container login-page">
        <div className="layout-main">
          <div className="surface-ground py-8 flex align-items-center justify-content-center">

            <div className="surface-card p-4 shadow-2 border-round" style={{width: '35rem'}}>
              <div className="text-center mb-5">
                <div className="text-900 text-3xl font-medium mb-3">{formType === 'Login' ? 'Đăng nhập' : 'Quên mật khẩu'}</div>
                <span className="text-600 font-medium line-height-3">IAM Software Vietnam - Quản lý công việc</span>
              </div>

              {formType === 'Login' && <LoginForm hasOrgByOriginLocation={hasOrgByOriginLocation} ownerPartyTaxId={ownerPartyTaxId} setOwnerPartyTaxId={setOwnerPartyTaxId}
																									getToken={getToken} setFormType={setFormType}/>}
              {formType === 'ResetPassword' && <ResetPasswordForm hasOrgByOriginLocation={hasOrgByOriginLocation} ownerPartyTaxId={ownerPartyTaxId} setOwnerPartyTaxId={setOwnerPartyTaxId}
																																	getToken={getToken} setFormType={setFormType}/>}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const LoginForm = ({hasOrgByOriginLocation, ownerPartyTaxId, setOwnerPartyTaxId, getToken, setFormType}) => {

  const message = useRef(null);

  const [submitting, setSubmitting] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = event => {
    if (!submitting) {
      setSubmitting(true);

      AuthService.login(`${ownerPartyTaxId}_${username}`, password).then(data => {
        if (data.isSuccess === 'Y') {
          AppStorage.set('ownerPartyTaxId', ownerPartyTaxId);
          AppStorage.set('moquiSessionToken', data.moquiSessionToken);
          UserStorage.set('showHappyBirthday', 'Y');
          window.location.hash = '/';
        } else {
          data?.errors.forEach(error => {
            message.current.show({severity: 'error', content: error, sticky: true});
          });
          getToken();
        }
      }).catch(getToken).finally(() => {
        setSubmitting(false);
      });
    }
    event.preventDefault();
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        {!hasOrgByOriginLocation && <Fragment>
					<label htmlFor="partyTaxId" className="block text-900 font-medium mb-2">Mã số thuế</label>
					<InputText id="partyTaxId" className="w-full mb-3" value={ownerPartyTaxId} onChange={e => setOwnerPartyTaxId(e.target.value)} required/>
				</Fragment>}

        <label htmlFor="username" className="block text-900 font-medium mb-2">Tài khoản</label>
        <InputText id="username" className="w-full mb-3" value={username} onChange={e => setUsername(e.target.value)} required/>

        <label htmlFor="password" className="block text-900 font-medium mb-2">Mật khẩu</label>
        <Password id="password" className="w-full mb-3" value={password} onChange={e => setPassword(e.target.value)} toggleMask feedback={false} required/>

        <Messages ref={message}/>

        <Button label="Đăng nhập" icon="pi pi-user" className="w-full" loading={submitting}/>
      </form>
      <div className="grid mt-2">
        <div className="col-12 text-right">
          <span className="text-primary pointer" onClick={() => setFormType('ResetPassword')}>Quên mật khẩu?</span>
        </div>
      </div>
    </Fragment>
  );
}

const ResetPasswordForm = ({hasOrgByOriginLocation, ownerPartyTaxId, setOwnerPartyTaxId, getToken, setFormType}) => {

  const message = useRef(null);

  const [submitting, setSubmitting] = useState(false);

  const [username, setUsername] = useState('');
  const [emailAddress, setEmailAddress] = useState('');

  const handleSubmit = event => {
    if (!submitting) {
      setSubmitting(true);

      AuthService.resetPassword(`${ownerPartyTaxId}_${username}`, emailAddress).then(() => {
        message.current.show({severity: 'success', content: 'Đặt lại mật khẩu thành công. Quý khách vui lòng kiểm tra Hộp thư đến email.', sticky: true});
      }).catch(getToken).finally(() => {
        setSubmitting(false);
      });
    }
    event.preventDefault();
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        {!hasOrgByOriginLocation && <Fragment>
					<label htmlFor="partyTaxId" className="block text-900 font-medium mb-2">Mã số thuế</label>
					<InputText id="partyTaxId" className="w-full mb-3" value={ownerPartyTaxId} onChange={e => setOwnerPartyTaxId(e.target.value)} required/>
				</Fragment>}

        <label htmlFor="username" className="block text-900 font-medium mb-2">Tài khoản</label>
        <InputText id="username" className="w-full mb-3" value={username} onChange={e => setUsername(e.target.value)} required/>

        <label htmlFor="emailAddress" className="block text-900 font-medium mb-2">Email</label>
        <InputText id="emailAddress" className="w-full mb-3" value={emailAddress} onChange={e => setEmailAddress(e.target.value)} required/>

        <Messages ref={message}/>

        <Button label="Lấy lại mật khẩu" icon="pi pi-history" className="w-full" severity="warning" loading={submitting}/>
      </form>
      <div className="grid mt-2">
        <div className="col-12">
          <span className="text-primary pointer" onClick={() => setFormType('Login')}><i className="pi pi-arrow-left mr-2"></i>Quay lại</span>
        </div>
      </div>
    </Fragment>
  );
}