import {useEffect, useState} from 'react';

import {Tooltip} from 'primereact/tooltip';

import {FormatDisplay, ToastService, useDialogCrup} from '@iamsoftware/react-hooks';

import {EventService as Service} from './EventService';
import {TaskService} from '../cong-viec/danh-sach/TaskService';
import {TeamService} from '../he-thong/team-nhom/TeamService';
import {UserService} from '../he-thong/nhan-vien/UserService';
import {ClientService} from '../danh-muc/khach-hang/ClientService';

import {cronOptions, convertToCron} from 'src/shared/utils/Cron';

export const useCrupEvent = ({me, header}) => {

  const dataKey = 'workEffortId';

  const [teams, setTeams] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [clients, setClients] = useState([]);

  const searchTeams = event => {
    const _event: any = {
      filters: {partyName: {value: event.query, matchMode: 'contains'}}
    };
    TeamService.getList(JSON.stringify(_event)).then(({listData}) => {
      setTeams(listData.map(item => {
        return {value: item.partyId, label: item.partyName};
      }));
    });
  }

  const searchEmployees = event => {
    UserService.find(event.query).then(data => {
      setEmployees(data.listData.map(item => {
        return {value: item.partyId, label: `[${item.username.replace(`${me.ownerTaxId}_`, '')}] - ${item.partyName}`};
      }));
    });
  }

  const searchTasks = event => {
    const params: any = {
      size: 200,
      sort: [{priority: 'asc'}, {estimatedCompletionDate: 'asc'}],
      query: {
        bool: {
          filter: {
            bool: {
              must: [
                {terms: {statusId: ['WeInPlanning', 'WeApproved', 'WeInProgress', 'WeOnHold']}}
              ]
            }
          }
        }
      }
    };
    if (event.query) {
      params.query.bool.filter.bool.must.push({
        match: {workEffortName: event.query}
      });
    }
    TaskService.getElasticTasks(JSON.stringify(params)).then(({listData}) => {
      setTasks(listData.map(item => {
        return {value: item._source.workEffortId, label: item._source.workEffortName};
      }));
    });
  }

  const searchClients = event => {
    ClientService.find(event.query).then(data => {
      setClients(data.listData.map(item => {
        return {value: item.partyId, label: `[${item.partyTaxId}] - ${item.partyName}`};
      }));
    });
  }

  const {render: renderDialogCrup, create, view, update, form} = useDialogCrup({
    header,
    dataKey,
    width: '60rem',
    fields: [
      {field: 'purposeEnumId', type: 'hidden'},
      {field: 'statusId', type: 'hidden'},
      {
        field: 'workEffortName', header: 'Sự kiện', required: true, className: 'md:col-12',
        displayDependency(item: any): boolean {
          return ['WepTimesheet', 'WepBirthDate'].includes(item.purposeEnumId);
        }
      },
      {
        field: 'description', header: 'Mô tả', type: 'InputTextarea', className: 'md:col-12',
        displayDependency(item: any): boolean {
          return ['WepTimesheet'].includes(item.purposeEnumId);
        }
      },
      {
        field: 'estimatedStartDate', header: 'Ngày bắt đầu', required: true, InputTextProps: {type: 'datetime-local'}, className: 'md:col-6',
        displayDependency(item: any): boolean {
          return ['WepTimesheet'].includes(item.purposeEnumId);
        }
      },
      {
        field: 'estimatedStartDate', header: 'Ngày', required: true, InputTextProps: {type: 'datetime-local'}, className: 'md:col-6',
        displayDependency(item: any): boolean {
          return ['WepBirthDate'].includes(item.purposeEnumId);
        }
      },
      {field: 'recurStartCron', type: 'hidden'},
      {
        field: 'recurCronOption', header: 'Chu kỳ', type: 'Dropdown', DropdownProps: {options: cronOptions, className: 'cron-expression'}, className: 'md:col-6',
        displayDependency(item: any): boolean {
          return ['WepTimesheet'].includes(item.purposeEnumId);
        }
      },
      {
        type: 'template', template(item: any): any {
          if (item.recurStartCron) {
            return <Tooltip target=".cron-expression" event="hover" position="bottom">
              {item.recurStartCron}
            </Tooltip>
          }
          return '';
        }, className: 'md:col-12 m-0'
      },
      // {
      //   field: 'recurStartCron', header: 'Chu kỳ', InputTextProps: {className: 'cron-expression'} as any, className: 'md:col-6',
      //   displayDependency(item: any): boolean {
      //     return ['WepTimesheet'].includes(item.purposeEnumId);
      //   }
      // },
      // {
      //   type: 'custom', body: <Tooltip target=".cron-expression" event="focus" position="bottom">
      //     <QuartzCronTooltipContent/>
      //   </Tooltip>, className: 'md:col-12 m-0'
      // },
      {
        field: 'teams', header: 'Nhóm', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: teams, completeMethod: searchTeams, field: 'label', dropdown: true, multiple: true}, className: 'md:col-12',
        displayDependency(item: any): boolean {
          return ['WepTimesheet'].includes(item.purposeEnumId);
        }
      },
      {
        field: 'employees', header: 'Nhân viên', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: employees, completeMethod: searchEmployees, field: 'label', dropdown: true, multiple: true}, className: 'md:col-12',
        displayDependency(item: any): boolean {
          return ['WepTimesheet', 'WepEmploymentLeave', 'WepBirthDate'].includes(item.purposeEnumId);
        }
      },
      {
        field: 'estimatedStartDate', header: 'Từ ngày', required: true, InputTextProps: {type: 'date'}, className: 'md:col-6',
        displayDependency(item: any): boolean {
          return ['WepEmploymentLeave'].includes(item.purposeEnumId);
        }
      },
      {
        field: 'estimatedCompletionDate', header: 'Đến ngày', required: true, InputTextProps: {type: 'date'}, className: 'md:col-6',
        displayDependency(item: any): boolean {
          return ['WepEmploymentLeave'].includes(item.purposeEnumId);
        }
      },
      {
        field: 'estimatedWorkDuration', header: 'Số ngày nghỉ', required: true, type: 'InputNumber', InputNumberProps: {maxFractionDigits: 1}, className: 'md:col-12',
        displayDependency(item: any): boolean {
          return ['WepEmploymentLeave'].includes(item.purposeEnumId);
        }
      },
      {
        field: 'description', header: 'Lý do', required: true, type: 'InputTextarea', className: 'md:col-12',
        displayDependency(item: any): boolean {
          return ['WepEmploymentLeave'].includes(item.purposeEnumId);
        }
      },
      {
        field: 'employees', header: 'Nhân viên', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: employees, completeMethod: searchEmployees, field: 'label', dropdown: true}, className: 'md:col-12',
        displayDependency(item: any): boolean {
          return ['WepOvertime'].includes(item.purposeEnumId);
        }
      },
      {
        field: 'estimatedStartDate', header: 'Từ giờ', required: true, InputTextProps: {type: 'datetime-local'}, className: 'md:col-4',
        displayDependency(item: any): boolean {
          return ['WepOvertime'].includes(item.purposeEnumId);
        }
      },
      {
        field: 'estimatedCompletionDate', header: 'Đến giờ', required: true, InputTextProps: {type: 'datetime-local'}, className: 'md:col-4',
        displayDependency(item: any): boolean {
          return ['WepOvertime'].includes(item.purposeEnumId);
        }
      },
      {
        field: 'estimatedWorkDuration', header: 'Số giờ', required: true, type: 'InputNumber', InputNumberProps: {maxFractionDigits: 1}, className: 'md:col-4',
        displayDependency(item: any): boolean {
          return ['WepOvertime'].includes(item.purposeEnumId);
        }
      },
      {
        field: 'taskRelates', header: 'Công việc', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: tasks, completeMethod: searchTasks, field: 'label', dropdown: true, multiple: true}, className: 'md:col-12',
        displayDependency(item: any): boolean {
          return ['WepOvertime'].includes(item.purposeEnumId);
        }
      },
      {
        field: 'clients', header: 'Khách hàng', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: clients, completeMethod: searchClients, field: 'label', dropdown: true, multiple: true}, className: 'md:col-12',
        displayDependency(item: any): boolean {
          return ['WepOvertime'].includes(item.purposeEnumId);
        }
      },
      {
        field: 'description', header: 'Ghi chú', type: 'InputTextarea', className: 'md:col-12',
        displayDependency(item: any): boolean {
          return ['WepOvertime'].includes(item.purposeEnumId);
        }
      }
    ],
    createItem: item => {
      return TaskService.checkRecurStartCron(item).then(injectByPurposeEnumId).catch(err => {
        ToastService.error(err);
      }).then(item => Service.create(inject(item)));
    },
    updateItem: (id, item) => {
      return TaskService.checkRecurStartCron(item).then(injectByPurposeEnumId).catch(err => {
        ToastService.error(err);
      }).then(item => Service.update(id, inject(item)));
    }
  });

  const injectByPurposeEnumId = item => {
    return new Promise((resolve, reject) => {
      switch (item.purposeEnumId) {
        case 'WepEmploymentLeave':
          if (item.employees?.length) {
            item.workEffortName = item.employees?.map(employee => employee.label)?.join(', ') + ' nghỉ phép';
          } else {
            reject('Nhân viên không được để trống.');
          }
          break;
        case 'WepOvertime':
          item.workEffortName = item.employees?.label + ' làm thêm giờ';
          item.employees = [item.employees.value];
          break;
        default:
          if (item.employees?.length) {
            item.employees = item.employees.map(employee => employee.value);
          }
      }
      resolve(item);
    });
  }

  const inject = item => {
    if (item.teams?.length) {
      item.teams = item.teams.map(team => team.value);
    }

    if (item.taskRelates?.length) {
      item.taskRelates = item.taskRelates.map(team => team.value);
    }
    if (item.clients?.length) {
      item.clients = item.clients.map(team => team.value);
    }
    return item;
  }

  const {estimatedStartDate, recurCronOption} = form.getValue();
  useEffect(() => {
    let _recurStartCron = '';
    if (estimatedStartDate && recurCronOption) {
      _recurStartCron = convertToCron(recurCronOption, new Date(1 * estimatedStartDate));
    }
    const _value = form.getRawValue();
    _value.recurStartCron = _recurStartCron;
    form.setValue(_value);
  }, [estimatedStartDate, recurCronOption]); // eslint-disable-line react-hooks/exhaustive-deps

  const doCreate = (defaultValue?: any) => {
    const format = ['WepEmploymentLeave'].includes(defaultValue?.purposeEnumId) ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm';

    defaultValue.estimatedStartDate = FormatDisplay.date(new Date(defaultValue?.estimatedStartDate || Date.now()), format);
    if (defaultValue?.estimatedCompletionDate) {
      defaultValue.estimatedCompletionDate = FormatDisplay.date(new Date(defaultValue?.estimatedCompletionDate || Date.now()), format);
    }
    create(defaultValue);
  }

  const doUpdate = (item: any, doView?: boolean) => {
    if (item && item[dataKey]) {

      Service.get(item[dataKey]).then(data => {

        const format = ['WepEmploymentLeave'].includes(data?.purposeEnumId) ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm';

        if (data.estimatedStartDate) {
          data.estimatedStartDate = FormatDisplay.date(data.estimatedStartDate, format);
        }
        if (data.estimatedCompletionDate) {
          data.estimatedCompletionDate = FormatDisplay.date(data.estimatedCompletionDate, format);
        }

        if (data.teams) {
          data.teams = data.teams.map(item => {
            return {value: item.partyId, label: item.partyName}
          });
        }
        if (data.employees) {
          switch (data.purposeEnumId) {
            case 'WepOvertime':
              data.employees = data.employees.map(item => {
                return {value: item.partyId, label: item.partyName};
              })[0];
              break;
            default:
              data.employees = data.employees.map(item => {
                return {value: item.partyId, label: item.partyName};
              });
          }
        }
        if (data.clients) {
          data.clients = data.clients.map(item => {
            return {value: item.partyId, label: item.partyName}
          });
        }
        if (data.taskRelates) {
          data.taskRelates = data.taskRelates.map(item => {
            return {value: item.workEffortId, label: item.workEffortName};
          });
        }

        if (doView === true) {
          view(data);
        } else {
          update(data);
        }
      });
    }
  }

  return {renderDialogCrupEvent: renderDialogCrup, doCreateEvent: doCreate, doUpdateEvent: doUpdate, formEvent: form}
}