import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {ToastService, useDataTableBasic} from '@iamsoftware/react-hooks';

import {AuthService} from 'src/service/AuthService';
import {ServiceService} from '../danh-muc/dich-vu/ServiceService';

export const ConfigMenus = () => {
  return (
    <Fragment>
      <ConfigMenu preferenceKey="ConfigMenu_ResolvedTask_report" title="Báo cáo kết quả"/>
      <ConfigMenu preferenceKey="ConfigMenu_ResolvedMinutes_report" title="Biên bản"/>
    </Fragment>
  );
}

const ConfigMenu = ({preferenceKey, title}) => {

  const dataKey = 'productId';

  const [submitting, setSubmitting] = useState(false);
  const [items, setItems] = useState([]);

  const [classes, setClasses] = useState([]);

  useEffect(() => {
    const _event: any = {
      filters: {statusId: {value: 'ProdActive', matchMode: 'equals'}}
    };
    ServiceService.getList(JSON.stringify(_event)).then(({listData}) => {
      listData.forEach(item => {
        item.applAsValue = item.productName;
      });
      AuthService.getPreference(preferenceKey).then(({preferenceValue}) => {
        setItems(listData.map(item => {
          let savedMenus = [];
          if (preferenceValue) {
            try {
              savedMenus = JSON.parse(preferenceValue);
            } catch (e) {
            }
          }
          for (const savedMenu of savedMenus) {
            if (savedMenu[dataKey] === item[dataKey]) {
              item.applAsValue = savedMenu.applAsValue;
              item.appl = true;
              break;
            }
          }
          return item;
        }));
      });
    });

    ServiceService.getClasses().then(({listData}) => {
      setClasses(listData.map(item => {
        return {value: item.enumId, label: item.description};
      }));
    });
  }, []);

  const onEditorChange = (rowData, column, newValue) => {
    setItems(prevItems => prevItems.map(prevItem => {
      if (prevItem[dataKey] === rowData[dataKey]) {
        return {
          ...prevItem,
          [column.field]: newValue
        }
      }
      return prevItem;
    }));
  }

  const {render: renderDataTable} = useDataTableBasic({
    tableHeader: 'Dịch vụ',
    dataKey,
    indexColumnWidth: 45,
    columns: [
      {field: 'productClassEnumId', header: 'Loại biểu mẫu', width: 200, filterOptions: classes, dataType: 'fromFilterOptions'},
      {field: 'pseudoId', header: 'Mã dịch vụ', width: 250},
      {field: 'productName', header: 'Tên dịch vụ', minWidth: 250},
      {field: 'applAsValue', header: 'Tiêu đề menu', minWidth: 150, dataType: 'editor', editorConfig: {onEditorChange}},
      {field: 'appl', header: 'Áp dụng', width: 120, dataType: 'editor', editorConfig: {onEditorChange, field: {type: 'Checkbox'}}, className: 'text-center'}
    ],
    items: items
  });

  const onSubmit = () => {
    if (!submitting) {
      setSubmitting(true);
      let _preferenceValue = '';
      if (items?.length) {
        _preferenceValue = JSON.stringify(items.filter(item => item.appl).map(item => {
          return {
            productId: item.productId,
            applAsValue: item.applAsValue
          }
        }));
      }
      AuthService.setPreference(preferenceKey, _preferenceValue).then(() => {
        ToastService.success();
      });
    }
  }

  return (
    <div className="grid">
      <div className="col-12">
        <p>Hiển thị Menu <b style={{color: 'var(--primary-color)'}}>{title}</b> với các dịch vụ:</p>
      </div>
      <div className="col-12">
        {renderDataTable()}
      </div>
      <div className="col-12 text-right">
        <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit} loading={submitting}/>
      </div>
    </div>
  );
}