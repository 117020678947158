import {Service} from 'src/service/Service';

export class OrganizationService extends Service {

  static entity = 'iam-system/organization';

  static get(): Promise<any> {
    return this.axios.get(`${this.entity}`).then(res => res.data);
  }

  static set(data: any): Promise<any> {
    return this.axios.put(`${this.entity}`, data).then(res => res.data);
  }

  static getContentText(partyContentTypeEnumId: string): Promise<any> {
    return this.axios.get(`${this.entity}/content-text`, {params: {partyContentTypeEnumId}}).then(res => res.data);
  }

  static setContentText(partyContentTypeEnumId: string, contentText: string): Promise<any> {
    return this.axios.put(`${this.entity}/content-text`, {partyContentTypeEnumId, contentText}).then(res => res.data);
  }

  static setLogo(contentFile: any): Promise<any> {
    return this.axios.post(`${this.entity}/logo`, this.toFormData({contentFile})).then(res => res.data);
  }

}
