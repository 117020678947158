import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';

import {ClientService as Service} from './ClientService';
import {ZaloNotificationService} from '../../tai-khoan/zalo-notification/ZaloNotificationService';
import {ZaloFollowStatus} from '../../tai-khoan/zalo-notification/ZaloFollowStatus';

export const ClientZaloFollowStatus = ({display, setDisplay}) => {

  const header = `Thông báo Zalo - ${display?.partyName}`;

  const [officialAccount, setOfficialAccount] = useState(null);
  const [followStatus, setFollowStatus] = useState(null);

  useEffect(() => {
    ZaloNotificationService.getOfficialAccount().then(data => {
      setOfficialAccount(data);
    });
  }, []);

  useEffect(() => {
    if (officialAccount?.zoaId && display?.partyId) {
      Service.getZaloFollowStatus(officialAccount.zoaId, display.partyId).then(data => {
        setFollowStatus(data);
      });
    }
  }, [officialAccount, display]);

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '72rem'}} position="top" onHide={() => setDisplay(false)}>
      <ZaloFollowStatus party={display} officialAccount={officialAccount} followStatus={followStatus}/>
    </Dialog>
  );
}