interface Props {
  me?: any
  party?: any

  officialAccount: any
  followStatus: any
}

export const ZaloFollowStatus = (props: Props) => {

  const {me, party, officialAccount, followStatus} = props;

  return (
    <div className="grid">
      <div className="col-12">
        {!!followStatus?.userId &&
					<div className="p-message p-component p-message-success p-message-enter-done">
						<div className="p-message-wrapper">
							<span className="p-message-detail">{me ? 'Bạn' : 'Khách hàng'} đã đăng ký nhận tin nhắn thông báo Zalo</span>
						</div>
					</div>
        }
        {!followStatus?.userId &&
					<div className="p-message p-component p-message-warn p-message-enter-done">
						<div className="p-message-wrapper">
							<span className="p-message-detail">{me ? 'Bạn' : 'Khách hàng'} chưa đăng ký nhận tin nhắn thông báo Zalo</span>
						</div>
					</div>
        }
        <div className="grid mt-3">
          <div className="col-8">
            <div className="text-900 font-bold text-4xl mb-3">Đăng ký nhận thông báo Zalo</div>
            <div className="text-700 line-height-3 mb-5">Áp dụng cho khách hàng chưa đăng ký hoặc muốn nhận thông báo trên một tài khoản Zalo khác.</div>
            <div className="mb-5 flex">
              <div className="flex flex-column align-items-center" style={{width: '2rem'}}>
                <span className="bg-cyan-600 text-white flex align-items-center justify-content-center border-circle shadow-2" style={{minWidth: '2.5rem', minHeight: '2.5rem', border: '2px solid #ffffff'}}>
                  <b>1</b>
                </span>
                <div className="h-full bg-cyan-500" style={{width: '2px', minHeight: '4rem'}}></div>
              </div>
              <div className="ml-3">
                <div className="font-medium text-900 mb-2">Quan tâm Zalo Official Account</div>
                <p className="line-height-3 text-700">
                  - Mở Zalo {'>'} Chọn vào biểu tượng QR Code tại góc trên bên phải màn hình.
                </p>
                <p className="line-height-3 text-700">
                  - Di chuyển camera đến mã QR phía bên phải để quét.
                </p>
                <p className="line-height-3 text-700">
                  - Ấn Quan tâm Official Account.
                </p>
              </div>
            </div>
            <div className="mb-5 flex">
              <div className="flex flex-column align-items-center" style={{width: '2rem'}}>
                <span className="bg-orange-500 text-white flex align-items-center justify-content-center border-circle shadow-2" style={{minWidth: '2.5rem', minHeight: '2.5rem', border: '2px solid #ffffff'}}>
                  <b>2</b>
                </span>
                <div className="h-full bg-orange-500" style={{width: '2px', minHeight: '4rem'}}></div>
              </div>
              <div className="ml-3">
                <div className="font-medium text-900 mb-2">Gửi tin nhắn Đăng ký nhận thông báo cho Official Account</div>
                <p className="line-height-3 text-700">
                  - Gửi tin nhắn theo cú pháp: <b><b>DKTB {me?.partyId || party?.partyId}</b></b>
                </p>
                <p className="line-height-3 text-700">
                  <i>(DKTB MaSo)</i>
                </p>
                <p className="line-height-3 text-700 text-orange-500">
                  <i>Để huỷ đăng ký nhận thông báo, quý khách gửi tin nhắn <b>HUY DKTB {me?.partyId || party?.partyId}</b><br/>(HUY DKTB MaSo)</i>
                </p>
              </div>
            </div>
          </div>
          <div className="col-4 text-center">
            {officialAccount?.zoaId &&
							<img src={`https://chart.googleapis.com/chart?cht=qr&chl=https://zalo.me/${officialAccount.zoaId}&chs=160x160&chld=L|0`} alt={officialAccount.appName}/>
            }
          </div>
        </div>

      </div>
    </div>
  );
}