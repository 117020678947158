import {Service} from './Service';

export class AuthService extends Service {

  static entity: string = 'iam-auth';

  static getOrgByOriginLocation(originLocation: string): Promise<any> {
    return this.axios.get(`${this.entity}/org-by-origin-location`, {params: {originLocation}}).then(res => res.data);
  }

  static login(username: string, password: string): Promise<any> {
    return this.axios.post(`${this.entity}/login`, {username, password}).then(res => res.data);
  }

  static changePassword(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/change-password`, data).then(res => res.data);
  }

  static logout(): Promise<any> {
    return this.axios.post(`${this.entity}/logout`).then(res => res.data);
  }

  static getToken(): Promise<any> {
    return this.axios.get(`${this.entity}/token`).then(res => res.data);
  }

  static getApiKey(): Promise<any> {
    return this.axios.get(`${this.entity}/api-key`).then(res => res.data);
  }

  static resetPassword(username: string, emailAddress: string): Promise<any> {
    return this.axios.put(`${this.entity}/reset-password`, {username, emailAddress}).then(res => res.data);
  }

  static getMe(includeDetailInfo?: string): Promise<any> {
    return this.axios.get(`${this.entity}/me`, {params: {includeDetailInfo}}).then(res => res.data);
  }

  static getPermissions(): Promise<any> {
    return this.axios.get(`${this.entity}/permissions`).then(res => res.data);
  }

  static getPreference(preferenceKey: string): Promise<any> {
    return this.axios.get(`${this.entity}/get-preference`, {params: {preferenceKey}}).then(res => res.data);
  }

  static setPreference(preferenceKey: string, preferenceValue: string): Promise<any> {
    return this.axios.put(`${this.entity}/set-preference`, {preferenceKey, preferenceValue}).then(res => res.data);
  }

}
