import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {ToastService, useDataTableBasic} from '@iamsoftware/react-hooks';

import {OrganizationService} from './OrganizationService';

const typeEnumId = 'AppMenu_LabelConfig';

export const ConfigAppMenu = () => {

  const dataKey = 'dataKey';

  const [submitting, setSubmitting] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    OrganizationService.getContentText(typeEnumId).then(({contentText}) => {
      let _items;
      try {
        _items = JSON.parse(contentText);
      } catch (e) {
      }
      if (_items?.length) {
        setItems(_items);
      } else {
        setItems([
          {dataKey: 'ProdServiceManagement', originalTitle: 'Quản lý dịch vụ'}
        ]);
      }
    });
  }

  const onEditorChange = (rowData, column, newValue) => {
    setItems(prevItems => prevItems.map(prevItem => {
      if (prevItem[dataKey] === rowData[dataKey]) {
        return {
          ...prevItem,
          [column.field]: newValue
        }
      }
      return prevItem;
    }));
  }

  const {render: renderDataTable} = useDataTableBasic({
    tableHeader: 'Menu',
    dataKey,
    indexColumnWidth: 45,
    columns: [
      {field: 'dataKey', header: 'Mã menu', width: 250},
      {field: 'originalTitle', header: 'Tiêu đề gốc', minWidth: 250},
      {field: 'applAsValue', header: 'Tiêu đề điều chỉnh', minWidth: 150, dataType: 'editor', editorConfig: {onEditorChange}}
    ],
    items: items
  });

  const onSubmit = () => {
    if (!submitting) {
      setSubmitting(true);

      OrganizationService.setContentText(typeEnumId, JSON.stringify(items)).then(() => {
        load();
        ToastService.success();
      });
    }
  }

  return (
    <div className="grid">
      <div className="col-12">
        {renderDataTable()}
      </div>
      <div className="col-12 text-right">
        <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit} loading={submitting}/>
      </div>
    </div>
  );
}