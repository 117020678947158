import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {ToastService, useDataTable, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {IamPermission} from 'src/shared/utils/Permission';

import {ServiceService as Service} from './ServiceService';

import {ServiceTemplates} from './ServiceTemplates';

const statuses = [
  {value: 'ProdActive', label: 'Đang hoạt động'},
  {value: 'ProdInactive', label: 'Ngừng hoạt động'}
];

export const Services = () => {

  const header = 'Dịch vụ';
  const dataKey = 'productId';

  const [statusId, setStatusId] = useState(statuses[0].value);

  const [requiredParams, setRequiredParams] = useState(null);

  const [readOnly, setReadOnly] = useState(false);
  const [classes, setClasses] = useState([]);
  const [features, setFeatures] = useState([]);

  const [displayTemplates, setDisplayTemplates] = useState(null);

  useEffect(() => {
    Service.getClasses().then(({listData}) => {
      setClasses(listData.map(item => {
        return {value: item.enumId, label: item.description};
      }));
    });
    Service.getFeatures().then(({listData}) => {
      setFeatures(listData);
    });
  }, []);

  useEffect(() => {
    if (statusId) {
      setRequiredParams({statusId: {value: statusId, matchMode: 'equals'}});
    } else {
      setRequiredParams({});
    }
  }, [statusId]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: header,
    dataKey,
    columns: [
      {field: 'pseudoId', header: 'Mã dịch vụ', width: 250, matchMode: 'contains'},
      {field: 'productName', header: 'Tên dịch vụ', minWidth: 250, matchMode: 'contains'},
      {field: 'productClassEnumId', header: 'Loại biểu mẫu', width: 200, matchMode: 'equals', filterType: 'dropdown', filterOptions: classes, dataType: 'fromFilterOptions'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  const onEditorChange = (rowData, column, newValue) => {
    setFeatures(prevFeatures => prevFeatures.map(prevFeature => {
      if (prevFeature.productFeatureId === rowData.productFeatureId) {
        return {
          ...prevFeature,
          [column.field]: newValue
        }
      }
      return prevFeature;
    }));
  }

  const {render: renderDataTableFeatures, selectedItems: selectedFeatures, setSelectedItems: setSelectedFeatures} = useDataTableBasic({
    tableHeader: 'Trường yêu cầu',
    dataKey: 'productFeatureId',
    indexColumnWidth: 45,
    columns: [
      {field: 'description', header: 'Trường', minWidth: 150},
      {field: 'applAsValue', header: 'Áp dụng', minWidth: 150, dataType: 'editor', editorConfig: {onEditorChange, readOnly}}
    ],
    items: features,
    selectionMode: 'checkbox'
  });

  const {render: renderDialogCrup, create, view, update} = useDialogCrup({
    header,
    dataKey,
    width: '60rem',
    fields: [
      {field: 'pseudoId', header: 'Mã dịch vụ', required: true, className: 'md:col-12'},
      {field: 'productName', header: 'Tên dịch vụ', required: true, className: 'md:col-12'},
      {
        field: 'productClassEnumId', header: 'Loại biểu mẫu', required: true, type: 'Dropdown', DropdownProps: {options: classes}, className: 'md:col-12'
      },
      {type: 'custom', body: renderDataTableFeatures()}
    ],
    createItem: item => {
      return Service.create(inject(item));
    },
    updateItem: (id, item) => {
      return Service.update(id, inject(item));
    },
    reloadLazyData
  });

  const inject = item => {
    if (selectedFeatures?.length) {
      const serviceFeatures = [];
      selectedFeatures.forEach(selectedFeature => {
        for (const feature of features) {
          if (feature.productFeatureId === selectedFeature.productFeatureId) {
            serviceFeatures.push(feature);
            break;
          }
        }
      });
      item.serviceFeatures = serviceFeatures;
    }
    return item;
  }

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doCreate = () => {
    setFeatures(prevFeatures => prevFeatures.map(prevFeature => {
      prevFeature.applAsValue = prevFeature.description;
      return prevFeature;
    }));
    setSelectedFeatures(null);
    setReadOnly(false);
    create();
  }

  const doUpdate = (doView?: boolean) => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.get(selectedItem[dataKey]).then(data => {

        let _selectedFeatures = [];
        setFeatures(prevFeatures => prevFeatures.map(prevFeature => {
          prevFeature.applAsValue = prevFeature.description;

          for (const serviceFeature of data.serviceFeatures) {
            if (serviceFeature.productFeatureId === prevFeature.productFeatureId) {
              _selectedFeatures.push(prevFeature);
              prevFeature.applAsValue = serviceFeature.applAsValue;
              break;
            }
          }

          return prevFeature;
        }));
        setSelectedFeatures(_selectedFeatures);

        if (doView === true) {
          setReadOnly(true);
          view(selectedItem);
        } else {
          setReadOnly(false);
          update(selectedItem);
        }
      });
    }
  }

  const doDisable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.disable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }
  const doEnable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.enable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate: IamPermission.has('CREATE') && doCreate,
    hasSelectedItem: selectedItem,
    doUpdate: IamPermission.has('UPDATE') && doUpdate,
    leftButtons: <Fragment>
      {IamPermission.has('UPDATE') && <Fragment>
        {statusId === statuses[0].value && <Button label="Khoá" icon="pi pi-lock" severity="danger" size="small" onClick={doDisable} disabled={!selectedItem}/>}
        {statusId === statuses[1].value && <Button label="Mở khoá" icon="pi pi-lock" severity="success" size="small" onClick={doEnable} disabled={!selectedItem}/>}
      </Fragment>}
      <Button label="Biểu mẫu" icon="pi pi-file-word" severity="help" size="small" onClick={() => setDisplayTemplates(selectedItem[dataKey])} disabled={!selectedItem}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 17.48rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrup()}

      <ServiceTemplates display={displayTemplates} setDisplay={setDisplayTemplates}/>
    </div>
  );
}