import {Fragment, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {FileTableViewer, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {ResolvedTaskService as Service} from './ResolvedTaskService';
import {TaskService} from '../cong-viec/danh-sach/TaskService';
import {ServiceService} from '../danh-muc/dich-vu/ServiceService';
import {ClientService} from '../danh-muc/khach-hang/ClientService';
import {AuthService} from 'src/service/AuthService';

import {SentMessages} from './SentMessages';

export const ResolvedTasks = props => {

  const header = 'Kết quả công việc';
  const dataKey = 'workEffortId';

  const params = useParams();

  const preview = useRef(null);
  const print = useRef(null);

  const [serviceId, setServiceId] = useState(null);

  const [service, setService] = useState(null);
  const [featuredColumns, setFeaturedColumns] = useState([]);

  const [attachments, setAttachments] = useState(null);

  const [displayPreview, setDisplayPreview] = useState(false);

  const [displaySentMessages, setDisplaySentMessages] = useState(null);

  useEffect(() => {
    const subscription = props.iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('workEfforts') && data?.message?.workEffortTypeEnumIdSet?.includes('WetTask') && service?.productId) {
        refreshLazyData();
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setServiceId(params?.serviceId || props?.serviceId);
  }, [params?.serviceId, props?.serviceId]);

  useEffect(() => {
    if (serviceId) {
      ServiceService.get(serviceId).then(data => {
        if (data?.serviceFeatures?.length) {
          setFeaturedColumns(data.serviceFeatures.map(serviceFeature => {
            switch (serviceFeature.productFeatureId) {
              case 'WeTaskNo':
                return {field: 'taskNo', header: serviceFeature.applAsValue, minWidth: 140, matchMode: 'contains'};
              case 'WeTaskResolvedDate':
                return {field: 'taskResolvedDate', header: serviceFeature.applAsValue, width: 120, dataType: 'date'};
              case 'WeTaskSentMail':
                return {field: 'emailSentCount', header: serviceFeature.applAsValue, width: 120, matchMode: 'numeric', dataType: 'number'};
              default:
                return null;
            }
          }));
        } else {
          setFeaturedColumns([]);
        }

        setService(data);
      });
    }
  }, [serviceId]);

  useEffect(() => {
    if (service?.productId) {
      reloadLazyData();
    }
  }, [service]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, refreshLazyData, reloadLazyData} = useDataTable({
    stateKey: `ResolvedTasks_${serviceId}_${featuredColumns?.length}`,
    tableHeader: header + ` ${service?.productName || ''}`,
    dataKey,
    columns: [
      ...featuredColumns.filter(featuredColumn => featuredColumn?.field),
      {field: service?.productClassEnumId === 'PclsServiceTaskSingleClient' ? 'parent' : 'workEffortId', header: 'Mã CV', width: 100, matchMode: 'equals'},
      {field: 'actualCompletionDate', header: 'Ngày hoàn thành', width: 170, dataType: 'date-time'},
      {
        field: 'customerName', filterField: 'parties.partyName', header: 'Khách hàng', minWidth: 180, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'Customer')
            ?.map((party, index) => <p key={index} className="mb-0">- {party.partyName}</p>);
        }
      },
      {
        field: 'customerPseudoId', filterField: 'parties.partyPseudoId', header: 'MST khách hàng', minWidth: 180, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'Customer')
            ?.map((party, index) => <p key={index} className="mb-0">- {party.partyPseudoId}</p>);
        }
      },
      {
        field: 'employee', filterField: 'parties.partyPseudoId@IAM@1', header: 'Nhân viên', minWidth: 180, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'Assignee')
            ?.map((party, index) => <p key={index} className="mb-0" title={`Công việc tạo bởi ${rowData.createdByPartyName}`}>[{party.partyPseudoId}] {party.partyName}</p>);
        }
      }
    ],
    indexColumnWidth: 45,
    elastic: {
      tie_breaker_id: dataKey,
      sort: [
        {lastUpdatedStamp: 'desc'}
      ],
      getPitId(): Promise<any> {
        return TaskService.getPitId();
      },
      deletePit(id: string): Promise<any> {
        return TaskService.deletePit(id);
      }
    },
    getList: lazyLoadEvent => {
      if (service?.productId && service?.productClassEnumId) {
        return Service.getList(lazyLoadEvent, {serviceId: service.productId, productClassEnumId: service.productClassEnumId});
      } else {
        return Promise.resolve({});
      }
    }
  });

  const {render: renderDialogSendEmail, update: sendEmail} = useDialogCrup({
    header: '!Gửi Email',
    dataKey,
    submitLabel: 'Gửi',
    width: '40rem',
    fields: [
      {field: 'emailContent', type: 'hidden'},
      {field: 'workEffortName', header: 'Tên công việc', InputTextProps: {readOnly: true} as any, className: 'md:col-12'},
      {field: 'toAddresses', header: 'Email nhận', required: true, className: 'md:col-12'}
    ],
    updateItem(id: string, item: any): Promise<any> {
      return Service.sendEmailMessage(id, item);
    },
    reloadLazyData: refreshLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const makeInnerHTML = resolvedContent => {
    const div = document.createElement('div');
    div.innerHTML = resolvedContent.trim();

    for (const ref of div.querySelectorAll('[data-iam-ref-as]') as any) {
      ref.style.color = 'unset';
      ref.style.backgroundColor = 'unset';
    }

    return div.innerHTML;
  }

  const doPrint = () => {
    if (selectedItem && selectedItem[dataKey]) {
      TaskService.getResolvedContent(selectedItem[dataKey]).then(data => {
        if (data.resolvedContent) {
          print.current.contentWindow.document.open();
          print.current.contentWindow.document.write(makeInnerHTML(data.resolvedContent));
          print.current.contentWindow.print();
        }
      });
    }
  }

  const doView = () => {
    if (selectedItem && selectedItem[dataKey]) {
      setDisplayPreview(true);
      TaskService.getResolvedContent(selectedItem[dataKey]).then(data => {
        if (data.resolvedContent) {
          preview.current.contentWindow.document.open();
          preview.current.contentWindow.document.write(makeInnerHTML(data.resolvedContent));
        }
        if (data?.attachments?.length) {
          AuthService.getApiKey().then(({token}) => {
            const _attachments = [];
            for (const attachment of data.attachments) {
              _attachments.push({
                key: attachment.workEffortContentId,
                contentType: attachment.contentType,
                fileName: attachment.fileName,
                src: `${TaskService.baseURL}/${TaskService.entity}/${selectedItem[dataKey]}/contents/${attachment.workEffortContentId}/download`,
                viewSrc: `${window.location.origin}${TaskService.baseURL}/iam-content/${token}/work-effort/${attachment.workEffortContentId}/download`
              });
            }
            setAttachments(_attachments);
          });
        } else {
          setAttachments([]);
        }
      });
    }
  }

  const doSendEmail = async () => {
    if (selectedItem && selectedItem[dataKey]) {
      let clientDetail;
      if (selectedItem.clientId) {
        clientDetail = await ClientService.get(selectedItem.clientId);
      }

      TaskService.getResolvedContent(selectedItem[dataKey]).then(data => {
        if (data.resolvedContent) {
          sendEmail({
            [dataKey]: selectedItem[dataKey],
            workEffortName: selectedItem.workEffortName,
            emailContent: makeInnerHTML(data.resolvedContent),
            toAddresses: clientDetail?.emailAddress || ''
          });
        }
      });
    }
  }

  const {renderToolbar} = useToolbar({
    leftButtons: <Fragment>
      <Button label="In" icon="pi pi-print" severity="info" onClick={doPrint} disabled={!selectedItem}/>
      <Button label="Xem" icon="pi pi-eye" severity="help" onClick={doView} disabled={!selectedItem}/>
      {featuredColumns.filter(col => col?.field === 'emailSentCount').length > 0 && <Fragment>
				<Button label="Gửi Email" icon="pi pi-envelope" severity="warning" onClick={doSendEmail} disabled={!selectedItem}/>
				<Button label="Xem Email đã gửi" icon="pi pi-history" severity="secondary" onClick={() => setDisplaySentMessages(selectedItem[dataKey])} disabled={!selectedItem}/>
			</Fragment>}
    </Fragment>
  });

  const footerPreview = (
    <div>
      <Button label="In" icon="pi pi-print" type="button" outlined severity="info" size="small" onClick={doPrint}/>
      <Button label="Gửi Email" icon="pi pi-envelope" type="button" outlined severity="warning" size="small" onClick={doSendEmail}/>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplayPreview(false)}/>
    </div>
  );

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 pb-0" style={{maxHeight: `calc(100vh - ${(props?.showService ? '17.1rem' : '13.7rem')})`}}>
        {renderDataTable()}
      </div>

      <Dialog header={header} footer={footerPreview} visible={displayPreview} style={{width: '70rem'}} position="top" onHide={() => setDisplayPreview(false)}>
        <div className="grid">
          <div className="col-12">
            <iframe ref={preview} title="task-preview" style={{width: '100%', height: '50vh', border: 'none'}}/>
          </div>
          <div className="col-12 mt-3">
            <FileTableViewer items={attachments}/>
          </div>
        </div>
      </Dialog>

      {renderDialogSendEmail()}

      <SentMessages display={displaySentMessages} setDisplay={setDisplaySentMessages}/>

      <iframe ref={print} title="task-preview" style={{width: '100%', height: '70vh', border: 'none', display: 'none'}}/>
    </div>
  );
}
