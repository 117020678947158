import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Period, useDataTableBasic} from '@iamsoftware/react-hooks';

import {TaskExpenseService as Service} from './TaskExpenseService';

export const ByEmployee = ({iamElasticHasChange}) => {

  const header = 'Tổng hợp chi phí theo Nhân viên';
  const dataKey = 'key';

  const [period, setPeriod] = useState(null);

  const [items, setItems] = useState([]);

  useEffect(() => {
    reLoad();

    const subscription = iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('mantle.account.invoice.InvoiceItem')) {
        reLoad();
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable} = useDataTableBasic({
    tableHeader: header,
    dataKey,
    indexColumnWidth: 45,
    columns: [
      {field: 'key', header: 'Nhân viên', minWidth: 250},
      {field: 'doc_count', header: 'Số lần chi', width: 200, dataType: 'number'},
      {field: 'totalAmount', header: 'Tổng chi phí', width: 200, dataType: 'number'}
    ],
    items: items
  });

  const reLoad = () => {
    if (period?.length === 2) {
      Service.getExpensesByEmployee(period[0], period[1]).then(({aggregations}) => {
        if (aggregations?.buckets) {
          setItems(aggregations.buckets.map(bucket => {
            return {key: bucket.key?.join(' - '), doc_count: bucket.doc_count, totalAmount: bucket.totalAmount.value};
          }));
        } else {
          setItems([]);
        }
      });
    }
  }

  return (
    <div className="grid">
      <div className="col-3 p-fluid">
        <Period defaultPeriod="THISMONTH" value={period} onChange={({value}) => setPeriod(value)}/>
      </div>
      <div className="col-1">
        <Button icon="pi pi-search" rounded severity="success" onClick={reLoad}/>
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 19.1rem)'}}>
        {renderDataTable()}
      </div>
    </div>
  );
}