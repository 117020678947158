import {useState} from 'react';

import {OrganizationService} from '../../app/he-thong/to-chuc/OrganizationService';

interface Props {
  defaultSrc?: string
  last?: string
}

export const Logo = (props: Props) => {

  const [replace, setReplace] = useState(0);

  const onError = event => {
    if (replace < 5) {
      setReplace(prevState => prevState + 1);
      event.target.src = props.defaultSrc || '/iam/IAM-TASK.png';
    }
    return true;
  }
  return <img src={`${OrganizationService.baseURL}/${OrganizationService.entity}/logo?last=${props.last}`} onError={onError} alt="Logo" className="iam-logo"
              style={{height: 'unset'}}/>
}