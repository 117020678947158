import {Service} from './Service';

export class NotificationsService extends Service {

  static entity: string = 'iam-notification';

  static count(topic?: string): Promise<any> {
    return this.axios.get(`${this.entity}/notifications/count`, {params: {topic}}).then(res => res.data);
  }

  static getNotifications(params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/notifications/list`, {params}).then(res => res.data);
  }

  static markTouched(): Promise<any> {
    return this.axios.put(`${this.entity}/notifications/mark-touched`).then(res => res.data);
  }

}
