import {TabView, TabPanel} from 'primereact/tabview';

import {Expenses} from './Expenses';
import {ByEmployee} from './ByEmployee';
import {ByType} from './ByType';

export const TaskExpenseReport = ({iamElasticHasChange}) => {

  return (
    <TabView className="p-tabview-transparent">
      <TabPanel header="Chi tiết chi phí">
        <Expenses iamElasticHasChange={iamElasticHasChange}/>
      </TabPanel>
      <TabPanel header="Theo nhân viên">
        <ByEmployee iamElasticHasChange={iamElasticHasChange}/>
      </TabPanel>
      <TabPanel header="Theo loại chi phí">
        <ByType iamElasticHasChange={iamElasticHasChange}/>
      </TabPanel>
    </TabView>

  );
}