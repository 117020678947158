import {Fragment, useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';

import {Badge} from 'primereact/badge';
import {classNames} from 'primereact/utils';
import {Ripple} from 'primereact/ripple';

import {IamPermission} from 'src/shared/utils/Permission';

import {AuthService} from 'src/service/AuthService';
import {OrganizationService} from './he-thong/to-chuc/OrganizationService';

export const AppMenu = ({onMenuItemClick}) => {

  const [appMenuTitle, setAppMenuTitle] = useState({} as any);
  const [taskReportMenuItems, setTaskReportMenuItems] = useState([]);
  const [minutesMenuItem, setMinutesMenuItem] = useState({});

  useEffect(() => {
    OrganizationService.getContentText('AppMenu_LabelConfig').then(({contentText}) => {
      const _appMenuTitle = {
        ProdServiceManagement: 'Quản lý dịch vụ'
      };
      try {
        const appMenuLabels = JSON.parse(contentText);
        appMenuLabels.forEach(appMenuLabel => {
          _appMenuTitle[appMenuLabel.dataKey] = appMenuLabel.applAsValue || appMenuLabel.originalTitle;
        });
      } catch (e) {
      }
      setAppMenuTitle(_appMenuTitle);
    });

    AuthService.getPreference('ConfigMenu_ResolvedTask_report').then(({preferenceValue}) => {
      if (preferenceValue) {
        let savedMenus = [];
        try {
          savedMenus = JSON.parse(preferenceValue);
        } catch (e) {
        }
        if (savedMenus?.length) {
          setTaskReportMenuItems(savedMenus.map(savedMenu => {
            return {label: savedMenu.applAsValue, icon: 'pi pi-fw pi-circle', to: `/ket-qua/${savedMenu.productId}`};
          }));
        }
      }
    });
    AuthService.getPreference('ConfigMenu_ResolvedMinutes_report').then(({preferenceValue}) => {
      if (preferenceValue) {
        let savedMenus = [];
        try {
          savedMenus = JSON.parse(preferenceValue);
        } catch (e) {
        }
        if (savedMenus?.length) {
          setMinutesMenuItem({
            label: 'Biên bản',
            items: savedMenus.map(savedMenu => {
              return {label: savedMenu.applAsValue, icon: 'pi pi-fw pi-file-word', to: `/bien-ban/${savedMenu.productId}`};
            })
          });
        }
      }
    });
  }, []);

  const appMenus: Array<any> = [
    {
      label: 'Công việc',
      items: [
        {label: 'Công việc của tôi', icon: 'pi pi-fw pi-desktop', to: '/cong-viec/dashboard'},
        {label: 'Theo dõi tiến độ', icon: 'pi pi-fw pi-sliders-h', to: '/cong-viec/theo-doi-tien-do'},
        {label: 'Danh sách công việc', icon: 'pi pi-fw pi-list', to: '/cong-viec/danh-sach', visible: IamPermission.has('VIEW', 'TaskManagement')},
        {label: 'Đầu mục công việc', icon: 'pi pi-fw pi-sitemap', to: '/cong-viec/dau-muc', visible: IamPermission.has('VIEW', 'TaskHeadingManagement')},
        {label: 'Phân công', icon: 'pi pi-fw pi-user-edit', to: '/cong-viec/phan-cong', visible: IamPermission.has('VIEW', 'AggregatedTaskManagement')}
      ]
    },
    {
      label: 'Báo cáo kết quả',
      items: [
        ...taskReportMenuItems,
        {label: 'Tổng hợp kết quả', icon: 'pi pi-fw pi-chart-bar', to: '/ket-qua'}
      ]
    },
    minutesMenuItem,
    {
      label: 'Lịch làm việc',
      items: [
        {label: 'Lịch tháng', icon: 'pi pi-fw pi-calendar', to: '/lich-lam-viec/lich-thang'},
        {label: 'Sự kiện', icon: 'pi pi-fw pi-star', to: '/lich-lam-viec/su-kien'},
        {label: 'Nghỉ phép', icon: 'pi pi-fw pi-calendar-times', to: '/lich-lam-viec/nghi-phep'},
        {label: 'Quản lý nghỉ phép', icon: 'pi pi-fw pi-chart-bar', to: '/lich-lam-viec/thong-ke-nghi-phep'},
        {label: 'Làm thêm giờ', icon: 'pi pi-fw pi-clock', to: '/lich-lam-viec/lam-them-gio'}
      ]
    },
    {
      label: 'Báo cáo',
      items: [
        {label: 'Thống kê công việc', icon: 'pi pi-fw pi-calendar', to: '/bao-cao/thong-ke-cong-viec', visible: IamPermission.has('VIEW', ['StatisticsTaskByClient', 'StatisticsTaskByService', 'StatisticsTaskByEmployee', 'StatisticsTaskByTeam'])},
        {label: 'Tiến độ xử lý', icon: 'pi pi-fw pi-sliders-h', to: '/bao-cao/tien-do', visible: IamPermission.has('VIEW', ['ProgressReportByEmployee', 'ProgressReportByTeam'])},
        {
          label: 'Báo cáo', icon: 'pi pi-fw pi-bookmark',
          items: [
            {label: 'Trạng thái công việc', to: '/bao-cao/trang-thai-cong-viec', visible: IamPermission.has('VIEW', ['StatusesOfTaskByEmployee', 'StatusesOfTaskByTeam', 'StatusesOfTaskByClient'])},
            {label: 'Chi phí công việc', to: '/bao-cao/chi-phi', visible: IamPermission.has('VIEW', 'TaskExpenseReport')}
          ]
        }
      ]
    },
    {
      label: 'Danh mục',
      items: [
        {label: 'Quản lý khách hàng', icon: 'pi pi-fw pi-folder', to: '/danh-muc/khach-hang', visible: IamPermission.has('VIEW', 'ClientManagement')},
        {label: appMenuTitle?.ProdServiceManagement, icon: 'pi pi-fw pi-folder', to: '/danh-muc/dich-vu', visible: IamPermission.has('VIEW', 'ProdServiceManagement')},
        {label: 'Tài liệu - biểu mẫu', icon: 'pi pi-fw pi-folder', to: '/danh-muc/bieu-mau', visible: IamPermission.has('VIEW', 'OrgDocumentManagement')},
        {
          label: 'Danh mục khác', icon: 'pi pi-fw pi-folder',
          items: [
            {label: 'Thông tin khách hàng', to: '/danh-muc/thong-tin-khach-hang', visible: IamPermission.has('VIEW', 'ClientAdditionalInfoManagement')},
            {label: 'Loại chi phí', to: '/danh-muc/loai-chi-phi', visible: IamPermission.has('VIEW', 'TaskExpenseTypeManagement')}
          ]
        }
      ]
    },
    {
      label: 'Hệ thống',
      items: [
        {label: 'Quản lý nhân viên', icon: 'pi pi-fw pi-user', to: '/he-thong/nhan-vien', visible: IamPermission.has('VIEW', 'UserManagement')},
        {label: 'Team - nhóm', icon: 'pi pi-fw pi-users', to: '/he-thong/team-nhom', visible: IamPermission.has('VIEW', 'TeamManagement')},
        {label: 'Thông tin tổ chức', icon: 'pi pi-fw pi-sitemap', to: '/he-thong/to-chuc', visible: IamPermission.has('VIEW', 'OrgInfoManagement')}
      ]
    }
  ];

  const pluck = menu => {
    if (menu.items?.length) {
      if (typeof menu.visible !== 'boolean') {
        menu.items.forEach(item => pluck(item));
        menu.visible = menu.items.filter(item => item.visible !== false).length > 0;
      }
    }
  }
  appMenus.forEach(appMenu => {
    pluck(appMenu);
  });

  return (
    <div className="layout-menu-container">
      <AppSubmenu items={appMenus} className="layout-menu" onMenuItemClick={onMenuItemClick} root={true} role="menu"/>
    </div>
  );
}

const AppSubmenu = (props) => {

  const [activeIndex, setActiveIndex] = useState(null)

  const onMenuItemClick = (event, item, index) => {
    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    //execute command
    if (item.command) {
      item.command({originalEvent: event, item: item});
    }

    if (index === activeIndex)
      setActiveIndex(null);
    else
      setActiveIndex(index);

    if (props.onMenuItemClick) {
      props.onMenuItemClick({
        originalEvent: event,
        item: item
      });
    }
  }

  const onKeyDown = event => {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.preventDefault();
      event.target.click();
    }
  }

  const renderLinkContent = item => {
    let submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>;
    let badge = item.badge && <Badge value={item.badge}/>

    return (
      <Fragment>
        <i className={item.icon}></i>
        <span>{item.label}</span>
        {submenuIcon}
        {badge}
        <Ripple/>
      </Fragment>
    );
  }

  const renderLink = (item, i) => {
    let content = renderLinkContent(item);

    if (item.to) {
      return (
        <NavLink aria-label={item.label} onKeyDown={onKeyDown} role="menuitem" className={({isActive}) => isActive ? 'p-ripple router-link-active router-link-exact-active' : 'p-ripple'} to={item.to} onClick={(e) => onMenuItemClick(e, item, i)} target={item.target} end>
          {content}
        </NavLink>
      )
    } else {
      return (
        <a tabIndex={0} aria-label={item.label} onKeyDown={onKeyDown} role="menuitem" href={item.url} className="p-ripple" onClick={(e) => onMenuItemClick(e, item, i)} target={item.target}>
          {content}
        </a>
      );
    }
  }

  let items = props.items && props.items.filter(item => item.visible !== false).map((item, i) => {
    let active = activeIndex === i;
    let styleClass = classNames(item.badgeStyleClass, {'layout-menuitem-category': props.root, 'active-menuitem': active && !item.to});

    if (props.root) {
      return (
        <li className={styleClass} key={i} role="none">
          {props.root === true && <Fragment>
						<div className="layout-menuitem-root-text" aria-label={item.label}>{item.label}</div>
						<AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick}/>
					</Fragment>}
        </li>
      );
    } else {
      return (
        <li className={styleClass} key={i} role="none">
          {renderLink(item, i)}
          <CSSTransition classNames="layout-submenu-wrapper" timeout={{enter: 1000, exit: 450}} in={active} unmountOnExit>
            <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick}/>
          </CSSTransition>
        </li>
      );
    }
  });

  return items ? <ul className={props.className} role="menu">{items}</ul> : null;
}