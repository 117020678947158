import {Route, Routes} from 'react-router-dom';

import {EventCalendar} from './lich-thang';
import {Events} from './su-kien';
import {EmploymentLeaves} from './nghi-phep';
import {EmploymentLeaveAggregation} from './thong-ke-nghi-phep';
import {Overtime} from './lam-them-gio';

export default function Reminder({me, iamElasticHasChange}) {

  return (
    <Routes>
      <Route path="lich-thang" element={<EventCalendar me={me} iamElasticHasChange={iamElasticHasChange}/>}/>
      <Route path="su-kien" element={<Events me={me} iamElasticHasChange={iamElasticHasChange}/>}/>
      <Route path="nghi-phep" element={<EmploymentLeaves me={me} iamElasticHasChange={iamElasticHasChange}/>}/>
      <Route path="thong-ke-nghi-phep" element={<EmploymentLeaveAggregation iamElasticHasChange={iamElasticHasChange}/>}/>
      <Route path="lam-them-gio" element={<Overtime me={me} iamElasticHasChange={iamElasticHasChange}/>}/>
    </Routes>
  );
}
