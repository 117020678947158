import {Fragment, useEffect} from 'react';

import {Button} from 'primereact/button';
import {ToastService, useDataTable, useToolbar} from '@iamsoftware/react-hooks';

import {IamPermission} from 'src/shared/utils/Permission';

import {TaskExpenseService as Service} from './TaskExpenseService';

export const Expenses = ({iamElasticHasChange}) => {

  const header = 'Chi phí';
  const dataKey = 'invoiceId';

  useEffect(() => {
    const subscription = iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('mantle.account.invoice.InvoiceItem')) {
        refreshLazyData();
        setSelectedItems(null);
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, setSelectedItems, refreshLazyData} = useDataTable({
    stateKey: 'Expenses-Table',
    tableHeader: header,
    dataKey,
    columns: [
      {
        field: 'details.workEffortName', header: 'Công việc', minWidth: 180, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.details
            ?.filter(detail => detail.workEffortName)
            ?.map((detail, index) => <p key={index} className="mb-0">[{detail.workEffortId}] {detail.workEffortName}</p>);
        }
      },
      {field: 'itemTypeDescription', header: 'Loại chi phí', minWidth: 150, matchMode: 'contains'},
      {field: 'amount', header: 'Chi phí', width: 120, dataType: 'number', matchMode: 'numeric'},
      {field: 'invoiceDate', header: 'Ngày', width: 120, dataType: 'date'},
      {field: 'description', header: 'Ghi chú', minWidth: 150, matchMode: 'contains'},
      {field: 'toPartyName', header: 'Nhân viên tạo', minWidth: 150, matchMode: 'contains'},
      {field: 'statusId', header: 'Trạng thái', minWidth: 150, matchMode: 'equals', filterType: 'dropdown', filterOptions: Service.expenseStatuses, dataType: 'fromFilterOptions'}
    ],
    indexColumnWidth: 45,
    elastic: {
      tie_breaker_id: dataKey,
      sort: [{invoiceDate: 'desc'}],
      getPitId(): Promise<any> {
        return Service.getPitId();
      },
      deletePit(id: string): Promise<any> {
        return Service.deletePit(id);
      }
    },
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    }
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const updateStatus = statusId => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.updateExpenseStatus(selectedItem[dataKey], statusId).then(() => {
        ToastService.success();
      });
    }
  }

  const {renderToolbar} = useToolbar({
    leftButtons: <Fragment>
      {IamPermission.has('ALL', 'TaskExpenseReport') && <Fragment>
				<Button label="Duyệt" icon="pi pi-check" severity="success" size="small" onClick={() => updateStatus('InvoiceAcked')} disabled={!['InvoiceSent', 'InvoiceCancelled'].includes(selectedItem?.statusId)}/>
				<Button label="Không duyệt" icon="pi pi-times" severity="danger" size="small" onClick={() => updateStatus('InvoiceCancelled')} disabled={!['InvoiceSent', 'InvoiceAcked'].includes(selectedItem?.statusId)}/>
			</Fragment>}
    </Fragment>
  });

  return (
    <div className="grid">
      {IamPermission.has('ALL', 'TaskExpenseReport') && <div className="col-12">
        {renderToolbar()}
			</div>}
      <div className="col-12 pb-0">
        {renderDataTable()}
      </div>
    </div>
  );
}