import {EntityService} from 'src/service/EntityService';

export class ServiceService extends EntityService {

  static entity = 'iam-product/services';

  static getClasses(): Promise<any> {
    return this.axios.get(`${this.entity}/classes`).then(res => res.data);
  }

  static getFeatures(): Promise<any> {
    return this.axios.get(`${this.entity}/features`).then(res => res.data);
  }

  static getContents(productId: string, lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/${productId}/contents/list?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static getContent(productId: string, productContentId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${productId}/contents/${productContentId}/detail`).then(res => res.data);
  }

  static createContent(productId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${productId}/contents/create`, data).then(res => res.data);
  }

  static updateContent(productId: string, productContentId: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${productId}/contents/${productContentId}/update`, data).then(res => res.data);
  }

  static disableContent(productId: string, productContentId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${productId}/contents/${productContentId}/disable`).then(res => res.data);
  }

  static enableContent(productId: string, productContentId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${productId}/contents/${productContentId}/enable`).then(res => res.data);
  }

}
