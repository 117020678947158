import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';

import {ToastService, useDataTable, useToolbar} from '@iamsoftware/react-hooks';

import {IamPermission} from 'src/shared/utils/Permission';

import {EventService as Service} from '../EventService';
import {TaskService} from '../../cong-viec/danh-sach/TaskService';

import {useCrupEvent} from '../CrupEvent';
import moment from 'moment/moment';

const purposeEnumId = 'WepEmploymentLeave';

export const EmploymentLeaves = ({me, iamElasticHasChange}) => {

  const header = 'Nghỉ phép';
  const dataKey = 'workEffortId';

  const [requiredParams] = useState({purposeEnumId: {value: purposeEnumId, matchMode: 'equals'}});

  useEffect(() => {
    const subscription = iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('workEfforts') && data?.message?.workEffortTypeEnumIdSet?.includes('WetEvent')) {
        refreshLazyData();
        setSelectedItems(null);
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, setSelectedItems, refreshLazyData, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: header,
    dataKey,
    columns: [
      {
        field: 'employee', filterField: 'parties.partyPseudoId', header: 'Nhân viên', minWidth: 180, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'Assignee')
            ?.map((party, index) => <p key={index} className="mb-0">[{party.partyPseudoId}] {party.partyName}</p>);
        }
      },
      {field: 'estimatedStartDate', header: 'Từ ngày', width: 130, dataType: 'date'},
      {field: 'estimatedCompletionDate', header: 'Đến ngày', width: 130, dataType: 'date'},
      {field: 'estimatedWorkDuration', header: 'Số ngày nghỉ', width: 130, dataType: 'number'},
      {field: 'description', header: 'Lý do', minWidth: 150, sortable: false, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'contains'},
      {field: 'createdByPartyName', header: 'Người tạo', minWidth: 150, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    elastic: {
      tie_breaker_id: dataKey,
      sort: [
        {lastUpdatedStamp: 'desc'}
      ],
      getPitId(): Promise<any> {
        return TaskService.getPitId();
      },
      deletePit(id: string): Promise<any> {
        return TaskService.deletePit(id);
      }
    },
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  const {renderDialogCrupEvent, doCreateEvent, doUpdateEvent, formEvent} = useCrupEvent({me, header});

  const {estimatedStartDate, estimatedCompletionDate} = formEvent.getRawValue();
  useEffect(() => {
    if (estimatedStartDate && estimatedCompletionDate && (formEvent.dirty['estimatedStartDate'] || formEvent.dirty['estimatedCompletionDate'])) {
      const duration = moment.duration(moment(estimatedCompletionDate).diff(moment(estimatedStartDate))).asDays();
      if (duration > 0) {
        const _value = formEvent.getRawValue();
        _value.estimatedWorkDuration = duration;
        formEvent.setValue(_value);
      }
    }
  }, [estimatedStartDate, estimatedCompletionDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doCreate = () => {
    doCreateEvent({purposeEnumId, statusId: 'WeOnHold', employees: [{value: me.partyId, label: me.partyName}]});
  }

  const doUpdate = (doView?: boolean) => {
    if (selectedItem && selectedItem[dataKey]) {
      doUpdateEvent(selectedItem, doView);
    }
  }

  const updateStatus = statusId => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.updateStatus(selectedItem[dataKey], statusId).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate,
    hasSelectedItem: selectedItem,
    doView: () => doUpdate(true),
    leftButtons: <Fragment>
      <Button label="Chỉnh sửa" icon="pi pi-check" severity="warning" size="small" onClick={() => doUpdate(selectedItem)} disabled={['WeApproved'].includes(selectedItem?.statusId) || me.partyId !== selectedItem?.createdByPartyId}/>
      {IamPermission.has('ALL', 'ReminderManagement') && <Fragment>
				<Button label="Duyệt" icon="pi pi-check" severity="success" size="small" onClick={() => updateStatus('WeApproved')} disabled={!['WeOnHold', 'WeCancelled'].includes(selectedItem?.statusId)}/>
				<Button label="Không duyệt" icon="pi pi-times" severity="danger" size="small" onClick={() => updateStatus('WeCancelled')} disabled={!['WeOnHold', 'WeApproved'].includes(selectedItem?.statusId)}/>
			</Fragment>}
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 13.7rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrupEvent()}
    </div>
  );
}