import {Route, Routes} from 'react-router-dom';

import {StatisticsTask} from './thong-ke-cong-viec';
import {ProgressReport} from './tien-do';
import {StatusesOfTask} from './trang-thai-cong-viec';
import {TaskExpenseReport} from './chi-phi';

export default function Report({me, iamElasticHasChange}) {

  return (
    <Routes>
      <Route path="thong-ke-cong-viec" element={<StatisticsTask me={me} iamElasticHasChange={iamElasticHasChange}/>}/>
      <Route path="tien-do" element={<ProgressReport me={me} iamElasticHasChange={iamElasticHasChange}/>}/>
      <Route path="trang-thai-cong-viec" element={<StatusesOfTask iamElasticHasChange={iamElasticHasChange}/>}/>
      <Route path="chi-phi" element={<TaskExpenseReport iamElasticHasChange={iamElasticHasChange}/>}/>
    </Routes>
  );
}
