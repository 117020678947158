import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {FormatDisplay} from '@iamsoftware/react-hooks';

import {DashboardService as Service} from './DashboardService';
import {NotificationsService} from 'src/service/NotificationsService';

import {Avatar} from 'src/shared/components/Avatar';

import {TasksOverview} from './TasksOverview';

export const Dashboard = ({iamElasticHasChange, taMaTeamActivity}) => {

  const [stats, setStats] = useState({} as any);

  const [activities, setActivities] = useState([]);

  useEffect(() => {

    loadData();

    const subscription = iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('workEfforts') && data?.message?.workEffortTypeEnumIdSet?.includes('WetTask')) {
        loadData();
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const subscription = taMaTeamActivity.subscribe(() => {
      NotificationsService.getNotifications({topic: 'TaMaTeamActivity', limit: 20}).then(data => {
        setActivities(data.listData.map(item => {
          item.messageJson = JSON.parse(item.messageJson);
          return item;
        }));
      });
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = () => {
    getStats('WeInPlanning');
    getStats('WeInProgress');
    getStats('WeOnHold');
    Service.getStatsExpiredTask().then(data => {
      setStats(prevState => {
        prevState['ExpiredTask'] = data;
        return {...prevState};
      });
    });
  }

  const getStats = statusId => {
    Service.getStatsByStatus(statusId).then(data => {
      setStats(prevState => {
        prevState[statusId] = data;
        return {...prevState};
      });
    });
  }

  return (
    <div className="grid">
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div><span className="block text-500 font-medium mb-3">Chưa xử lý</span>
              <div className="text-900 font-medium text-xl">{FormatDisplay.number(stats?.WeInPlanning?.totalCount)} <span className="text-sm text-500">công việc</span></div>
            </div>
            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}><i className="pi pi-hourglass text-cyan-500 text-xl"></i></div>
          </div>
          <span className="text-orange-500 font-medium">{FormatDisplay.number(stats?.WeInPlanning?.expiredCount)} </span><span className="text-500">đã quá hạn</span></div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div><span className="block text-500 font-medium mb-3">Đang thực hiện</span>
              <div className="text-900 font-medium text-xl">{FormatDisplay.number(stats?.WeInProgress?.totalCount)} <span className="text-sm text-500">công việc</span></div>
            </div>
            <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}><i className="pi pi-bolt text-blue-500 text-xl"></i></div>
          </div>
          <span className="text-orange-500 font-medium">{FormatDisplay.number(stats?.WeInProgress?.expiredCount)} </span><span className="text-500">đã quá hạn</span></div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div><span className="block text-500 font-medium mb-3">Đang chờ</span>
              <div className="text-900 font-medium text-xl">{FormatDisplay.number(stats?.WeOnHold?.totalCount)} <span className="text-sm text-500">công việc</span></div>
            </div>
            <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}><i className="pi pi-ban text-purple-500 text-xl"></i></div>
          </div>
          <span className="text-orange-500 font-medium">{FormatDisplay.number(stats?.WeOnHold?.expiredCount)} </span><span className="text-500">đã quá hạn</span></div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div><span className="block text-500 font-medium mb-3">Quá hạn</span>
              <div className="text-900 font-medium text-xl">{FormatDisplay.number(stats?.ExpiredTask?.totalCount)} <span className="text-sm text-500">công việc</span></div>
            </div>
            <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}><i className="pi pi-exclamation-circle text-orange-500 text-xl"></i></div>
          </div>
          <span className="text-orange-500 font-medium">{FormatDisplay.number(stats?.ExpiredTask?.prioritizedCount)} </span><span className="text-500">công việc ưu tiên cao</span></div>
      </div>

      <div className="col-8">
        <TasksOverview iamElasticHasChange={iamElasticHasChange}/>
      </div>
      <div className="col-4">
        <div className="surface-card shadow-2 border-round">
          <div className="flex align-items-center justify-content-between p-4 pb-2 mb-3">
            <div className="text-900 font-medium text-xl">Hoạt động gần đây</div>
          </div>
          <div className="px-4" style={{maxHeight: 'calc(100vh - 24.5rem)', overflowY: 'auto'}}>
            <ul className="list-none p-0 m-0">
              {activities.map((activity, index) => <li key={index} className="pt-3 pb-2 border-bottom-1 surface-border">
                <div className="grid">
                  <div className="col-fixed">
                    <div className="mr-1 w-3rem h-3rem iam-avatar">
                      {activity.messageJson?.createdByPartyId && <Avatar partyId={activity.messageJson.createdByPartyId}/>}
                    </div>
                  </div>
                  <div className="col">
                    <div className="flex align-items-center justify-content-between">
                      <span className="text-900 font-medium block mb-2">{activity.messageJson?.createdByUser}</span>
                      <span className="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0">{FormatDisplay.timeAgo(activity.sentDate)}</span>
                    </div>
                    <div className="text-700 mb-2" dangerouslySetInnerHTML={{__html: activity.messageJson?.titleMessage}}></div>
                    {activity.messageJson?.linkHref && <Link to={activity.messageJson?.linkHref}>
											<i className="pi pi-reply text-sm mr-2"></i><span>{activity.messageJson?.linkText}</span>
										</Link>}
                  </div>
                </div>
              </li>)}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}