import {EntityService} from 'src/service/EntityService';

export class ResolvedTaskService extends EntityService {

  static entity = 'iam-work-effort/resolved-tasks';

  static sendEmailMessage(workEffortId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${workEffortId}/email-messages/send`, data).then(res => res.data);
  }

  static getEmailMessages(workEffortId: string, lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/${workEffortId}/email-messages/list?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

}
