import {Fragment, useState} from 'react';

import {AutoComplete} from 'primereact/autocomplete';

import {ServiceService} from '../danh-muc/dich-vu/ServiceService';

import {ResolvedTasks} from './ResolvedTasks';

export const Report = ({iamElasticHasChange}) => {

  const [services, setServices] = useState([]);
  const [service, setService] = useState(null);

  const searchServices = event => {
    ServiceService.find(event.query).then(data => {
      setServices(data.listData.map(item => {
        return {value: item.productId, label: `[${item.pseudoId}] - ${item.productName}`};
      }));
    });
  }

  return (
    <Fragment>
      <div className="p-fluid formgrid grid mb-2">
        <div className="col-6">
          <AutoComplete value={service} suggestions={services} completeMethod={searchServices} onChange={e => setService(e.value)}
                        field="label" dropdown placeholder="Chọn dịch vụ"/>
        </div>
      </div>

      <ResolvedTasks iamElasticHasChange={iamElasticHasChange} serviceId={service?.value} showService="Y"/>
    </Fragment>
  );
}