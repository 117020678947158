import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {useForm} from '@iamsoftware/react-hooks';

import {OrganizationService as Service} from './OrganizationService';
import {CommonService} from 'src/service/CommonService';

export const OrgInfo = () => {

  const [formType, setFormType] = useState<'View' | 'Update'>('View');

  const [submitting, setSubmitting] = useState(false);

  const [provinces, setProvinces] = useState([]);
  const [counties, setCounties] = useState([]);

  useEffect(() => {
    CommonService.getProvinces().then(data => {
      setProvinces(data.listData.map(item => {
        return {value: item.toGeoId, label: item.geoName}
      }));
    });
    loadSetting();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadSetting = () => {
    Service.get().then(data => {
      form.setValue(data);
    });
  }

  const form = useForm({
    fields: [
      {field: 'partyTaxId', header: 'Mã số thuế', disabled: true, className: 'md:col-4'},
      {field: 'partyName', header: 'Tên đơn vị', required: true, className: 'md:col-12'},
      {field: 'address1', header: 'Địa chỉ', required: true, InputTextProps: {maxLength: 500}, className: 'md:col-12'},
      {field: 'stateProvinceGeoId', header: 'Tỉnh/Thành phố', required: true, type: 'Dropdown', DropdownProps: {options: provinces, filter: true}, className: 'md:col-6'},
      {field: 'countyGeoId', header: 'Quận/Huyện', required: true, type: 'Dropdown', DropdownProps: {options: counties, filter: true}, className: 'md:col-6'},
      {field: 'phoneNumber', header: 'Số điện thoại', InputTextProps: {maxLength: 17, keyfilter: 'int'}, className: 'md:col-6'},
      {field: 'emailAddress', header: 'Email', InputTextProps: {maxLength: 50}, className: 'md:col-6'}
    ],
    readOnly: formType === 'View'
  });

  const {stateProvinceGeoId} = form.getValue();
  useEffect(() => {
    if (stateProvinceGeoId) {
      CommonService.getCounties(stateProvinceGeoId).then(data => {
        setCounties(data.listData.map(item => {
          return {value: item.toGeoId, label: item.geoName}
        }));
      });
    }
  }, [stateProvinceGeoId]);

  const doSubmit = () => {
    if (!submitting) {
      setSubmitting(true);

      Service.set(form.getValue()).then(() => {
        loadSetting();
        setFormType('View');
      }).finally(() => setSubmitting(false));
    }
  }

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">

          {form.render()}

          <div className="grid">
            {formType === 'View' && <div className="col-12 flex justify-content-center">
							<Button label="Chỉnh sửa" icon="pi pi-pencil" severity="warning" size="small" onClick={() => setFormType('Update')}/>
						</div>}
            {formType === 'Update' && <div className="col-12 flex gap-2 justify-content-center">
							<Button label="Lưu" icon="pi pi-check" size="small" onClick={doSubmit}/>
							<Button label="Đóng" icon="pi pi-times" outlined severity="secondary" size="small" onClick={() => setFormType('View')}/>
						</div>}
          </div>
        </div>
      </div>
    </div>
  );
}