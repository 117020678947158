import {FormatDisplay} from '@iamsoftware/react-hooks';

export const Stats = ({stats}) => {

  return (
    <div className="grid">
      <div className="col-12 lg:col-6 xl:col-4">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div><span className="block text-500 font-medium mb-3">Chờ xử lý</span>
              <div className="text-900 font-medium text-xl">{FormatDisplay.number(stats?.openStats?.statsValue1St)} <span className="text-sm text-500">công việc</span></div>
            </div>
            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}><i className="pi pi-hourglass text-cyan-500 text-xl"></i></div>
          </div>
          <span className="text-green-500 font-medium">{FormatDisplay.number(stats?.openStats?.statsValue2Nd)} công việc </span><span className="text-500">đang xử lý</span></div>
      </div>
      <div className="col-12 lg:col-6 xl:col-4">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div><span className="block text-500 font-medium mb-3">Đã hoàn thành</span>
              <div className="text-900 font-medium text-xl">{FormatDisplay.number(stats?.closedStats?.statsValue1St)} <span className="text-sm text-500">công việc</span></div>
            </div>
            <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}><i className="pi pi-check-square text-blue-500 text-xl"></i></div>
          </div>
          <span className="text-green-500 font-medium">{FormatDisplay.number(stats?.closedStats?.statsValue2Nd)} công việc hoàn thành </span><span className="text-500">trong tháng này</span></div>
      </div>

      <div className="col-12 lg:col-6 xl:col-4">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div><span className="block text-500 font-medium mb-3">Đã huỷ</span>
              <div className="text-900 font-medium text-xl">{FormatDisplay.number(stats?.cancelledStats?.statsValue1St)} <span className="text-sm text-500">công việc</span></div>
            </div>
            <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}><i className="pi pi-times text-orange-500 text-xl"></i></div>
          </div>
          <span className="text-green-500 font-medium">{FormatDisplay.number(stats?.cancelledStats?.statsValue2Nd)} công việc bị huỷ </span><span className="text-500">trong tháng này</span></div>
      </div>

    </div>
  );
}