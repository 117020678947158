import {useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {FormatDisplay, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {IamPermission} from 'src/shared/utils/Permission';
import {DepartmentService as Service} from './DepartmentService';
import {UserService} from '../../nhan-vien/UserService';

export const Employees = ({me, display, setDisplay}) => {

  const header = 'Nhân viên';

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );
  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '70rem'}} position="top" onHide={() => setDisplay(false)}>
      <Screen me={me} departmentId={display}/>
    </Dialog>
  );
}

const Screen = ({me, departmentId}) => {

  const header = 'Nhân viên';
  const dataKey = 'partyRelationshipId';

  const [employees, setEmployees] = useState([]);

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    stateKey: 'Employees-Table',
    dataKey,
    columns: [
      {field: 'partyName', header: 'Tên nhân viên', minWidth: 250, matchMode: 'contains'},
      {field: 'fromRoleTypeId', header: 'Vai trò', width: 170, matchMode: 'equals', filterType: 'dropdown', filterOptions: Service.roleTypes, dataType: 'fromFilterOptions'},
      {field: 'fromDate', header: 'Từ ngày', width: 170, dataType: 'date-time'},
      {field: 'thruDate', header: 'Đến ngày', width: 170, dataType: 'date-time'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      if (departmentId) {
        return Service.getEmployees(departmentId, lazyLoadEvent);
      } else {
        return Promise.resolve({});
      }
    }
  });

  const searchEmployees = event => {
    UserService.find(event.query).then(data => {
      setEmployees(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.partyId, label: `[${item.username.replace(`${me.ownerTaxId}_`, '')}] - ${item.partyName}`};
      }));
    });
  }

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {
        field: 'fromParty', header: 'Tên nhân viên', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: employees, completeMethod: searchEmployees, field: 'label', dropdown: true, forceSelection: true},
        disabled: 'Update', className: 'md:col-12'
      },
      {field: 'fromRoleTypeId', header: 'Vai trò', required: true, type: 'Dropdown', DropdownProps: {options: Service.roleTypes}, className: 'md:col-12'},
      {field: 'fromDate', header: 'Từ ngày', required: true, InputTextProps: {type: 'date'}, className: 'md:col-12'},
      {field: 'thruDate', header: 'Đến ngày', InputTextProps: {type: 'date'}, className: 'md:col-12'}
    ],
    createItem: item => {
      item.fromPartyId = item.fromParty.value;
      return Service.createEmployee(departmentId, item);
    },
    updateItem: (id, item) => {
      item.fromPartyId = item.fromParty.value;
      return Service.updateEmployee(departmentId, id, item);
    },
    reloadLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doCreate = () => {
    create({fromDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD')});
  }

  const doUpdate = () => {
    if (selectedItem && selectedItem[dataKey]) {
      selectedItem.fromParty = {
        value: selectedItem.fromPartyId,
        label: selectedItem.partyName
      };
      if (selectedItem.fromDate) {
        selectedItem.fromDate = FormatDisplay.date(selectedItem.fromDate, 'YYYY-MM-DD');
      }
      if (selectedItem.thruDate) {
        selectedItem.thruDate = FormatDisplay.date(selectedItem.thruDate, 'YYYY-MM-DD');
      }
      update(selectedItem);
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate,
    hasSelectedItem: selectedItem,
    doUpdate
  });

  return (
    <div className="grid mt-2">
      {IamPermission.has('UPDATE', 'OrgDepartmentManagement') && <div className="col-12">
        {renderToolbar()}
			</div>}
      <div className="col-12 py-0">
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>
  );
}