import {Service} from 'src/service/Service';

export class ZaloNotificationService extends Service {

  static entity = 'iam-zalo/me';

  static getOfficialAccount(): Promise<any> {
    return this.axios.get(`${this.entity}/official-account`).then(res => res.data);
  }

  static getFollowStatus(zoaId: string): Promise<any> {
    return this.axios.get(`${this.entity}/follow-status`, {params: {zoaId}}).then(res => res.data);
  }

}
