import {EntityService} from 'src/service/EntityService';

export class DepartmentService extends EntityService {

  static entity = 'iam-system/departments';

  static roleTypes = [
    {value: 'SectionManager', label: 'Trưởng bộ phận'},
    {value: 'DepartmentManager', label: 'Trưởng phòng ban'},
    {value: 'Executive', label: 'Ban lãnh đạo'}
  ];

  static getTreeList(): Promise<any> {
    return this.axios.get(`${this.entity}/tree-list`).then(res => res.data);
  }

  static getEmployees(departmentId: string, lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/${departmentId}/employees/list?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static createEmployee(departmentId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${departmentId}/employees/create`, data).then(res => res.data);
  }

  static updateEmployee(departmentId: string, id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${departmentId}/employees/${id}/update`, data).then(res => res.data);
  }

}
