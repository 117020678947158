import {Service} from './Service';

export class PartyService extends Service {

  static entity: string = 'iam-party';

  static getByTaxCode(partyTaxId: string, includeRepresentative?: string): Promise<any> {
    return this.axios.get(`${this.entity}/by-tax-code`, {params: {partyTaxId, includeRepresentative}}).then(res => res.data);
  }

  static genders = [
    {value: 'M', label: 'Nam'},
    {value: 'F', label: 'Nữ'}
  ];

}
