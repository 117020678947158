import {Fragment, useEffect, useState} from 'react';

import {AutoComplete} from 'primereact/autocomplete';
import {Button} from 'primereact/button';
import {FormatDisplay, Period, ToastService, useDataTable} from '@iamsoftware/react-hooks';

import {ProgressReportService} from './ProgressReportService';
import {TaskService} from '../../cong-viec/danh-sach/TaskService';
import {TeamService} from '../../he-thong/team-nhom/TeamService';

import {Stats} from './Stats';

export const ByTeam = ({iamElasticHasChange}) => {

  const header = 'Công việc';
  const dataKey = 'workEffortId';

  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState(null);

  const [period, setPeriod] = useState(null);

  const [stats, setStats] = useState({} as any);

  useEffect(() => {
    const subscription = iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('workEfforts') && data?.message?.workEffortTypeEnumIdSet?.includes('WetTask')) {
        loadData();
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    stateKey: `StatisticsTask_ByEmployee`,
    tableHeader: header,
    dataKey,
    headerColumnGroups: [
      {header, colSpan: 5},
      {header: <span>Ngày bắt đầu</span>, colSpan: 2},
      {header: <span>Ngày hoàn thành</span>, colSpan: 2}
    ],
    columns: [
      {field: 'workEffortId', header: 'Mã CV', width: 100, matchMode: 'equals'},
      {field: 'workEffortName', header: 'Công việc', minWidth: 200, sortable: false, matchMode: 'contains'},
      {field: 'priority', header: 'Ưu tiên', width: 90, matchMode: 'numeric', dataType: 'number'},
      {
        field: 'employee', filterField: 'parties.partyPseudoId', header: 'Nhân viên', minWidth: 180, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'Assignee')
            ?.map((party, index) => <p key={index} className="mb-0" title={`Công việc tạo bởi ${rowData.createdByPartyName}`}>[{party.partyPseudoId}] {party.partyName}</p>);
        }
      },
      {field: 'estimatedStartDate', header: 'Dự kiến', width: 170, dataType: 'date-time'},
      {
        field: 'actualStartDate', header: 'Thực tế', width: 170, dataType: 'custom', customCell(rowData: any): any {
          let _className = 'alert-success';
          if (rowData.estimatedStartDate < rowData.actualStartDate) {
            _className = 'alert-danger';
          }
          return <span className={_className}>{FormatDisplay.dateTime(rowData.actualStartDate)}</span>
        }
      },
      {field: 'estimatedCompletionDate', header: 'Dự kiến', width: 170, dataType: 'date-time'},
      {
        field: 'actualCompletionDate', header: 'Thực tế', width: 170, dataType: 'custom', customCell(rowData: any): any {
          let _className = 'alert-success';
          if (rowData.estimatedCompletionDate < rowData.actualCompletionDate) {
            _className = 'alert-danger';
          }
          return <span className={_className}>{FormatDisplay.dateTime(rowData.actualCompletionDate)}</span>
        }
      }
    ],
    indexColumnWidth: 45,
    elastic: {
      tie_breaker_id: dataKey,
      sort: [
        {lastUpdatedStamp: 'desc'}
      ],
      getPitId(): Promise<any> {
        return TaskService.getPitId();
      },
      deletePit(id: string): Promise<any> {
        return TaskService.deletePit(id);
      }
    },
    getList: lazyLoadEvent => {
      if (team?.value && period?.length === 2) {
        return ProgressReportService.getTasksByTeam(lazyLoadEvent, team.value, period[0], period[1]);
      } else {
        return Promise.resolve({});
      }
    }
  });

  const loadData = () => {
    if (team?.value && period?.length === 2) {
      ProgressReportService.getStatsByObject('Team', team.value, period[0], period[1]).then(data => {
        setStats(data);
      });
      reloadLazyData();
    } else {
      setStats({});
      ToastService.error('Chọn nhân viên và khoảng thời gian cần báo cáo.');
    }
  }

  const searchTeams = event => {
    const _event: any = {
      filters: {partyName: {value: event.query, matchMode: 'contains'}}
    };
    TeamService.getList(JSON.stringify(_event)).then(({listData}) => {
      setTeams(listData.map(item => {
        return {value: item.partyId, label: item.partyName};
      }));
    });
  }

  return (
    <Fragment>
      <div className="p-fluid formgrid grid mb-2">
        <div className="col-6">
          <AutoComplete value={team} suggestions={teams} completeMethod={searchTeams} onChange={e => setTeam(e.value)}
                        field="label" dropdown placeholder="Chọn nhóm"/>
        </div>
        <div className="col-3">
          <Period defaultPeriod="THISMONTH" value={period} onChange={({value}) => setPeriod(value)}/>
        </div>
        <div className="col-1">
          <Button icon="pi pi-search" rounded severity="success" onClick={loadData}/>
        </div>
      </div>

      <Stats stats={stats}/>

      <div className="grid">
        <div className="col-12 pb-0">
          {renderDataTable()}
        </div>
      </div>
    </Fragment>
  );
}