import {Service} from 'src/service/Service';

export class ProgressReportService extends Service {

  static entity = 'iam-report/progress-report';

  static getStatsByObject(byObject: string, objectId: string, fromDate: string, toDate: string): Promise<any> {
    return this.axios.get(`${this.entity}/stats`, {params: {byObject, objectId, fromDate, toDate}}).then(res => res.data);
  }

  static getTasksByEmployee(lazyLoadEvent: string, employeeId: string, fromDate: string, toDate: string): Promise<any> {
    return this.axios.get(`${this.entity}/by-employee`, {params: {lazyLoadEvent, employeeId, fromDate, toDate}}).then(res => res.data);
  }

  static getTasksByTeam(lazyLoadEvent: string, teamId: string, fromDate: string, toDate: string): Promise<any> {
    return this.axios.get(`${this.entity}/by-team`, {params: {lazyLoadEvent, teamId, fromDate, toDate}}).then(res => res.data);
  }

}
