import {Service} from 'src/service/Service';

export class StatusesOfTaskService extends Service {

  static entity = 'iam-report/statuses-of-task';

  static getStatusesByClient(partyIdSet: string, fromDate: string, toDate: string): Promise<any> {
    return this.axios.get(`${this.entity}/by-client`, {params: {partyIdSet, fromDate, toDate}}).then(res => res.data);
  }

  static getStatusesByEmployee(partyIdSet: string, fromDate: string, toDate: string): Promise<any> {
    return this.axios.get(`${this.entity}/by-employee`, {params: {partyIdSet, fromDate, toDate}}).then(res => res.data);
  }

  static getStatusesByTeam(partyIdSet: string, fromDate: string, toDate: string): Promise<any> {
    return this.axios.get(`${this.entity}/by-team`, {params: {partyIdSet, fromDate, toDate}}).then(res => res.data);
  }

}
