import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';

import {ToastService, useDataTable, useToolbar} from '@iamsoftware/react-hooks';

import {EventService as Service} from '../EventService';
import {TaskService} from '../../cong-viec/danh-sach/TaskService';

import {useCrupEvent} from '../CrupEvent';
import {cronOptions} from '../../../shared/utils/Cron';

const purposeEnumId = 'WepTimesheet';
const statuses = [
  {value: 'WeApproved', label: 'Đang hoạt động'},
  {value: 'WeClosed', label: 'Ngừng hoạt động'}
];

export const Events = ({me, iamElasticHasChange}) => {

  const header = 'Sự kiện';
  const dataKey = 'workEffortId';

  const [statusId, setStatusId] = useState(statuses[0].value);

  const [requiredParams, setRequiredParams] = useState(null);

  useEffect(() => {
    const subscription = iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('workEfforts') && data?.message?.workEffortTypeEnumIdSet?.includes('WetEvent')) {
        refreshLazyData();
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (statusId) {
      setRequiredParams({
        statusId: {value: statusId, matchMode: 'equals'},
        purposeEnumId: {value: purposeEnumId, matchMode: 'equals'}
      });
    } else {
      setRequiredParams({purposeEnumId: {value: purposeEnumId, matchMode: 'equals'}});
    }
  }, [statusId]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, refreshLazyData, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: header,
    dataKey,
    columns: [
      {field: 'workEffortId', header: 'Mã sự kiện', width: 120, matchMode: 'contains'},
      {field: 'workEffortName', header: 'Sự kiện', minWidth: 250, sortable: false, matchMode: 'contains'},
      {field: 'description', header: 'Mô tả', minWidth: 150, sortable: false, matchMode: 'contains'},
      {field: 'estimatedStartDate', header: 'Ngày bắt đầu', width: 170, dataType: 'date-time'},
      {field: 'recurCronOption', header: 'Chu kỳ', width: 120, matchMode: 'equals', filterType: 'dropdown', filterOptions: cronOptions.filter(it => it.value), dataType: 'fromFilterOptions'},
      {
        field: 'team', filterField: 'parties.partyName', header: 'Nhóm', width: 150, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'OrgTeam')
            ?.map((party, index) => <p key={index} className="mb-0">{party.partyName}</p>);
        }
      },
      {
        field: 'employee', filterField: 'parties.partyPseudoId', header: 'Nhân viên', minWidth: 180, sortable: false, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.parties
            ?.filter(party => party.partyRoleTypeId === 'Assignee')
            ?.map((party, index) => <p key={index} className="mb-0">[{party.partyPseudoId}] {party.partyName}</p>);
        }
      },
      {field: 'createdByPartyName', header: 'Người tạo', minWidth: 150, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    elastic: {
      tie_breaker_id: dataKey,
      sort: [
        {lastUpdatedStamp: 'desc'}
      ],
      getPitId(): Promise<any> {
        return TaskService.getPitId();
      },
      deletePit(id: string): Promise<any> {
        return TaskService.deletePit(id);
      }
    },
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  const {renderDialogCrupEvent, doCreateEvent, doUpdateEvent} = useCrupEvent({me, header});

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doCreate = () => {
    doCreateEvent({purposeEnumId, statusId: 'WeApproved', employees: [{value: me.partyId, label: me.partyName}]});
  }

  const doUpdate = (doView?: boolean) => {
    if (selectedItem && selectedItem[dataKey]) {
      doUpdateEvent(selectedItem, doView);
    }
  }

  const updateStatus = statusId => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.updateStatus(selectedItem[dataKey], statusId).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate,
    hasSelectedItem: selectedItem,
    doView: () => doUpdate(true),
    doUpdate,
    leftButtons: <Fragment>
      {statusId === statuses[0].value && <Button label="Khoá" icon="pi pi-lock" severity="danger" size="small" onClick={() => updateStatus(statuses[1].value)} disabled={!selectedItem}/>}
      {statusId === statuses[1].value && <Button label="Mở khoá" icon="pi pi-lock" severity="success" size="small" onClick={() => updateStatus(statuses[0].value)} disabled={!selectedItem}/>}
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 17.48rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrupEvent()}
    </div>
  );
}