export const cronOptions = [
  {value: '', label: 'Không lặp lại'},
  {value: 'daily', label: 'Hàng ngày'},
  {value: 'weekly', label: 'Hàng tuần'},
  {value: 'monthly', label: 'Hàng tháng'},
  {value: 'quarterly', label: 'Hàng qúy'},
  {value: 'yearly', label: 'Hàng năm'}
];

export const convertToCron = (option: string, startDate: any) => {
  if (option && startDate) {
    const minute = startDate.getMinutes();
    const hour = startDate.getHours();
    const dayOfWeek = startDate.getDay();
    const dayOfMonth = startDate.getDate();
    const month = startDate.getMonth();
    const quarterIndex = Math.floor(month / 3);

    switch (option) {
      case 'daily':
        return `0 ${minute} ${hour} * * ? *`;
      case 'weekly':
        return `0 ${minute} ${hour} ? * ${(dayOfWeek + 1)} *`;
      case 'monthly':
        return `0 ${minute} ${hour} ${dayOfMonth} * ? *`;
      case 'quarterly':
        return `0 ${minute} ${hour} ${dayOfMonth} ${quarterIndex}/3 ? *`;
      case 'yearly':
        return `0 ${minute} ${hour} ${dayOfMonth} ${(month + 1)} ? *`;
      default:
        return '';
    }
  }
  return '';
}