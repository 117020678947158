import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {Dialog} from 'primereact/dialog';
import {FormatDisplay, ToastService} from '@iamsoftware/react-hooks';

import {UserService as Service} from './UserService';

export const Permissions = ({display, setDisplay}) => {

  const header = `Phân quyền #${display?.partyName}`;

  const {renderScreen, footer} = useScreen({userId: display?.userId, setDisplay});

  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '70rem'}} position="top" onHide={() => setDisplay(false)}>
      {renderScreen()}
    </Dialog>
  );
}

const useScreen = ({userId, setDisplay}) => {

  const [submitting, setSubmitting] = useState(false);

  const [normalPermissions, setNormalPermissions] = useState([]);

  const [checkAll, setCheckAll] = useState({} as any);

  useEffect(() => {
    if (userId) {
      Service.getPermissions(userId).then(data => {
        setNormalPermissions(data.normalPermissions);
      });
    }
  }, [userId]);

  const onCheckAllChange = (permissionType, checked) => {
    setCheckAll(prevState => {
      prevState[permissionType] = checked;
      return {...prevState};
    });
    setNormalPermissions(prevState => {
      return prevState.map(normalPermission => {
        normalPermission.permissions.forEach(permission => {
          if (permission.permissionType === permissionType) {
            permission.granted = checked;
          }
        });
        return normalPermission;
      });
    });
  }

  const setChecked = (userGroupId, granted) => {
    setNormalPermissions(prevState => {
      return prevState.map(normalPermission => {
        normalPermission.permissions.forEach(permission => {
          if (permission.userGroupId === userGroupId) {
            permission.granted = granted;
            if (!granted) {
              setCheckAll(prevState => {
                prevState[permission.permissionType] = granted;
                return {...prevState};
              });
            }
          }
        });
        return normalPermission;
      });
    });
  }

  const Setter = ({normalPermission, permissionType}) => {
    return (
      <Fragment>
        {normalPermission.permissions
          ?.filter(permission => permission.permissionType === permissionType)
          ?.map((permission, permissionIndex) => <Checkbox key={permissionIndex} checked={permission.granted} onChange={e => setChecked(permission.userGroupId, e.checked)}/>)}
      </Fragment>
    );
  }

  const renderScreen = () => {
    return (
      <div className="grid">
        <div className="col-12">
          <div className="p-datatable p-component p-datatable-gridlines p-datatable-sm">
            <div className="p-datatable-wrapper">
              <table className="p-datatable-table">
                <thead className="p-datatable-thead">
                <tr>
                  <th className="text-center" style={{width: '45px'}}>#</th>
                  <th>Màn hình</th>
                  <th className="text-center" style={{width: '90px'}}>Xem</th>
                  <th className="text-center" style={{width: '90px'}}>Tạo mới</th>
                  <th className="text-center" style={{width: '90px'}}>Sửa</th>
                  <th className="text-center" style={{width: '90px'}}>Tất cả</th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th className="text-center"><Checkbox checked={checkAll.VIEW} onChange={e => onCheckAllChange('VIEW', e.checked)}/></th>
                  <th className="text-center"><Checkbox checked={checkAll.CREATE} onChange={e => onCheckAllChange('CREATE', e.checked)}/></th>
                  <th className="text-center"><Checkbox checked={checkAll.UPDATE} onChange={e => onCheckAllChange('UPDATE', e.checked)}/></th>
                  <th className="text-center"><Checkbox checked={checkAll.ALL} onChange={e => onCheckAllChange('ALL', e.checked)}/></th>
                </tr>
                </thead>
                <tbody className="p-datatable-tbody">
                {normalPermissions.map((normalPermission, permissionIndex) => <tr key={permissionIndex}>
                  <td className="text-center">{FormatDisplay.number(permissionIndex + 1)}</td>
                  <td>
                    {normalPermission.artifacts.map((artifact, artifactIndex) => <p key={artifactIndex}>{artifact.artifactName}</p>)}
                  </td>
                  <td className="text-center">
                    <Setter normalPermission={normalPermission} permissionType="VIEW"/>
                  </td>
                  <td className="text-center">
                    <Setter normalPermission={normalPermission} permissionType="CREATE"/>
                  </td>
                  <td className="text-center">
                    <Setter normalPermission={normalPermission} permissionType="UPDATE"/>
                  </td>
                  <td className="text-center">
                    <Setter normalPermission={normalPermission} permissionType="ALL"/>
                  </td>
                </tr>)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const onSubmit = () => {
    if (!submitting) {
      setSubmitting(true);

      const permissions = normalPermissions.reduce((accumulator, currentValue) => [...accumulator, ...currentValue.permissions], []);

      Service.setPermissions(userId, permissions).then(() => {
        ToastService.success();
        setDisplay(false);
      }).finally(() => setSubmitting(false));
    }
  }

  const footer = (
    <div>
      <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit} loading={submitting}/>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );

  return {renderScreen, footer};
}