import {Route, Routes} from 'react-router-dom';

import {Clients} from './khach-hang';
import {Services} from './dich-vu';
import {OrgDocuments} from './bieu-mau';
import {ClientAdditionalInfos} from './thong-tin-khach-hang';
import {TaskExpenseTypes} from './loai-chi-phi';

export default function Category() {

  return (
    <Routes>
      <Route path="khach-hang" element={<Clients/>}/>
      <Route path="dich-vu" element={<Services/>}/>
      <Route path="bieu-mau" element={<OrgDocuments/>}/>
      <Route path="thong-tin-khach-hang" element={<ClientAdditionalInfos/>}/>
      <Route path="loai-chi-phi" element={<TaskExpenseTypes/>}/>
    </Routes>
  );
}
