import {useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {useDataTable} from '@iamsoftware/react-hooks';

import {ResolvedTaskService as Service} from './ResolvedTaskService';

export const SentMessages = ({display, setDisplay}) => {

  const header = 'Email đã gửi';

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '70rem'}} position="top" onHide={() => setDisplay(false)}>
      {display && <Screen agreementId={display}/>}
    </Dialog>
  );
}

const Screen = ({agreementId}) => {

  const dataKey = 'emailMessageId';

  const [display, setDisplay] = useState(null);

  const {render: renderDataTable} = useDataTable({
    stateKey: 'SentMessages-Table',
    dataKey,
    columns: [
      {field: 'sentDate', header: 'Ngày gửi', width: 170, dataType: 'date-time'},
      {field: 'subject', header: 'Tiêu đề', minWidth: 150, matchMode: 'contains'},
      {field: 'toAddresses', header: 'Email nhận', width: 200, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 40,
    getList: lazyLoadEvent => {
      if (agreementId) {
        return Service.getEmailMessages(agreementId, lazyLoadEvent);
      } else {
        return Promise.resolve({});
      }
    },
    initActions(items: Array<any>) {
      items.forEach(item => {
        item.actions = [
          {icon: 'pi pi-eye', className: 'p-button-success', tooltip: 'Xem nội dung', command: () => setDisplay(item.body)}
        ];
      });
    }
  });

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <div className="grid mt-2">
      <div className="col-12 py-0">
        {renderDataTable()}
      </div>

      <Dialog header="Nội dung" footer={footer} visible={!!display} style={{width: '70rem'}} position="top" onHide={() => setDisplay(false)}>
        <div dangerouslySetInnerHTML={{__html: display}}></div>
      </Dialog>
    </div>
  );
}