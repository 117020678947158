import {useEffect, useState} from 'react';

import {AutoComplete} from 'primereact/autocomplete';
import {Button} from 'primereact/button';
import {Period, useDataTableBasic} from '@iamsoftware/react-hooks';

import {StatusesOfTaskService as Service} from './StatusesOfTaskService';
import {ClientService} from '../../danh-muc/khach-hang/ClientService';

export const ByClient = ({iamElasticHasChange}) => {

  const header = 'Trạng thái công việc theo Khách hàng';
  const dataKey = 'partyId';

  const [period, setPeriod] = useState(null);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState([]);

  const [items, setItems] = useState([]);

  useEffect(() => {
    const subscription = iamElasticHasChange.subscribe(data => {
      if (data?.message?.entitySet?.includes('workEfforts') && data?.message?.workEffortTypeEnumIdSet?.includes('WetTask')) {
        reLoad();
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const searchClients = event => {
    ClientService.find(event.query).then(data => {
      setClients(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.partyId, label: `[${item.partyTaxId}] - ${item.partyName}`};
      }));
    });
  }

  const {render: renderDataTable} = useDataTableBasic({
    tableHeader: header,
    dataKey,
    indexColumnWidth: 45,
    columns: [
      {field: 'pseudoId', header: 'Mã khách hàng', width: 170},
      {field: 'partyName', header: 'Tên khách hàng', minWidth: 250},
      {field: 'doc_count', header: 'Tổng số công việc', width: 170, dataType: 'number'},
      {field: 'WeInPlanning', header: 'Mới phân công', width: 160, dataType: 'number'},
      {field: 'WeInProgress', header: 'Đang thực hiện', width: 155, dataType: 'number'},
      {field: 'WeOnHold', header: 'Đang chờ', width: 120, dataType: 'number'},
      {field: 'WeComplete', header: 'Hoàn thành', width: 130, dataType: 'number'},
      {field: 'WeCancelled', header: 'Đã huỷ', width: 100, dataType: 'number'}
    ],
    items: items
  });

  const reLoad = () => {
    setItems(client);
    if (period?.length === 2 && client?.length) {
      Service.getStatusesByClient(client.map(c => c.value).join(','), period[0], period[1]).then(({aggregations}) => {
        if (aggregations?.buckets) {
          setItems(prevState => {
            prevState.forEach(item => {
              for (const bucket of aggregations.buckets) {
                if (item[dataKey] === bucket.key) {
                  item.doc_count = bucket.doc_count;
                  if (bucket.statuses?.buckets?.length) {
                    bucket.statuses?.buckets.forEach(status => {
                      item[status.key] = status.doc_count;
                    });
                  }
                  break;
                }
              }
            });
            return [...prevState];
          });
        }
      });
    }
  }

  return (
    <div className="grid">
      <div className="col-6 p-fluid">
        <AutoComplete value={client} suggestions={clients} completeMethod={searchClients} onChange={e => setClient(e.value)}
                      field="label" dropdown placeholder="Chọn khách hàng" multiple={true}/>
      </div>
      <div className="col-3 p-fluid">
        <Period defaultPeriod="THISMONTH" value={period} onChange={({value}) => setPeriod(value)}/>
      </div>
      <div className="col-1">
        <Button icon="pi pi-search" rounded severity="success" onClick={reLoad}/>
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 19.1rem)'}}>
        {renderDataTable()}
      </div>
    </div>
  );
}