import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {ConfirmDialogService, ToastService, useDataTableBasic, useDialogCrup} from '@iamsoftware/react-hooks';

import {ClientService} from '../../danh-muc/khach-hang/ClientService';

export const useCrupTaskClients = ({display, teamId, disabled}) => {

  const [items, setItems] = useState([]);

  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (display && items?.length) {
      items.forEach(item => {
        item.actions = [
          {icon: 'pi pi-pencil', className: 'p-button-warning', tooltip: 'Chỉnh sửa', command: () => doUpdate(item)},
          {icon: 'pi pi-trash', className: 'p-button-danger', tooltip: 'Xoá', command: () => doDelete(item)}
        ];
      });
    }
  }, [display, items?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchClients = event => {
    ClientService.find(event.query, teamId).then(data => {
      setClients(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.partyId, label: `[${item.partyTaxId}] - ${item.partyName}`};
      }));
    });
  }

  const {render: renderDialogBulkCreate, create: bulkCreate} = useDialogCrup({
    header: '!Khách hàng',
    dataKey: 'clientId',
    width: '45rem',
    fields: [
      {field: 'partyTaxIds', header: 'Mã số thuế khách hàng', required: true, type: 'InputTextarea', InputTextareaProps: {rows: 15}, className: 'md:col-12'}
    ],
    createItem: item => {
      return new Promise((resolve, reject) => {
        try {
          const complete = [];
          item.partyTaxIds.split('\n').forEach((partyTaxId, index) => {
            if (partyTaxId?.trim()?.length > 2) {
              const _event: any = {
                filters: {
                  teamIds: {value: teamId, matchMode: 'contains'},
                  partyTaxId: {value: partyTaxId.trim(), matchMode: 'equals'}
                }
              };
              complete.push(ClientService.getList(JSON.stringify(_event)).then(({listData}) => {
                if (listData?.length === 1 && listData[0].partyId) {
                  const client = listData[0];
                  client.fromDate = Date.now();
                  return client;
                } else {
                  return Promise.reject(`Lỗi dòng ${index + 1} - Mã số thuế ${partyTaxId} không tìm thấy dữ liệu.`);
                }
              }));
            } else {
              complete.push(Promise.reject('Dữ liệu không hợp lệ'));
            }
          });
          Promise.all(complete).then(results => {
            if (results?.length) {
              setItems(prevItems => {
                results.forEach(result => {
                  if (prevItems.filter(prevItem => prevItem.clientId === result.partyId).length === 0) {
                    prevItems.push({
                      clientId: result.partyId,
                      clientPseudoId: result.partyTaxId,
                      clientName: result.partyName,
                      comments: '',
                      fromDate: result.fromDate
                    });
                  }
                });
                return prevItems;
              });
            }
            resolve(item);
          }).catch(err => {
            ToastService.error(err);
            reject();
          });
        } catch (e) {
          ToastService.error('Dữ liệu không hợp lệ');
          reject();
        }
      });
    }
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header: '!Khách hàng',
    dataKey: 'clientId',
    width: '45rem',
    fields: [
      {
        field: 'client', header: 'Tên khách hàng', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: clients, completeMethod: searchClients, field: 'label', dropdown: true, forceSelection: true},
        disabled: 'Update', className: 'md:col-12'
      },
      {field: 'comments', header: 'Ghi chú', type: 'InputTextarea', className: 'md:col-12'}
    ],
    createItem: item => {
      if (items.filter(existingItem => existingItem.clientId === item.client.value).length) {
        ToastService.error('Khách hàng đã tồn tại.');
        return Promise.reject();
      } else {
        setItems(prevItems => {
          prevItems.push({
            clientId: item.client.value,
            clientPseudoId: item.client.partyTaxId,
            clientName: item.client.partyName,
            comments: item.comments,
            fromDate: Date.now()
          });
          return prevItems;
        });
        return Promise.resolve(item);
      }
    },
    updateItem: (id, item) => {
      setItems(prevItems => {
        for (const prevItem of prevItems) {
          if (prevItem.clientId === id) {
            prevItem.comments = item.comments;
            break;
          }
        }
        return prevItems;
      });
      return Promise.resolve(item);
    },
    hideToastMessage: true
  });

  const doUpdate = item => {
    item.client = {
      value: item.clientId,
      label: `[${item.clientPseudoId}] - ${item.clientName}`
    };
    update(item);
  }

  const doDelete = item => {
    ConfirmDialogService.confirm('Xóa khách hàng', `Xác nhận xóa khách hàng [${item.clientPseudoId}] - ${item.clientName} ra khỏi danh sách?`, () => {
      setItems(prevItems => {
        return prevItems.filter(prevItem => prevItem.clientId !== item.clientId);
      });
    });
  }

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span>{items?.length} Khách hàng</span>
      {!disabled && <div>
				<Button icon="pi pi-bolt" severity="info" rounded className="mr-1" onClick={bulkCreate} disabled={!teamId}/>
				<Button icon="pi pi-plus" severity="success" rounded onClick={create} disabled={!teamId}/>
			</div>}
    </div>
  );

  const {render} = useDataTableBasic({
    tableHeader: header,
    indexColumnWidth: 45,
    actionColumnWidth: disabled ? null : 80,
    columns: [
      {field: 'clientName', header: 'Tên khách hàng', minWidth: 150, matchMode: 'contains'},
      {field: 'clientPseudoId', header: 'MST', width: 160, matchMode: 'contains'},
      {field: 'comments', header: 'Ghi chú', minWidth: 150, matchMode: 'contains'}
    ],
    items
  });

  const renderClients = () => {
    return (
      <Fragment>
        {render()}
        {renderDialogBulkCreate()}
        {renderDialogCrup()}
      </Fragment>
    );
  }

  return {renderClients, clients: items, setClients: setItems};
}