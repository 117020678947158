import {EntityService} from 'src/service/EntityService';

export class TaskService extends EntityService {

  static entity = 'iam-work-effort/tasks';

  static getPitId(): Promise<any> {
    return this.axios.get(`${this.entity}/pit`).then(res => res.data);
  }

  static deletePit(id: string): Promise<any> {
    return this.axios.delete(`${this.entity}/pit`, {params: {id}}).then(res => res.data);
  }

  static getElasticTasks(params: string): Promise<any> {
    return this.axios.get(`${this.entity}/elastic-get-tasks?params=${params}`).then(res => res.data);
  }

  static update(workEffortId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${workEffortId}/update`, data).then(res => res.data);
  }

  static resolve(workEffortId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${workEffortId}/resolve`, data).then(res => res.data);
  }

  static getClients(workEffortId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${workEffortId}/clients`).then(res => res.data);
  }

  static getResolvedContent(workEffortId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${workEffortId}/get-resolved-content`).then(res => res.data);
  }

  static updateStatus(workEffortId: string, statusId: string): Promise<any> {
    return this.axios.patch(`${this.entity}/${workEffortId}/update-status`, {statusId}).then(res => res.data);
  }

  static getStatusHistories(workEffortId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${workEffortId}/status-histories`).then(res => res.data);
  }

  static getStatuses(): Promise<any> {
    return this.axios.get(`${this.entity}/statuses`).then(res => res.data);
  }

  static getComments(workEffortId: string, lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/${workEffortId}/comments/list?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static createComment(workEffortId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${workEffortId}/comments/create`, data).then(res => res.data);
  }

  static updateComment(workEffortId: string, communicationEventId: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${workEffortId}/comments/${communicationEventId}/update`, data).then(res => res.data);
  }

  static deleteComment(workEffortId: string, communicationEventId: string): Promise<any> {
    return this.axios.delete(`${this.entity}/${workEffortId}/comments/${communicationEventId}/delete`).then(res => res.data);
  }

  static checkRecurStartCron(item): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const doReject = () => {
        reject('Chu kỳ không hợp lệ');
      }
      if (item.recurStartCron) {
        const recurStartCronArray = item.recurStartCron.split(' ');
        if ([6, 7].includes(recurStartCronArray.length)) {
          if (recurStartCronArray[0] === '*' || recurStartCronArray[1] === '*') {
            doReject();
          }
        } else {
          doReject();
        }
      }
      resolve(item);
    });
  }

  static getExpenses(workEffortId: string, lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/${workEffortId}/expenses/list?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static createExpense(workEffortId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${workEffortId}/expenses/create`, data).then(res => res.data);
  }

}
