import {TabView, TabPanel} from 'primereact/tabview';

import {IamPermission} from 'src/shared/utils/Permission';

import {ByClient} from './ByClient';
import {ByEmployee} from './ByEmployee';
import {ByTeam} from './ByTeam';

export const StatusesOfTask = ({iamElasticHasChange}) => {

  return (
    <TabView className="p-tabview-transparent">
      {IamPermission.has('VIEW', 'StatusesOfTaskByEmployee') && <TabPanel header="Theo nhân viên">
				<ByEmployee iamElasticHasChange={iamElasticHasChange}/>
			</TabPanel>}
      {IamPermission.has('VIEW', 'StatusesOfTaskByTeam') && <TabPanel header="Theo nhóm">
				<ByTeam iamElasticHasChange={iamElasticHasChange}/>
			</TabPanel>}
      {IamPermission.has('VIEW', 'StatusesOfTaskByClient') && <TabPanel header="Theo khách hàng">
				<ByClient iamElasticHasChange={iamElasticHasChange}/>
			</TabPanel>}
    </TabView>

  );
}