import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {Column} from 'primereact/column';
import {TreeTable} from 'primereact/treetable';
import {useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {IamPermission} from 'src/shared/utils/Permission';

import {DepartmentService as Service} from './DepartmentService';

import {Employees} from './Employees';

export const Departments = ({me}) => {

  const [nodes, setNodes] = useState([]);
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);

  const [departments, setDepartments] = useState(null);

  const [displayEmployees, setDisplayEmployees] = useState(null);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    Service.getTreeList().then(data => {
      setNodes(data.treeNodes);
    });
  }

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header: 'Phòng ban',
    dataKey: 'partyId',
    width: '40rem',
    fields: [
      {field: 'toPartyId', header: 'Trực thuộc', required: true, type: 'Dropdown', DropdownProps: {options: departments, filter: true}, className: 'md:col-12'},
      {field: 'pseudoId', header: 'Mã phòng ban', required: true, className: 'md:col-12'},
      {field: 'partyName', header: 'Tên phòng ban', required: true, className: 'md:col-12'},
      {field: 'comments', header: 'Ghi chú', className: 'md:col-12'},
      {field: 'enabled', header: 'Hoạt động', type: 'Checkbox', className: 'md:col-12'}
    ],
    createItem: item => {
      item.disabled = item.enabled ? 'N' : 'Y';
      return Service.create(item);
    },
    updateItem: (id, item) => {
      item.disabled = item.enabled ? 'N' : 'Y';
      return Service.update(id, item);
    },
    reloadLazyData: load
  });

  const loadDepartmentOptions = (selectedDepartment?: string) => {
    const _departments = [];
    const pushRecursive = nodeList => {
      for (const node of nodeList) {
        if (node.data.partyId !== selectedDepartment) {
          _departments.push({value: node.data.partyId, label: node.data.partyName});
          if (node.children?.length) {
            pushRecursive(node.children);
          }
        }
      }
    }
    pushRecursive(nodes);

    setDepartments(_departments);
  }

  const doCreate = () => {
    loadDepartmentOptions();
    create({enabled: true});
  }

  const doUpdate = () => {
    loadDepartmentOptions(selectedNodeKey);
    Service.get(selectedNodeKey).then(data => {
      data.enabled = data.disabled === 'N';
      update(data);
    });
  }

  const {renderToolbar} = useToolbar({
    doCreate: IamPermission.has('CREATE', 'OrgDepartmentManagement') && doCreate,
    hasSelectedItem: !!selectedNodeKey && selectedNodeKey !== me.ownerPartyId,
    doUpdate: IamPermission.has('UPDATE', 'OrgDepartmentManagement') && doUpdate,
    leftButtons: <Fragment>
      <Button label="Nhân viên" icon="pi pi-users" severity="help" size="small" onClick={() => setDisplayEmployees(selectedNodeKey)} disabled={!selectedNodeKey}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0">
        <TreeTable value={nodes} selectionMode="single" selectionKeys={selectedNodeKey} onSelectionChange={e => setSelectedNodeKey(e.value)}
                   showGridlines scrollable scrollHeight="calc(100vh - 24.5rem)">
          <Column field="pseudoId" header="Mã" expander filter/>
          <Column field="partyName" header="Tên" filter/>
          <Column field="comments" header="Ghi chú" filter/>
          <Column field="disabled" header="Hoạt động" body={rowData => <Checkbox checked={rowData.disabled !== 'Y'}/>} style={{width: '8rem'}} className="text-center"/>
        </TreeTable>
      </div>

      {renderDialogCrup()}

      <Employees me={me} display={displayEmployees} setDisplay={setDisplayEmployees}/>
    </div>
  );
}