import {useEffect, useState} from 'react';

import {TabView, TabPanel} from 'primereact/tabview';

import {IamPermission} from 'src/shared/utils/Permission';

import {TaskService} from '../../cong-viec/danh-sach/TaskService';

import {ByClient} from './ByClient';
import {ByEmployee} from './ByEmployee';
import {ByService} from './ByService';
import {ByTeam} from './ByTeam';

export const StatisticsTask = ({me, iamElasticHasChange}) => {

  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    TaskService.getStatuses().then(({listData}) => {
      setStatuses(listData.map(item => {
        return {value: item.statusId, label: item.description};
      }));
    });
  }, []);

  return (
    <TabView className="p-tabview-transparent">
      {IamPermission.has('VIEW', 'StatisticsTaskByClient') && <TabPanel header="Theo khách hàng">
				<ByClient iamElasticHasChange={iamElasticHasChange} statuses={statuses}/>
			</TabPanel>}
      {IamPermission.has('VIEW', 'StatisticsTaskByService') && <TabPanel header="Theo dịch vụ">
				<ByService iamElasticHasChange={iamElasticHasChange} statuses={statuses}/>
			</TabPanel>}
      {IamPermission.has('VIEW', 'StatisticsTaskByEmployee') && <TabPanel header="Theo nhân viên">
				<ByEmployee me={me} iamElasticHasChange={iamElasticHasChange} statuses={statuses}/>
			</TabPanel>}
      {IamPermission.has('VIEW', 'StatisticsTaskByTeam') && <TabPanel header="Theo nhóm">
				<ByTeam iamElasticHasChange={iamElasticHasChange} statuses={statuses}/>
			</TabPanel>}
    </TabView>

  );
}