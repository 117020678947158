import {Route, Routes} from 'react-router-dom';

import {Users} from './nhan-vien';
import {Teams} from './team-nhom';
import {Organization} from './to-chuc';

export default function System({me, org}) {

  return (
    <Routes>
      <Route path="nhan-vien" element={<Users me={me}/>}/>
      <Route path="team-nhom" element={<Teams me={me}/>}/>
      <Route path="to-chuc" element={<Organization me={me} org={org}/>}/>
    </Routes>
  );
}
