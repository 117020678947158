import {useEffect, useState} from 'react';

import {ZaloNotificationService as Service} from './ZaloNotificationService';

import {ZaloFollowStatus} from './ZaloFollowStatus';

export const ZaloNotification = ({me}) => {

  const [officialAccount, setOfficialAccount] = useState(null);
  const [followStatus, setFollowStatus] = useState(null);

  useEffect(() => {
    Service.getOfficialAccount().then(data => {
      setOfficialAccount(data);
    });
  }, []);

  useEffect(() => {
    if (officialAccount?.zoaId) {
      Service.getFollowStatus(officialAccount.zoaId).then(data => {
        setFollowStatus(data);
      });
    }
  }, [officialAccount]);

  return (
    <ZaloFollowStatus me={me} officialAccount={officialAccount} followStatus={followStatus}/>
  );
}